import React from 'react';
import { get } from 'lodash-es';
import paths from 'path';

import { hierarchize } from './util/hierarchical';

const keyName = 'key';
const pathName = 'path';
const uniqueKeyName = 'uniqueKey';

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + '.' + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, '');
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize(
  {
    key: 'home',
    name: 'Home',
    icon: 'ViewDashboard',
    path: '/',
    children: [{
      key: 'login',
      name: 'Login',
      isPublic: true,
      isHidden: true,
      component: React.lazy(() => import('./components/pages/Login')),
    }]
  },
  null,
  pathGenerator
);

//console.log(routeConfig);

export default routeConfig;
