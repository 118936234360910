import React, { useState, useContext } from 'react';
import { Stack, styled, IconButton, FocusTrapCallout, FocusZone, Text, FocusZoneTabbableElements, PrimaryButton, DefaultButton, classNamesFunction, getTheme, FontIcon } from '@fluentui/react';
import { UserMenu } from './UserMenu';
import { AccessTokens } from './AccessTokens';
import { SettingsMenu } from './SettingsMenu';
import { EuiFieldSearch, EuiFieldText, EuiSpacer, EuiText } from '@elastic/eui';
import ArbLogo from '../../../assets/arbLogo.png'
import { useFeData } from '../../util/frontend-context'
import { useTopMenu } from '../../util/topmenu-context'



const theme = getTheme()

const getStyles = ({ theme }) => {
  return {
    root: {
      backgroundColor: '#ffffff',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.semanticColors.bodyFrameDivider,
      borderBottomWidth: 0,
      padding: theme.spacing.s1,
      height: 56,
      boxShadow: '1px 1px 4px #ebe9e9'
    }
  };
};

function titleFontIcon(iconName, style) {
  return <FontIcon style={{ root: { ...style } }} iconName={iconName}></FontIcon>
}

const styles = getStyles

const getClassNames = classNamesFunction();

function TopMenuComponent(props) {
  const { saveButtonFn, newPanelRef, newButtonClickRef, itemsPendingSaveRef } = useTopMenu()
  const classNames = getClassNames(styles, { theme });
  const [newCalloutVisible, setNewCalloutVisible] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

//var {expressions} = useFeData()
  //const { } = useTopMenu()

  //var newBoxFn = props.newButtonFn
  //var setNewNameFn = props.newNameField
  var newErrorText = props.newErrorText

  function toggleIsCalloutVisibleTrue() {
    setNewCalloutVisible(true)
  }

  function toggleIsCalloutVisibleFalse() {
    setNewCalloutVisible(false)
  }

  function primaryNewButtonClick(evt) {
    var buttonClickFn = newButtonClickRef.current
    buttonClickFn(evt)

    toggleIsCalloutVisibleFalse()

    return undefined
  }

  function saveButtonClick(evt) {
    var saveClickFn = saveButtonFn.current
    saveClickFn(evt)
    return undefined
  }

  return (<>
    <Stack
      horizontal
      horizontalAlign='space-between'
      className={classNames.root}
      //style={{ width: '100%' }}
      tokens={{ childrenGap: '1em' }}>
      <Stack horizontal horizontalAlign='space-between' style={{ width: '100%' }} tokens={{ childrenGap: '1em' }}  >
        <EuiFieldSearch
          width='50%'
          style={{ alignItems: 'end', borderRadius: '10px', boxShadow: 'none' }}
          //placeholder='e.g. jsmith 192.168.16.2 usnyc1059'
          placeholder='Search...'
          box={{
            incremental: false
          }}
        //onChange={onSearchChange}
        />
        <Stack horizontal>
          {(props.demo !== true) && (props.saveFn || toggleIsCalloutVisibleTrue || props.clearFn) ? <Stack horizontalAlign='end' verticalAlign='center' tokens={{ childrenGap: '0.35 em' }} horizontal style={{ fontWeight: 'bold', fontSize: 'xx-large', margin: '1%' }} >
            <IconButton styles={{ icon: { fontSize: 'medium', color: 'black' } }} iconProps={{ iconName: 'Refresh' }}></IconButton>
            {toggleIsCalloutVisibleTrue ? <IconButton id='newButton' styles={{ icon: { fontSize: 'medium', color: 'black' } }} iconProps={{ iconName: 'CirclePlus' }} onClick={toggleIsCalloutVisibleTrue}></IconButton> : <></>}
            <IconButton styles={{ icon: { fontSize: 'medium', color: 'black' } }} onClick={saveButtonClick} iconProps={{ iconName: 'Save' }}></IconButton>
            {props.clearFn ? <IconButton styles={{ icon: { fontSize: 'x-large', color: 'rgb(204 200 200)' } }} iconProps={{ iconName: 'Delete' }} onClick={props.clearFn}></IconButton> : <></>}
            <SettingsMenu />
            {newCalloutVisible ? <FocusTrapCallout
              role="alertdialog"
              className={styles.callout}
              gapSpace={0}
              setInitialFocus
              target={`#newButton`}
              style={{ width: 320 }}
            >
              <Text block variant="xLarge" className={styles.title}>
                New
              </Text>
              <EuiSpacer></EuiSpacer>
              <EuiFieldText width='70%' inputRef={newPanelRef} compressed />
              {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
              <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                <EuiSpacer></EuiSpacer>
                <Stack className={styles.buttons} tokens={{ childrenGap: '0.35 em' }} horizontal>
                  <PrimaryButton onClick={primaryNewButtonClick} >Done</PrimaryButton>
                  <DefaultButton onClick={toggleIsCalloutVisibleFalse} >Cancel</DefaultButton>
                </Stack>
                {newErrorText ? <><EuiSpacer /><EuiText color='danger'><b>{newErrorText}</b></EuiText></> : <></>}
              </FocusZone>
            </FocusTrapCallout> : null}
          </Stack> : <></>}
          {props.demo === true ? <Stack horizontalAlign='end' verticalAlign='center' tokens={{ childrenGap: '0.35 em' }} horizontal style={{ fontWeight: 'bold', fontSize: 'xx-large', margin: '1%' }} >
            <IconButton styles={{ icon: { fontSize: 'x-large', color: 'rgb(204 200 200)' } }} iconProps={{ iconName: 'Refresh' }} />
            <IconButton styles={{ icon: { fontSize: 'x-large', color: 'rgb(204 200 200)' } }} iconProps={{ iconName: 'Lock' }} />
            <IconButton styles={{ icon: { fontSize: 'x-large', color: 'rgb(204 200 200)' } }} iconProps={{ iconName: 'Edit' }} />
            <IconButton styles={{ icon: { fontSize: 'x-large', color: 'rgb(204 200 200)' } }} iconProps={{ iconName: 'Settings' }} />
          </Stack> : <></>}
          {props.demo !== true ? <AccessTokens /> : <></>}
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '1em' }} style={{ marginLeft: '6%' }} >
            <UserMenu /></Stack>
        </Stack>
      </Stack></Stack>
  </>
  );
}

export const TopMenu = styled(TopMenuComponent, getStyles);
