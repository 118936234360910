import { useLocation, matchPath } from 'react-router-dom';
import { findNode, getParents } from '../../../util/hierarchical';

export function findRoute(pathname, routeConfig) {
  const current = findNode(
    routeConfig,
    route => {
      const match = matchPath(pathname, route);
      return match?.isExact;
    },
  );
  const paths = current ? getParents(current) : [];
  return { current, paths };
}

export default function useRoutePath(routeConfig) {
  const { pathname } = useLocation();
  return findRoute(pathname, routeConfig);
}
