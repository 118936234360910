import React from "react";
import { CommandBarButton } from '@fluentui/react';
import { useAuthentication } from '../../util/authentication';

export function AccessTokens() {
  //const { principal, logout } = useAuthentication();

  
  const { tokens } = useAuthentication();

  var tokensObj = tokens

  const menuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: 'acquiregraphtoken',
        text: 'Acquire Token',
        iconProps: { iconName: 'PlayerSettings' }
      },
      {
        key: 'logout',
        text: 'Logout',
        iconProps: { iconName: 'SignOut' }
      }
    ]
  };

  return (<>
  {tokensObj.tokenGraph ? null : <CommandBarButton
      menuProps={menuProps}
      iconProps={{ iconName: 'UserOptional' }}>
       'NO Graph Token'
    </CommandBarButton> }
    {tokensObj.tokenAz ? null : <CommandBarButton
      menuProps={menuProps}
    iconProps={{ iconName: 'UserOptional' }}>
       ''NO Az Token'
    </CommandBarButton> }
    {tokensObj.tokenAde ? null : <CommandBarButton
      menuProps={menuProps}
      iconProps={{ iconName: 'UserOptional' }}>
       'NO ADE Token'
    </CommandBarButton> }
    {tokensObj.tokenLa ? null : <CommandBarButton
      menuProps={menuProps}
      iconProps={{ iconName: 'UserOptional' }}>
       'NO LA Token'
    </CommandBarButton> }
    </>
  );
}
