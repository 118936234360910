import React, { useState, useEffect, useContext, useRef, createRef } from "react";
import AdvancedSearch from '../pages/AdvancedSearch/AdvancedSearch';
import ReactJson from 'react-json-view'


export const TopMenuContext = React.createContext();
export const useTopMenu = () => useContext(TopMenuContext);
export const TopMenuProvider = ({
  children,
  config
}) => {
  let bottomPanelObj = {
    textFieldContentsRef: useRef(),
    datasourceTypeDropdownRef: useRef(),
    specificDatasourceDropdownRef: useRef(),
    columnVisibilityRef: useRef(),
    columnsRef: useRef(),
    columnVizRef: useRef(),
    pageDataArrayRef: useRef(),
    panelHeightObjRef: useRef(),
    itemsPerPageRef: useRef(),
    itemPageIndexRef: useRef(),
  }

  let flyoutObj = {
    flyoutTabs: useRef()
  }

  const [currentTabControlBar, setCurrentTabControlBar] = useState();
  const [contentIsVisible, setVisibility] = useState(false);
  const [tabContent, setTabContent] = useState();
  const [pageData, setPageData] = useState()


  const searchTab = () => {
    if (currentTabControlBar !== 'search') {
      setVisibility(true);
      setCurrentTabControlBar('search')
      setTabContent(<AdvancedSearch />);
      //setRefreshNeeded(true)
    }
    else {
      setVisibility(false)
      setCurrentTabControlBar(undefined)
      //setRefreshNeeded(true)
    }
  };

  const pageDataTab = () => {
    if (currentTabControlBar !== 'pageData') {
      setVisibility(true);
      setCurrentTabControlBar('pageData')
      setTabContent(<ReactJson collapsed src={pageData}></ReactJson>);
      //setRefreshNeeded(true)
    }
    else {
      setVisibility(false)
      setCurrentTabControlBar(undefined)
      //setRefreshNeeded(true)
    }
  };

  const [flyoutData, setFlyoutData] = useState({})
  const [loadedFlyouts, setLoadedFlyouts] = useState([])
  const [renderedFlyouts, setRenderedFlyouts] = useState([])
  const [activeFlyout, setActiveFlyout] = useState()
  const [dataFlyoutVisible, setDataFlyoutVisible] = useState(false)
  const [controlBarControls, setControlBarControls] = useState([
    {
      controlType: 'tab',
      id: 'advanced_search_tab',
      label: 'Search',
      onClick: searchTab
    },
    {
      controlType: 'tab',
      id: 'page_data_tab',
      label: 'Page Data',
      onClick: pageDataTab
    },
    {
      controlType: 'divider',
    }
  ])
  const [pageOptionsRef, setPageOptionsRef] = useState();
  const [saveButtonFn, setSaveButtonRef] = useState();
  const [itemsPendingSave, setItemsPendingSave] = useState();
  const [refreshFn, setRefreshFn] = useState();
  const [layoutLock, setLayoutLock] = useState(true);
  const [editMode, setEditMode] = useState(true);
  const [newPanelRef, setNewPanelRef] = useState();
  const [newButtonClickRef, setNewButtonClickRef] = useState();
  const [toastArray, setToastArray] = useState([]);
  const [currentMainPage, setCurrentMainPage ] = useState();
  const [currentMainPageParams, setCurrentMainPageParams ] = useState();
  const [sidebarCollapsed, setSidebarCollapsed ] = useState(false);
  const [bottomPanelTabs, setBottomPanelTabs] = useState()
  const [bottomPanel, setBottomPanel] = useState(bottomPanelObj)
  const [bottomVisible, setBottomVisible] = useState()
  const [bottomHeight, setBottomHeight] = useState()
  const [flyout, setFlyout] = useState(flyoutObj)
  
  let renderedFlyoutsRef = useRef()
  let loadedFlyoutsRef = useRef()
  let activeFlyoutRef = useRef()
  let currentTabControlBarRef = useRef()
  let flyoutVisibleRef = useRef()
  let itemsPendingSaveRef = useRef()

  function toggleEditMode() {
    if (editMode === true) {
      setEditMode(false)
      return false
    }
    else {
      setEditMode(true)
      return true
    }
  }

  return (
    <TopMenuContext.Provider
      value={{
        pageOptionsRef,
        setPageOptionsRef,
        saveButtonFn,
        refreshFn,
        setSaveButtonRef,
        setRefreshFn,
        editMode,
        setEditMode,
        layoutLock,
        setLayoutLock,
        newPanelRef,
        setNewPanelRef,
        newButtonClickRef,
        setNewButtonClickRef,
        toastArray,
        setToastArray,
        currentMainPage,
        setCurrentMainPage,
        currentMainPageParams,
        setCurrentMainPageParams,
        sidebarCollapsed,
        setSidebarCollapsed,
        bottomPanelTabs,
        setBottomPanelTabs,
        bottomPanel,
        setBottomPanel,
        bottomVisible,
        setBottomVisible,
        bottomHeight,
        setBottomHeight,
        flyout,
        setFlyout,
        flyoutData,
        setFlyoutData,
        loadedFlyouts,
        setLoadedFlyouts,
        activeFlyout,
        setActiveFlyout,
        dataFlyoutVisible,
        setDataFlyoutVisible,
        renderedFlyouts,
        setRenderedFlyouts,
        controlBarControls,
        setControlBarControls,
        flyoutVisibleRef,
        renderedFlyoutsRef,
        loadedFlyoutsRef,
        activeFlyoutRef,
        currentTabControlBar,
        setCurrentTabControlBar,
        currentTabControlBarRef,
        itemsPendingSave,
        setItemsPendingSave,
        itemsPendingSaveRef
      }}
    >
      {children}
    </TopMenuContext.Provider>
  );
};