function delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export async function fetchRest(params: any, accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    var options: any

    if (params.params.body) {
        const body = params.params.body
        options = {
            method: params.params.method,
            headers: headers,
            body: JSON.stringify(body)
        };
    }
    else {
        options = {
            method: params.params.method,
            headers: headers
        };
    }

    var url = params.params.host + params.params.uri

    if (params.query) {
        var queryStringArray: any[] = []

        params.query.forEach((item: any) => { queryStringArray.push(item.join('=')) })

        var finalQueryString = queryStringArray.join('&')

        url = url + "?" + finalQueryString
    }

    const fetchResult = fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));

    return fetchResult;
}

export async function restGet(url: any) {
    const headers = new Headers();

    const options = {
        method: "GET",
        headers: headers,
    };

    const fetchResult = await fetch(url, options)
        .then(response => {
            if (response.status !== 200) {
                return response.statusText
            }
            else {
                return response.json()
            }
        }).catch(error =>
            console.log(error));

    return fetchResult;
}

export async function fetchRestAde(params: any, accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const queryParams = {
        "host": "https://azdataexplorerclu02.eastus.kusto.windows.net",
        "method": "POST",
        "uri": "/v2/rest/query"
    }

    const body = { "csl": params.query, "db": params.db }


    const options = {
        method: queryParams.method,
        headers: headers,
        body: JSON.stringify(body)
    };

    function handleFetch(response: any) {
        var resp = response.json()
        return resp
    }

    const url = queryParams.host + queryParams.uri

    //   console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await
        fetch(url, options)
            .then(response => handleFetch(response))
            .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestAdeGeneric(params: any, accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    var clusterName: any = params.kustoResource.split('/')[0]
    var location: any = params.kustoResource.split('/')[1]
    var db: any = params.kustoResource.split('/')[2]

    const queryParams = {
        "host": "https://" + clusterName + "." + location + ".kusto.windows.net",
        "method": "POST",
        "uri": "/v2/rest/query"
    }

    const body = { "csl": params.query, "db": db }


    const options = {
        method: queryParams.method,
        headers: headers,
        body: JSON.stringify(body)
    };

    function handleFetch(response: any) {
        var resp = response.json()
        return resp
    }

    function handleError(error: any) {
        return error
    }

    const url = queryParams.host + queryParams.uri

    //   console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        .then(response => handleFetch(response))
        .catch(error => handleError(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestLogAnalyticsWvd(params: any, accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const queryParams = {
        "host": "https://management.azure.com",
        "method": "POST",
        "uri": "/subscriptions/8f2573a7-cfdf-4ff1-8709-3e8b8d238579/resourcegroups/loganalytics-rg/providers/microsoft.operationalinsights/workspaces/loganalytics-workspace01/api/query?api-version=2017-01-01-preview"
    }

    const body = { "query": params.query }


    const options = {
        method: queryParams.method,
        headers: headers,
        body: JSON.stringify(body)
    };

    const url = queryParams.host + queryParams.uri

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestLogAnalytics(params: any, accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const queryParams = {
        "host": "https://management.azure.com",
        "method": "POST",
        "uri": "/subscriptions/8f2573a7-cfdf-4ff1-8709-3e8b8d238579/resourcegroups/loganalytics-rg/providers/microsoft.operationalinsights/workspaces/loganalytics-workspace01/api/query?api-version=2017-01-01-preview"
    }

    const body = { "query": params.query }


    const options = {
        method: queryParams.method,
        headers: headers,
        body: JSON.stringify(body)
    };

    const url = queryParams.host + queryParams.uri

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestLogAnalyticsGeneric(params: any, accessToken: string, logAnalyticsResourceId: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const queryParams = {
        "host": "https://management.azure.com",
        "method": "POST",
        "uri": logAnalyticsResourceId + "/api/query?api-version=2017-01-01-preview"
    }

    const body = { "query": params.query }


    const options = {
        method: queryParams.method,
        headers: headers,
        body: JSON.stringify(body)
    };

    const url = queryParams.host + queryParams.uri

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestLogAnalyticsIo(params: any, accessToken: string, logAnalyticsResourceId: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    let laWorkspaceGuid = logAnalyticsResourceId

    if (logAnalyticsResourceId === '/subscriptions/8f2573a7-cfdf-4ff1-8709-3e8b8d238579/resourcegroups/loganalytics-rg/providers/microsoft.operationalinsights/workspaces/loganalytics-workspace01') {
        laWorkspaceGuid = '3ed1e079-6bdf-4494-80e6-a61aebfcd719'
    }

    const queryParams = {
        "host": "https://api.loganalytics.io",
        "method": "POST",
        "uri": '/v1/workspaces/' + laWorkspaceGuid + "/query"
    }

    const body = { "query": params.query }


    const options = {
        method: queryParams.method,
        headers: headers,
        body: JSON.stringify(body)
    };

    const url = queryParams.host + queryParams.uri

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestAzureManagement(params: any, accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const requestParams = {
        "host": "https://management.azure.com",
        "method": params.method,
        "uri": params.uri
    }

    var options: any

    if (params.body) {
        const body = params.body
        options = {
            method: requestParams.method,
            headers: headers,
            body: JSON.stringify(body)
        };
    }
    else {
        options = {
            method: requestParams.method,
            headers: headers
        };
    }

    var url = requestParams.host + requestParams.uri

    var queryStringArray: any[] = []

    params.query.forEach((item: any) => { queryStringArray.push(item.join('=')) })

    var finalQueryString = queryStringArray.join('&')

    if (params.query) {
        url = url + "?" + finalQueryString
    }

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        //.then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestAzureManagementJson(params: any, accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const requestParams = {
        "host": "https://management.azure.com",
        "method": params.method,
        "uri": params.uri
    }

    var options: any

    if (params.body) {
        const body = params.body
        options = {
            method: requestParams.method,
            headers: headers,
            body: JSON.stringify(body)
        };
    }
    else {
        options = {
            method: requestParams.method,
            headers: headers
        };
    }

    var url = requestParams.host + requestParams.uri

    var queryStringArray: any[] = []

    params.query.forEach((item: any) => { queryStringArray.push(item.join('=')) })

    var finalQueryString = queryStringArray.join('&')

    if (params.query) {
        url = url + "?" + finalQueryString
    }

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

async function getFollowUpResponse202(followUpUrl: any, accessToken: any) {
    const followUpHeaders = new Headers();

    followUpHeaders.append("Authorization", accessToken);

    let followUpOptions: any = {
        headers: followUpHeaders
    }

    const followUpResult = await fetch(followUpUrl, followUpOptions)
        .then(followUpResponse => {
            if (followUpResponse.status === 200) {
                return followUpResponse.json()
            }
            else if (followUpResponse.status === 202) {
                let responseUrl: any = followUpUrl
                let loopedResponse: any = delay(1000).then(() => getFollowUpResponse202(responseUrl, accessToken))
                return loopedResponse
            }
        })
        .catch(followUpError => console.log(followUpError));

    return followUpResult
}

export async function fetchRestAzureManagementString(params: any, accessToken: string, additionalOptions?: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const requestParams = {
        "host": "https://management.azure.com",
        "method": params.method,
        "uri": params.uri
    }

    var options: any

    if (params.body) {
        let body = params.body
        options = {
            method: requestParams.method,
            headers: headers,
            body: body
        };
    }
    else {
        options = {
            method: requestParams.method,
            headers: headers
        };
    }

    var url = requestParams.host + requestParams.uri

    var queryStringArray: any[] = []

    params.query.forEach((item: any) => { queryStringArray.push(item.join('=')) })

    var finalQueryString = queryStringArray.join('&')

    if (params.query) {
        url = url + "?" + finalQueryString
    }

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        .then(response => {
            if (additionalOptions && additionalOptions.outputType && additionalOptions.outputType === 'toast') {
                return {
                    status: response.status,
                    response: response.headers.get('content-length') && response.headers.get('content-length') !== '0' ?
                        response.json() : {}
                }
            }
            else {
                if (response.status === 200) {
                    return response.json()
                }
                else if (response.status === 202) {
                    let responseUrl: any = response.headers.get('location')
                    let actualResponse = delay(1000).then(() => getFollowUpResponse202(responseUrl, bearer))
                    return actualResponse
                }
                else if (response.status === 429) {
                    let asdf1 = null
                    let asdf2 = null
                    let asdf3 = null
                    return response.json()
                }
                else {
                    return { status: response.status }
                }
            }
        })
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestMsGraph(params: any, accessToken: string, options?: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (options && options.eventualConsistency) {
        headers.append("ConsistencyLevel", "eventual");
    }

    const requestParams = {
        "host": "https://graph.microsoft.com",
        "method": params.method,
        "uri": params.uri
    }

    var options: any

    if (params.body) {
        const body = params.body
        options = {
            method: requestParams.method,
            headers: headers,
            body: body
        };
    }
    else {
        options = {
            method: requestParams.method,
            headers: headers
        };
    }

    var url = requestParams.host + requestParams.uri

    var queryStringArray: any[] = []

    params.query.forEach((item: any) => { queryStringArray.push(item.join('=')) })

    var finalQueryString = queryStringArray.join('&')

    if (params.query && params.query.length > 0) {
        url = url + "?" + finalQueryString
    }

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function fetchRestWvd(params: any) {
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');

    const postBodyJson = {
        "bearerToken": params.token,
        "laToken": params.laToken
    };
    const postBody = JSON.stringify(postBodyJson, null, 2)

    const requestParams = {
        "host": "",
        "method": "POST",
        "uri": '/api/getwvdinfo',
        "body": postBody
    }

    const options = {
        method: requestParams.method,
        body: requestParams.body,
        headers: headers
    };

    //console.log(Headers)

    const url = requestParams.host + requestParams.uri

    //console.log(JSON.stringify(options))

    const fetchResultWvd = await fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));

    //.then(data => console.log(data))
    //.then(response => console.log(response))


    //console.log(fetchResult)
    return fetchResultWvd;
}

export async function fetchRestOktaCore(params: any) {
    const headers = new Headers();
    headers.append("content-type", 'application/json');
    headers.append("authorization", 'SSWS ');

    const requestParams = {
        "uriBase": "",
        "uri": params.uri
    }

    const url = requestParams.uriBase + requestParams.uri

    const options: RequestInit = {
        method: params.method,
        headers: headers

    };

    //console.log(Headers)

    //console.log(JSON.stringify(options))

    const fetchResultOktaCore = await fetch(url, options)
        .then(response => response.json())
    //.then(data => console.log(data))
    //.then(response => console.log(response))
    //console.log(fetchResult)
    return fetchResultOktaCore;
}

export async function fetchRestOktaSearch(start: string, end: string, userId: string) {
    const headers = new Headers();
    headers.append("content-type", 'application/json');
    headers.append("authorization", 'SSWS 007ARnfvEvq5AtNca-at8S8pKrsJwGzmSe5FLOfffB');

    var headersArray = [
        ["content-type", 'application/json'],
        ["authorization", 'SSWS ']
    ]

    const requestParams = {
        "uriBase": "",
    }

    var filterString = 'filter=target.id eq "' + userId + '" or actor.id eq "' + userId + '"'
    var sinceString = 'since=' + start
    var untilString = 'until=' + end
    var queryString = filterString + '&' + sinceString + '&' + untilString

    const url = requestParams.uriBase + "?" + queryString

    var proxyObj = {
        url: url,
        headersJson: JSON.stringify(headersArray),
        method: 'GET'
    }

    const options: RequestInit = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(proxyObj)
    };

    var proxyUrl = ''

    //console.log(Headers)


    //console.log(JSON.stringify(options))

    const fetchResultOktaCore = await fetch(proxyUrl, options)
        .then(response => response.json())
        .catch(error => console.log(error));

    //.then(data => console.log(data))
    //.then(response => console.log(response))
    //console.log(fetchResult)
    return fetchResultOktaCore;
}

export async function fetchOktaLogsFailuresPolling(params: any) {
    const headers = new Headers();
    headers.append("content-type", 'application/json');
    headers.append("authorization", 'SSWS  ');

    var headersArray = [
        ["content-type", 'application/json'],
        ["authorization", 'SSWS  ']
    ]

    const requestParams = {
        "uriBase": "",
    }

    var url = ''

    if (params.start) {
        var filterString = 'filter=outcome.result eq "FAILURE"'
        var sinceString = 'since=' + params.start
        var sortOrder = 'sortOrder=ASCENDING'
        var queryString = filterString + '&' + sinceString + '&' + sortOrder
        url = requestParams.uriBase + "?" + queryString
    }
    else if (params.nextLink) {
        url = params.nextLink
    }

    var proxyObj = {
        url: url,
        headersJson: JSON.stringify(headersArray),
        method: 'GET'
    }

    const options: RequestInit = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(proxyObj)
    };

    var proxyUrl = ''

    //console.log(Headers)


    //console.log(JSON.stringify(options))

    const fetchResultOktaCore = await fetch(proxyUrl, options)
        .then(response => response.json())
        .catch(error => console.log(error));

    //.then(data => console.log(data))
    //.then(response => console.log(response))
    //console.log(fetchResult)
    return fetchResultOktaCore;
}


export async function fetchRestOkta(params: any, accessToken: string) {
    const headers = new Headers();
    headers.append("content-type", 'application/json');
    headers.append("authorization", 'SSWS 00MqD0KlanqhdxyD2GyARiVHmwA0r7rJulpIuv4uYj');

    var headersArray = [
        ["content-type", 'application/json'],
        ["authorization", 'SSWS 00MqD0KlanqhdxyD2GyARiVHmwA0r7rJulpIuv4uYj']
    ]


    let uriBase: any = params.uriBase

    let options: any

    if (params.body) {
        const body = params.body
        options = {
            method: params.method ? params.method : 'POST',
            headers: headers,
            body: body
        };
    }
    else {
        options = {
            method:  params.method ? params.method : 'GET',
            headers: headers
        };
    }

    var url = uriBase + params.uri

    var queryStringArray: any[] = []

    params.query.forEach((item: any) => { queryStringArray.push(item.join('=')) })

    var finalQueryString = queryStringArray.join('&')

    if (params.query && params.query.length > 0) {
        url = url + "?" + finalQueryString
    }

    var proxyObj = {
        url: url,
        headersJson: JSON.stringify(headersArray),
        method: 'GET'
    }

    const postOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(proxyObj)
    };

    var proxyUrl = 'http://localhost:3001/restProxy'

    //console.log(JSON.stringify(options))
    //console.log(JSON.stringify(accessToken))
    const fetchResult = await fetch(proxyUrl, postOptions)
        .then(response => response.json())
        .catch(error => console.log(error));
    //console.log(JSON.stringify(params))
    return fetchResult;
}

export async function awsEc2Command(params: any, awsClient: any) {
    let clientResult: any = null
    let adsdf3 = params
    let asdf4 = null

    // async/await.
    try {
        const clientResult = await awsClient.send(params.command);
        // process data.
    } catch (error) {
        // error handling.
        return JSON.stringify(error)
    } finally {
        // finally.
        return clientResult;
    }
}