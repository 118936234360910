import React, { Fragment, createRef } from "react";
import { Spinner, SpinnerSize, IconButton } from '@fluentui/react';
import * as AWS from "@aws-sdk/client-ec2"
import { TagPicker, ITag } from '@fluentui/react/lib/Pickers';
import { EuiSelect, EuiButton, EuiSwitch, EuiExpression, EuiPopover, EuiFieldNumber, EuiButtonIcon, EuiPopoverTitle, EuiFormRow, EuiFlexItem, EuiTextArea, EuiFieldText, EuiFlexGroup } from '@elastic/eui';
import ReactJson from 'react-json-view'
import {
  fetchRestLogAnalyticsGeneric, fetchRestLogAnalyticsIo, fetchRestAzureManagementJson, fetchRestAzureManagementString, fetchRestMsGraph, fetchRestAdeGeneric, fetchRestOkta
} from "./rest";
import { evaluateExpressionByName, evaluateTransformExpression, getExpressionObjByName, evaluateExpression } from "./expressions";
import { includes } from "lodash";

export async function GetQueryResult(queryObj: any, tokens: any, allExpressionsObj?: any[]) {
  const tokenMsGraph = tokens.tokenGraph
  const tokenAz = tokens.tokenAz
  const tokenAde = tokens.tokenAde
  const tokenSec = tokens.tokenSec
  const tokenLa = tokens.tokenLa
  const oktaToken: any = '00MqD0KlanqhdxyD2GyARiVHmwA0r7rJulpIuv4uYj'

  var returnObj = {}

  switch (queryObj.type) {
    case 'loganalytics':
      returnObj = logAnalyticsQuery(queryObj.query, tokenAz, queryObj.logAnalyticsSource)
      break;
    case 'loganalyticsio':
      returnObj = logAnalyticsIoQuery(queryObj.query, tokenLa, queryObj.logAnalyticsSource)
      break;
    case 'azureresourcegraph':
      returnObj = azureResourceGraphQuery(queryObj.query, tokenAz)
      break;
    case 'azuremanagement':
      returnObj = azureResourceRest(queryObj, tokenAz, allExpressionsObj)
      break;
    case 'msgraph':
      returnObj = msGraphRest(queryObj, tokenMsGraph, allExpressionsObj)
      break;
    case 'okta':
      returnObj = oktaRest(queryObj, oktaToken, allExpressionsObj)
      break;
    case 'aws':
     /* if (queryObj.options && queryObj.options.commandText) {
        returnObj = awsApiCommand(queryObj, awsClients, allExpressionsObj)
      } */
      break;
    case 'kusto':
      returnObj = kustoQuery(queryObj.query, tokenAde, queryObj.kustoSource)
      break;
    case 'simplequery':
      returnObj = simpleQuery(queryObj.query)
      break;
    case 'querytransform':
      returnObj = queryTransform(queryObj, allExpressionsObj)
      break;
    default:
      break;
  }
  return returnObj
}

export function logAnalyticsQuery(query: any, bearerToken: any, logAnalyticsResourceId: any) {

  var queryResult = fetchRestLogAnalyticsGeneric({ query: query }, bearerToken, logAnalyticsResourceId)
    .then(response => {
      var returnResult: any = {}
      var queryResultRaw = {}
      queryResultRaw = response && response.Tables ? response : response.error ? response.error : {}

      if (!response.error) {
        returnResult.raw = response
        returnResult.transformed = transformLogAnalyticsResult(queryResultRaw)
      }
      else {
        returnResult = queryResultRaw
      }
      return returnResult
    })

  return queryResult
}

export function logAnalyticsIoQuery(query: any, bearerToken: any, logAnalyticsResourceId: any) {

  var queryResult = fetchRestLogAnalyticsIo({ query: query }, bearerToken, logAnalyticsResourceId)
    .then(response => {
      var returnResult: any = {}
      var queryResultRaw = {}
      queryResultRaw = response && response.tables ? response : response.error ? response.error : {}

      if (!response.error) {
        returnResult.raw = response
        returnResult.transformed = transformLogAnalyticsIoResult(queryResultRaw)
      }
      else {
        returnResult = queryResultRaw
      }
      return returnResult
    })

  return queryResult
}

export function simpleQuery(query: any) {
  var returnObjRaw: string = query
  var returnObj: any = {}

  try {
    var parsedJson = JSON.parse(returnObjRaw)
    returnObj.raw = parsedJson
    returnObj.transformed = parsedJson
  }
  catch (err: any) {
    var caughtError = err

    var errorString = caughtError.name + ': ' + caughtError.message
    returnObj.raw = errorString
  }

  return returnObj
}

export function queryTransform(query: any, allExpressionsObj?: any) {
  let returnObj: any = {}
  let expressionObj: any = allExpressionsObj.find((item: any) => item.id === query.exprs.transformFunctionExpr)

  if (expressionObj) {
    let transformExpressionObj: any = evaluateExpression(expressionObj, {})
    if (transformExpressionObj) {
      returnObj.raw = transformExpressionObj
      returnObj.transformed = transformExpressionObj
    }
  }

  return returnObj
}

export function kustoQuery(query: any, bearerToken: any, kustoResourceId: any) {

  var queryResult = fetchRestAdeGeneric({ query: query, kustoResource: kustoResourceId }, bearerToken)
    .then((response: any) => {
      var returnResult: any = {}
      if (Array.isArray(response)) {
        returnResult.raw = response
        returnResult.transformed = transformKustoResult(response)
      }
      else {
        returnResult.raw = response
      }
      return returnResult
    })

  return queryResult
}

export function azureResourceGraphQuery(query: any, bearerToken: any) {

  var queryResult = fetchRestAzureManagementJson({
    query: [["api-version", "2021-03-01"]],
    body: { query: query },
    method: "POST",
    uri: '/providers/Microsoft.ResourceGraph/resources'
  }, bearerToken).then(response => {
    var returnResult: any = {}
    var queryResultRaw = {}
    queryResultRaw = response.data ? response : response.error

    if (!response.error) {
      returnResult.raw = response
      returnResult.transformed = handleAzureResourceGraphResult(queryResultRaw)
    }
    else {
      returnResult = queryResultRaw
    }

    return returnResult
  })

  return queryResult
}

export function awsApiCommand(queryObj: any, awsClient: any, allExpressionsObj?: any[]) {
  let paramsObj: any

  if (queryObj.parameters && queryObj.parameters.length > 0) {
    if (!paramsObj) {
      paramsObj = {}
    }
    queryObj.parameters.map((item: any, index: number) => {
      if (item[2] && item[2] === 'v2') {
        paramsObj[item[0]] = item[1]
      }
      return undefined
    })
  }

 /* let commandResult = awsCommand({
    service: queryObj.options && queryObj.options.awsApi ? queryObj.options.awsApi : undefined,
    command: queryObj.options && queryObj.options.commandText ?
      queryObj.options.commandText : undefined,
    params: paramsObj ? paramsObj : undefined
  }, awsClient).then((response: any) => {
    let returnResult: any = {}
    let commandResultRaw = {}

    if (queryObj.options && queryObj.options.outputType && queryObj.options.outputType === 'toast') {
      response = JSON.parse(JSON.stringify(response))
      returnResult.raw = response
      if (response['$metadata']) {
        returnResult.transformed = {
          status: response['$metadata']['httpStatusCode'],
          response: response['$metadata']['httpStatusCode'] && response['$metadata']['httpStatusCode'] !== 200 ? response['message'] : {}
        }
      }

    }
    else {
      if (response['$metadata'] && response['$metadata']['httpStatusCode'] === 200) {
        returnResult.raw = response
        if (queryObj.transformFn && queryObj.transformFn !== '') {
          let transformExpressionObj: any = getExpressionObjByName(queryObj.transformFn, allExpressionsObj)
          if (transformExpressionObj) {
            let transformedOutput = evaluateTransformExpression(transformExpressionObj, response)
            returnResult.transformed = transformedOutput
          }
        }
      }
      else {
        returnResult.raw = response
        returnResult.transformed = response
      }
    }

    return returnResult
  })
  return commandResult */
}

export function azureResourceRest(query: any, bearerToken: any, allExpressionsObj?: any[]) {
  let uriExpressionOutput

  if (query.azMgmtUri) {
    uriExpressionOutput = query.azMgmtUri
  } else {
    uriExpressionOutput = evaluateExpressionByName(query.queryRest.uri, allExpressionsObj)
  }

  let bodyExpressionOutput
  if (query.postBody) {
    bodyExpressionOutput = query.postBody
  }
  else {
    bodyExpressionOutput = evaluateExpressionByName(query.queryRest.bodyExpr, allExpressionsObj)
  }

  var resolvedQueryParameterExpressions: any[] = []

  if (query.parameters.length > 0) {
    query.parameters.map((item: any, index: number) => {
      if (item[2] && item[2] === 'v2') {
        let resolvedParamObjV2: any = [item[0], item[1]]
        resolvedQueryParameterExpressions.push(resolvedParamObjV2)
      }
      else {
        var resolvedParamObj: any = [item[0], evaluateExpressionByName(item[1], allExpressionsObj)]
        resolvedQueryParameterExpressions.push(resolvedParamObj)
      }


      return null
    })

  }

  var queryResult = fetchRestAzureManagementString({
    query: resolvedQueryParameterExpressions,
    body: query.queryRest.method === 'POST' ? bodyExpressionOutput : null,
    method: query.queryRest.method,
    uri: uriExpressionOutput
  }, bearerToken, { ...query.options }).then((response: any) => {
    var returnResult: any = {}
    var queryResultRaw = {}

    if (response) {
      queryResultRaw = response.error ? response.error : response
      if (!response.error) {
        returnResult.raw = response
        if (query.transformFn && query.transformFn !== '') {
          var transformExpressionObj: any = getExpressionObjByName(query.transformFn, allExpressionsObj)
          if (transformExpressionObj) {
            var transformedOutput = evaluateTransformExpression(transformExpressionObj, response)
            returnResult.transformed = transformedOutput
          }
        }
      }
      else {
        returnResult = queryResultRaw
      }
    }
    else {
      returnResult.error = 'ERROR: No response. Check query parameters/uri/method'
    }

    return returnResult

  })

  return queryResult
}

export function oktaRest(query: any, sswsToken: any, allExpressionsObj?: any[]) {
  let uriExpressionOutput

  if (query.oktaUri) {
    uriExpressionOutput = query.oktaUri
  } else {
    uriExpressionOutput = evaluateExpressionByName(query.queryRest.uri, allExpressionsObj)
  }

  var bodyExpressionOutput = ''
  if (query.queryRest.bodyExpr) {
    bodyExpressionOutput = evaluateExpressionByName(query.queryRest.bodyExpr, allExpressionsObj)
  }

  var resolvedQueryParameterExpressions: any[] = []

  if (query.parameters.length > 0) {
    query.parameters.map((item: any, index: number) => {
      var resolvedParamObj: any = [item[0], evaluateExpressionByName(item[1], allExpressionsObj)]
      resolvedQueryParameterExpressions.push(resolvedParamObj)

      return null
    })

  }

  var queryResult = fetchRestOkta({
    query: resolvedQueryParameterExpressions,
    uriBase: query && query.options && query.options.adminApi ? 'https://dev-80639501-admin.okta.com' : 'https://dev-80639501.okta.com',
    body: query.queryRest.method === 'POST' ? bodyExpressionOutput : null,
    method: query.queryRest.method,
    uri: uriExpressionOutput
  }, sswsToken).then(response => {
    var returnResult: any = {}
    var queryResultRaw = {}

    if (response) {
      queryResultRaw = response.error ? response.error : response
      if (!response.error) {
        returnResult.raw = response
        if (query.transformFn && query.transformFn !== '') {
          var transformExpressionObj: any = getExpressionObjByName(query.transformFn, allExpressionsObj)
          if (transformExpressionObj) {
            var transformedOutput = evaluateTransformExpression(transformExpressionObj, response)
            returnResult.transformed = transformedOutput
          }
        }
      }
      else {
        returnResult = queryResultRaw
      }
    }
    else {
      returnResult.error = 'ERROR: No response. Check query parameters/uri/method'
    }

    return returnResult
  })

  return queryResult
}

export function msGraphRest(query: any, bearerToken: any, allExpressionsObj?: any[]) {
  let uriExpressionOutput

  if (query.graphUri) {
    uriExpressionOutput = query.graphUri
  } else {
    uriExpressionOutput = evaluateExpressionByName(query.queryRest.uri, allExpressionsObj)
  }

  var bodyExpressionOutput = ''
  if (query.queryRest.bodyExpr) {
    bodyExpressionOutput = evaluateExpressionByName(query.queryRest.bodyExpr, allExpressionsObj)
  }

  var resolvedQueryParameterExpressions: any[] = []

  if (query.parameters.length > 0) {
    query.parameters.map((item: any, index: number) => {
      if (item[2] && item[2] === 'v2') {
        let resolvedParamObjV2: any = [item[0], item[1]]
        resolvedQueryParameterExpressions.push(resolvedParamObjV2)
      }
      else {
        var resolvedParamObj: any = [item[0], evaluateExpressionByName(item[1], allExpressionsObj)]
        resolvedQueryParameterExpressions.push(resolvedParamObj)
      }


      return null
    })

  }

  let optionsObj: any = query.options

  var queryResult = fetchRestMsGraph({
    query: resolvedQueryParameterExpressions,
    body: query.queryRest.method === 'POST' ? bodyExpressionOutput : null,
    method: query.queryRest.method,
    uri: uriExpressionOutput
  }, bearerToken, optionsObj).then(response => {
    var returnResult: any = {}
    var queryResultRaw = {}

    if (response) {
      queryResultRaw = response.error ? response.error : response
      if (!response.error) {
        if (typeof response === 'number') {
          let responseNum: number = response
          let propName: any = query.id
          response = {}
          response[propName] = responseNum
        }

        returnResult.raw = response
        if (query.transformFn && query.transformFn !== '') {
          var transformExpressionObj: any = getExpressionObjByName(query.transformFn, allExpressionsObj)
          if (transformExpressionObj) {
            var transformedOutput = evaluateTransformExpression(transformExpressionObj, response)
            returnResult.transformed = transformedOutput
          }
        }
      }
      else {
        returnResult = queryResultRaw
      }
    }
    else {
      returnResult.error = 'ERROR: No response. Check query parameters/uri/method'
    }

    return returnResult
  })

  return queryResult
}

export function transformLogAnalyticsResult(rawJson: any) {
  let outputResult: any[] = []
  let dataRows: any = []
  let columns: any = []
  if (rawJson && rawJson.Tables && rawJson.Tables[0] && rawJson.Tables[0].Rows) {
    dataRows = rawJson.Tables[0].Rows
    columns = rawJson.Tables[0].Columns
  }

  dataRows.map((item: any, index: number) => {
    var transformedItem: any = {}
    item.map((value: any, colNum: number) => {
      var colName = columns[colNum].ColumnName
      transformedItem[colName] = value

      return null
    })
    outputResult.push(transformedItem)

    return null
  })

  return outputResult
}

export function transformLogAnalyticsIoResult(rawJson: any) {
  let outputResult: any[] = []
  let dataRows: any = []
  let columns: any = []
  if (rawJson && rawJson.tables && rawJson.tables[0] && rawJson.tables[0].rows) {
    dataRows = rawJson.tables[0].rows
    columns = rawJson.tables[0].columns
  }

  dataRows.map((item: any, index: number) => {
    var transformedItem: any = {}
    item.map((value: any, colNum: number) => {
      var colName = columns[colNum].name
      transformedItem[colName] = value

      return null
    })
    outputResult.push(transformedItem)

    return null
  })

  return outputResult
}

export function transformKustoResult(rawJson: any) {
  var outputResult: any[] = []
  var dataRows = rawJson[2].Rows
  var columns = rawJson[2].Columns

  dataRows.map((item: any, index: number) => {
    var transformedItem: any = {}
    if (item && Array.isArray(item)) {
      item.map((value: any, colNum: number) => {
        var colName = columns[colNum].ColumnName
        transformedItem[colName] = value

        return null
      })
    }
    outputResult.push(transformedItem)

    return null
  })

  return outputResult
}

function handleAzureResourceGraphResult(rawJson: any) {
  var dataRows = rawJson.data

  return dataRows
}

export function QueryEditHtml(props: any) {
  function existingParamButtonPopover(currentQueryObj: any, paramIndex: number) {
    if (!currentQueryObj.parameters) {
      currentQueryObj.parameters = []
    }

    var paramObj: any[] = currentQueryObj.parameters[paramIndex]

    var paramPopoverName = currentQueryObj.id + '-param-' + paramIndex + '-popover'
    var paramButtonName = currentQueryObj.id + '-param-' + paramIndex

    var currentOpenPopover: any = props.currentOpenPopover

    var parameterRefKey = createRef<any>()
    var parameterRefVersion = createRef<any>()
    var parameterRefValue = createRef<any>()
    var paramRefObj: any[] = [parameterRefKey, parameterRefValue, parameterRefVersion]
    props.queryRef.parametersRefArray.push(paramRefObj)

    return <><EuiFlexGroup><EuiFlexItem><EuiPopover
      button={<EuiExpression id={paramButtonName} isInvalid={false} onClick={existingParamButtonClick} uppercase={false} description={paramObj[0]}></EuiExpression>}
      id={paramPopoverName}
      key={paramPopoverName}
      isOpen={paramPopoverName === currentOpenPopover ? true : false}
      closePopover={closePopoverV2}
    >
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiFieldText
            onBlur={(evt: any) => {
              if (evt.currentTarget.value) {
                paramObj[0] = evt.currentTarget.value
              }

              props.refreshFn()

              return undefined
            }}
            //inputRef={parameterRefKey}
            name={paramPopoverName + '-key'}
            defaultValue={paramObj[0]}
            compressed
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSelect
            onChange={(evt: any) => {
              if (evt.currentTarget.value) {
                paramObj[2] = evt.currentTarget.value
              }

              props.refreshFn()

              return undefined
            }}
            //inputRef={parameterRefVersion}
            name={paramPopoverName + '-version'}
            defaultValue={paramObj[2] ? paramObj[2] : 'v1'}
            options={[{ value: '', text: '' }, { value: 'v1', text: 'v1' }, { value: 'v2', text: 'v2' }]}
            compressed
          />
        </EuiFlexItem>
        <EuiFlexItem>
          {paramObj[2] && paramObj[2] === 'v2' ? <EuiSwitch
            onChange={(evt: any) => {
              if (Array.isArray(paramObj) && !paramObj[3]) {
                paramObj.push({})
              }
              paramObj[3] = {
                ...paramObj[3],
                optional: evt.currentTarget.checked
              }
              props.refreshFn()
              return undefined
            }}
            label='optional'
            checked={paramObj[3] && paramObj[3].optional ? paramObj[3].optional : false}></EuiSwitch>
            : parameterExpressionDropdown(expressionsArray, currentQueryObj, parameterRefValue, paramPopoverName + '-value')}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover></EuiFlexItem><EuiFlexItem>
        <EuiButtonIcon
          color='danger'
          onClick={(evt: any) => {
            if (queryObj.parameters) {
              queryObj.parameters.splice(paramIndex, 1)

              props.refreshFn()
            }

            return undefined
          }}
          iconType="minusInCircleFilled"
        />
      </EuiFlexItem></EuiFlexGroup></>
  }
  function delParamButtonClick(evt: any) {
    var currentQueryIndex: number = evt.target.id.split('-')[1]
    var currentParamIndex: number = evt.target.id.split('-')[3]

    var paramsArray: any[] = allQueries[currentQueryIndex].parameters

    paramsArray.splice(currentParamIndex, 1)

    props.refreshFn()

    return evt
  }
  function existingParamButtonClick(evt: any) {
    var popoverName = evt.currentTarget.id + '-popover'
    props.setOpenPopoverFn(popoverName)

    props.refreshFn()

    return evt
  }
  function onChangeOrigQueryDropdown(evt: any) {
    var selectedResultsetQuery: any = evt.target.value
    var currentItemName: any = evt.currentTarget.name.split('-')[0]
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    var queries = allQueries
    currentItemObj = allQueries[currentItemIndex]
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    currentItemObj.origQuery = selectedResultsetQuery
    queries[currentItemIndex] = currentItemObj

    //setAllQueries(queries)
    //setQueriesHtml(queryHtml(queries, allQueriesResults))

    props.refreshFn()
  }
  function nonTransformQueriesDropdown(queryList: any) {
    var nonTransformQueries: any[] = [{ value: '', text: '' }]
    queryList.map((item: any, index: number) => {
      if (item.type !== 'querytransform') {
        nonTransformQueries.push({ value: item.id, text: item.id })
      }

      return null
    })

    var dropdownId: any = props.queryName + '-origQueryDropdown'

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeOrigQueryDropdown}
      options={nonTransformQueries}
      value={queryObj && queryObj.origQuery ? queryObj.origQuery : ''}
      compressed
    />
  }
  function newParamButton(buttonName: any) {

    return <><EuiButtonIcon
      id={buttonName}
      key={buttonName}
      color='primary'
      onClick={newParamButtonClick}
      iconType="plusInCircleFilled"
    /></>
  }
  function newParamButtonClick(evt: any) {
    var currentQueryIndex: number = evt.currentTarget.id.split('-')[1]
    var currentQueryObj: any = allQueries[currentQueryIndex]

    if (!currentQueryObj.parameters) {
      currentQueryObj.parameters = []
    }

    var paramIndex: number = currentQueryObj.parameters.length
    var newParamName = currentQueryObj.id + '-' + currentQueryIndex + '-param-' + paramIndex

    currentQueryObj.parameters.push([newParamName, ''])
    //setOpenPopover(popoverName)

    props.refreshFn()

    return evt
  }
  function newParamButtonPopover(currentQueryObj: any) {
    if (!currentQueryObj.parameters) {
      currentQueryObj.parameters = []
    }
    var paramIndex: number = currentQueryObj.parameters.length

    var paramPopoverName = currentQueryObj.id + '-param-' + paramIndex + '-popover'
    var paramButtonName = currentQueryObj.id + '-param-' + paramIndex

    return <><EuiPopover
      button={<><EuiButtonIcon
        id={paramButtonName}
        key={paramButtonName}
        color='primary'
        onClick={(evt: any) => {
          if (!currentQueryObj.parameters) {
            currentQueryObj.parameters = []
          }

          let paramIndex: number = currentQueryObj.parameters.length
          let newParamName = currentQueryObj.id + '-param-' + paramIndex

          currentQueryObj.parameters.push([newParamName, ''])
          //setOpenPopover(popoverName)

          props.refreshFn()

          return undefined
        }}
        iconType="plusInCircleFilled"
      /></>}
      id={paramPopoverName}
      key={paramPopoverName}
      //isOpen={paramPopoverName === currentOpenPopover ? true : false}
      closePopover={closePopover}
    >
      <EuiPopoverTitle>New Parameter</EuiPopoverTitle>
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiFieldText
            onChange={newParamNameOnChange}
            name={paramPopoverName + '-field'}
            //inputRef={newParamName}
            compressed
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover></>
  }
  function batchPropToIterateDropdown(resultSet: any, currentQueryId: any) {
    var queryResultsetList: any[] = [{ value: '', text: '' }]
    var dropdownId = currentQueryId + '-resultsetDropdown'
    var currentQueryObj = allQueries.find((result: any) => result.id === currentQueryId) || null
    var selectedItem = ''

    if (currentQueryObj.propToIterate) {
      selectedItem = currentQueryObj.propToIterate
    }

    if (resultSet.queryResult) {
      var sampleRowColumns: any[] = Object.keys(resultSet.queryResult.transformed[0])
    }
    else {
      sampleRowColumns = []
    }

    sampleRowColumns.map((item: any, index: number) => {
      queryResultsetList.push({ value: item, text: item })

      return null
    })

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeQueryResultsetDropdown}
      options={queryResultsetList}
      defaultValue={selectedItem}
      compressed
    />
  }
  function baseUrl(queryType: any) {
    var baseUrl = ''

    switch (queryType) {
      case 'loganalytics':
        baseUrl = 'https://management.azure.com';
        break;
      case 'azuremanagement':
        baseUrl = 'https://management.azure.com';
        break;
      case 'azureresourcegraph':
        baseUrl = 'https://management.azure.com';
        break;
      case 'kusto':
        baseUrl = 'https://azdataexplorerclu02.eastus.kusto.windows.net';
        break;
      default:
        break;
    }

    return baseUrl
  }
  function onChangeQueryResultsetDropdown(evt: any) {
    var queries = allQueries
    var currentQueryName: any = evt.currentTarget.name.split('-')[0]
    var currentQueryIndex: number = queries.findIndex((result: any) => result.id === currentQueryName)
    var currentQueryObj: any = {}
    //var queries = allQueries
    //var selectedQuery: any = evt.target.value
    currentQueryObj = queries[currentQueryIndex]
    //var selectedQueryObj: any = allQueries.find((result: any) => result.id === selectedQuery)
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    currentQueryObj.propToIterate = evt.currentTarget.value
    //allQueries[currentItemIndex] = currentItemObj

    //setAllQueries(queries)
    ////setQueriesHtml(queryHtml(queries, allQueriesResults))

  }
  function transformExprDropdown(queryObj: any, dropdownRef: any) {
    var outputObj = <></>

    var queryName = queryObj.id

    var dropdownArray: any[] = []

    allExpressions.map((item: any) => {
      var exprEntry: any = { value: item.id, text: item.id }
      if (item.type === 'querytransform') {
        dropdownArray.push(exprEntry)
      }

      return null
    })

    outputObj = <EuiSelect
      options={dropdownArray}
      inputRef={dropdownRef}
      name={queryName + '-transform'}
      onChange={onChangeTransformFnDropdown}
      hasNoInitialSelection={queryObj && queryObj.transformFn ? false : true}
      value={queryObj && queryObj.transformFn ? queryObj.transformFn : ''}
      compressed />

    return outputObj
  }
  function queryTypeDropdownRef(selectedItem: any, dropdownId: any, queryRef: any) {

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeDropdown}
      inputRef={queryRef}
      options={[
        { value: '', text: '' },
        { value: 'loganalytics', text: 'Log Analytics Query' },
        { value: 'loganalyticsio', text: 'LogAnalytics.io Query' },
        { value: 'kusto', text: 'Azure Data Explorer (Kusto)' },
        { value: 'azureresourcegraph', text: 'Azure Resource Graph' },
        { value: 'azuremanagement', text: 'Azure Management REST' },
        { value: 'msgraph', text: 'Microsoft Graph REST' },
        { value: 'azuremgmtbatch', text: 'Azure Management Batch' },
        { value: 'okta', text: 'Okta REST API' },
        { value: 'aws', text: 'AWS API' },
        { value: 'simplequery', text: 'Simple / Manual Resultset Entry' },
        { value: 'querytransform', text: 'Transform Existing' },
      ]}
      value={selectedItem}
      compressed
    />
  }
  function newParamNameOnChange(evt: any) {
    var newParamNameValue: any = evt.target.value
    var queryIndex: number = evt.target.name.split('-')[1]

    var queryObj: any = allQueries[queryIndex]

    if (!queryObj.parameters) {
      queryObj.parameters = []
    }

    var newParamObj: any[] = []
    newParamObj.push(newParamNameValue)

    queryObj.parameters.push(newParamObj)

    //setRefreshNeeded(true)

    return evt
  }
  function onChangeLogAnalyticsSourceDropdown(evt: any) {
    var logAnalyticsSource: any = evt.target.value
    var currentItemName: any = evt.currentTarget.name.split('-')[0]
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    var queries = allQueries
    currentItemObj = allQueries[currentItemIndex]
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    currentItemObj.logAnalyticsSource = logAnalyticsSource
    queries[currentItemIndex] = currentItemObj

    props.refreshFn()

    //setAllQueries(queries)
    //setQueriesHtml(queryHtml(queries, allQueriesResults))

  }
  function onChangeKustoSourceDropdown(evt: any) {
    var kustoSource: any = evt.target.value
    var currentItemName: any = evt.currentTarget.name.split('-')[0]
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    var queries = allQueries
    currentItemObj = allQueries[currentItemIndex]
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    currentItemObj.kustoSource = kustoSource
    queries[currentItemIndex] = currentItemObj

    props.refreshFn()

    //setAllQueries(queries)
    //setQueriesHtml(queryHtml(queries, allQueriesResults))

  }
  function logAnalyticsSourceDropdown(currentQueryName: any) {
    let logAnalyticsSourceList: any[] = [
      { value: '', text: '' },
      { value: '/subscriptions/c5322680-4315-47f9-9ed3-542a36c86e95/resourceGroups/sentinel-rg/providers/Microsoft.OperationalInsights/workspaces/jarboeco-loganalytics01', text: 'jarboeco-loganalytics01' },
    ]

    var currentQueryObj = allQueries.find((result: any) => result.id === currentQueryName) || null

    var dropdownId = currentQueryName + '-logAnalyticsSourceDropdown'

    var selectedItem = ''
    if (currentQueryObj.logAnalyticsSource) {
      selectedItem = currentQueryObj.logAnalyticsSource
    }

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeLogAnalyticsSourceDropdown}
      options={logAnalyticsSourceList}
      value={selectedItem}
      compressed
    />
  }
  function kustoSourceDropdown(currentQueryName: any) {
    var kustoSourceList: any[] = [
      { value: '', text: '' },
      { value: 'azdataexplorerclu02/eastus/pr-powerbi01', text: 'pr-powerbi01' }
    ]

    var currentQueryObj = allQueries.find((result: any) => result.id === currentQueryName) || null

    var dropdownId = currentQueryName + '-kustoSourceDropdown'

    var selectedItem = ''
    if (currentQueryObj.kustoSource) {
      selectedItem = currentQueryObj.kustoSource
    }

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeKustoSourceDropdown}
      options={kustoSourceList}
      value={selectedItem}
      compressed
    />
  }
  function restUriDropdown(currentQueryName: any) {
    var uriList: any[] = expressionsArray.map((item: any) => {
      return { value: item, text: item }
    })

    var currentQueryObj = allQueries.find((result: any) => result.id === currentQueryName) || null

    var dropdownId = currentQueryName + '-uriExprDropdown'

    var selectedItem = ''
    if (currentQueryObj && currentQueryObj.queryRest && currentQueryObj.queryRest.uri) {
      selectedItem = currentQueryObj.queryRest.uri
    }

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeUriDropdown}
      options={uriList}
      value={selectedItem}
      compressed
    />
  }
  function postBodyDropdown(currentQueryName: any) {
    var exprList: any[] = expressionsArray.map((item: any) => {
      return { value: item, text: item }
    })

    var currentQueryObj = allQueries.find((result: any) => result.id === currentQueryName) || null

    var dropdownId = currentQueryName + '-postBodyDropdown'

    var selectedItem = ''
    if (currentQueryObj && currentQueryObj.queryRest && currentQueryObj.queryRest.bodyExpr) {
      selectedItem = currentQueryObj.queryRest.bodyExpr
    }

    return <EuiSelect
      name={dropdownId}
      onChange={onChangePostBodyDropdown}
      options={exprList}
      value={selectedItem}
      compressed
    />
  }
  function restMethodDropdown(currentQueryName: any) {
    var methods: any[] = methodsArray.map((item: any) => {
      return { value: item, text: item }
    })

    var currentQueryObj = allQueries.find((result: any) => result.id === currentQueryName) || null

    var dropdownId = currentQueryName + '-restMethodsDropdown'

    var selectedItem = ''
    if (currentQueryObj && currentQueryObj.queryRest && currentQueryObj.queryRest.method) {
      selectedItem = currentQueryObj.queryRest.method
    }

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeRestMethodDropdown}
      options={methods}
      value={selectedItem}
      compressed
    />
  }
  function onChangeRestMethodDropdown(evt: any) {
    var restMethod: any = evt.target.value
    var currentItemName: any = evt.currentTarget.name.split('-')[0]
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    var queries = allQueries
    currentItemObj = allQueries[currentItemIndex]
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    if (!currentItemObj.queryRest) {
      currentItemObj.queryRest = {
        method: ''
      }
    }

    currentItemObj.queryRest.method = restMethod

    queries[currentItemIndex] = currentItemObj

    props.refreshFn()

    //setAllQueries(queries)
    //setQueriesHtml(queryHtml(queries, allQueriesResults))

  }
  function onChangeUriDropdown(evt: any) {
    var uriExpr: any = evt.target.value
    var currentItemName: any = evt.currentTarget.name.split('-')[0]
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    var queries = allQueries
    currentItemObj = allQueries[currentItemIndex]
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    if (!currentItemObj.queryRest) {
      currentItemObj.queryRest = {
        uri: ''
      }
    }

    currentItemObj.queryRest.uri = uriExpr

    queries[currentItemIndex] = currentItemObj

    props.refreshFn()

    //setAllQueries(queries)
    //setQueriesHtml(queryHtml(queries, allQueriesResults))

  }
  function onChangeTransformFnDropdown(evt: any) {
    var transformExpr: any = evt.target.value
    var currentItemName: any = evt.currentTarget.name.split('-')[0]
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    var queries = allQueries
    currentItemObj = allQueries[currentItemIndex]
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    currentItemObj.transformFn = transformExpr

    queries[currentItemIndex] = currentItemObj

    props.refreshFn()

    //setAllQueries(queries)
    //setQueriesHtml(queryHtml(queries, allQueriesResults))

  }
  function onChangePostBodyDropdown(evt: any) {
    var postBodyExpr: any = evt.target.value
    var currentItemName: any = evt.currentTarget.name.split('-')[0]
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    var queries = allQueries
    currentItemObj = allQueries[currentItemIndex]
    //var selectedQueryResultObj: any = allQueriesResults.findIndex((result: any) => result.id === selectedQuery)

    if (!currentItemObj.queryRest) {
      currentItemObj.queryRest = {
        bodyExpr: ''
      }
    }

    currentItemObj.queryRest.bodyExpr = postBodyExpr

    queries[currentItemIndex] = currentItemObj

    props.refreshFn()

    //setAllQueries(queries)
    //setQueriesHtml(queryHtml(queries, allQueriesResults))

  }
  function onChangeDropdown(evt: any) {
    var currentItemName: number = evt.target.name.split('-')[0]
    var currentItem: any = getQueryObjByNameInMem(currentItemName, allQueries)
    var currentQueryIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var pageQueries: any = allQueries

    currentItem.type = evt.currentTarget.value
    if (evt.currentTarget.value === 'azuremanagement' || evt.currentTarget.value === 'msgraph' || evt.currentTarget.value === 'okta') {
      if (!currentItem.queryRest) {
        currentItem.queryRest = {}
      }
    }

    pageQueries[currentQueryIndex] = currentItem

    //setAllQueries(pageQueries)
    //setQueriesHtml(queryHtml(pageQueries, pageQueryPreviews))

    props.refreshFn()
  }
  function onChangeQueryExpressionDropdown(evt: any) {
    let expressionIdQuery: any = evt.currentTarget.name + 'Expr'
    if (!queryObj.exprs) {
      queryObj.exprs = {}
    }

    if (!queryObj.exprs[expressionIdQuery]) {
      queryObj.exprs[expressionIdQuery] = {}
    }
    queryObj.exprs[expressionIdQuery] = evt.currentTarget.value

    //props.newRefreshFn()
    props.refreshFn()
  }

  function queryOptionsPanelRef(currentQueryObj: any, queryRef: any) {
    var queryType: any = currentQueryObj.type

    let currentQueryId: any = currentQueryObj.id

    var queryResultObjIndex: number = -2
    var queryResultObj: any = {}

    if (currentQueryObj.origQuery) {
      queryResultObjIndex = allQueriesResults.findIndex((result: any) => result.id === currentQueryObj.origQuery)

      if (queryResultObjIndex !== -1) {
        queryResultObj = allQueriesResults[queryResultObjIndex]
      }
    }

    if (!currentQueryObj.parameters) {
      currentQueryObj.parameters = []
    }

    var paramPopoverArray: JSX.Element[] = []

    currentQueryObj.parameters.map((item: any, index: number) => {
      let paramObj: JSX.Element = <></>

      if (item[2] && item[2] === 'v2') {
        paramObj =
          <EuiFlexGroup>
            <EuiFlexItem>
              {existingParamButtonPopover(currentQueryObj, index)}
            </EuiFlexItem>
            <EuiFlexItem>
              {expressionDropdownWithParameters('param-' + currentQueryObj.id + '-' + index)}
            </EuiFlexItem>
          </EuiFlexGroup>
      }
      else {
        paramObj = existingParamButtonPopover(currentQueryObj, index)
      }
      paramPopoverArray.push(paramObj)

      return null
    })

    paramPopoverArray.push(newParamButtonPopover(currentQueryObj))

    var panelElement: JSX.Element = <></>

    var batchUri = ''

    if (currentQueryObj.uri) {
      batchUri = currentQueryObj.uri
    }

    switch (queryType) {
      case 'querytransform':
        let queryParamValueDropdownOptions: any = [{ value: '', text: '' }]
        if (queryObj && queryObj.queryParams) {
          queryObj.queryParams.map((item: any) => {
            queryParamValueDropdownOptions.push({ text: item.paramId, value: item.paramId })
          })
        }
        panelElement = <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="transform function">{expressionDropdownWithParameters('transformFunction')}</EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        break;
      case 'simplequery':
        panelElement = <></>
        break;
      case 'azuremgmtbatch':
        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="query result">{queryResultsetDropdown(currentQueryObj.id)}</EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            {currentQueryObj.origQuery ? <EuiFormRow label="property to iterate">{batchPropToIterateDropdown(queryResultObj, currentQueryId)}</EuiFormRow> : <></>}
          </EuiFlexItem>
        </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="uri"><EuiFieldText defaultValue={batchUri} compressed name='uri' /></EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup></>
        break;
      case 'azuremanagement':
        var uriRef: any = createRef()
        var methodRef: any = createRef()
        var azMgmtRefObj: any = {}

        azMgmtRefObj.uriRef = uriRef
        azMgmtRefObj.methodRef = methodRef

        props.queryRef.optionsRef = azMgmtRefObj

        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="uri">{expressionDropdownWithParameters('azMgmtUri')}</EuiFormRow>
          </EuiFlexItem></EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="parameters"><>{paramPopoverArray}</></EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="method">{restMethodDropdown(currentQueryId)}</EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          {currentQueryObj && currentQueryObj.queryRest && currentQueryObj.queryRest.method && currentQueryObj.queryRest.method === 'POST' ? <EuiFlexItem><EuiFormRow label="post body">{expressionDropdownWithParameters('postBody')}</EuiFormRow></EuiFlexItem> : <></>}
        </>
        break;
      case 'msgraph':
        uriRef = createRef()
        methodRef = createRef()
        var msGraphRefObj: any = {}

        msGraphRefObj.uriRef = uriRef
        msGraphRefObj.methodRef = methodRef

        props.queryRef.optionsRef = msGraphRefObj

        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="uri">{expressionDropdownWithParameters('graphUri')}</EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="parameters"><>{paramPopoverArray}</></EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="method">{restMethodDropdown(currentQueryId)}</EuiFormRow>
            <EuiFormRow label="headers"><EuiSwitch label="eventual consistency" onChange={((evt: any) => {
              if (!currentQueryObj.options) {
                currentQueryObj.options = {}
              }
              currentQueryObj.options['eventualConsistency'] = evt.target.checked
              let refreshFn: any = props.refreshFn
              refreshFn()
            })} checked={currentQueryObj.options && currentQueryObj.options['eventualConsistency'] ? currentQueryObj.options['eventualConsistency'] : false}></EuiSwitch></EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
          {currentQueryObj && currentQueryObj.queryRest && currentQueryObj.queryRest.method && currentQueryObj.queryRest.method === 'POST' ? <EuiFlexItem><EuiFormRow label="post body">{postBodyDropdown(currentQueryId)}</EuiFormRow></EuiFlexItem> : <></>}
        </>
        break;
      case 'okta':
        uriRef = createRef()
        methodRef = createRef()
        var oktaRefObj: any = {}

        oktaRefObj.uriRef = uriRef
        oktaRefObj.methodRef = methodRef

        props.queryRef.optionsRef = oktaRefObj

        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="uri">{expressionDropdownWithParameters('oktaUri')}</EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="parameters"><>{paramPopoverArray}</></EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="method">{restMethodDropdown(currentQueryId)}</EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
          {currentQueryObj && currentQueryObj.queryRest && currentQueryObj.queryRest.method && currentQueryObj.queryRest.method === 'POST' ? <EuiFlexItem><EuiFormRow label="post body">{postBodyDropdown(currentQueryId)}</EuiFormRow></EuiFlexItem> : <></>}
        </>
        break;
      case 'loganalytics':
        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="log analytics source">{logAnalyticsSourceDropdown(currentQueryId)}</EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup></>
        break;
      case 'loganalyticsio':
        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="log analytics source">{logAnalyticsSourceDropdown(currentQueryId)}</EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup></>
        break;
      case 'kusto':
        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="kusto source">{kustoSourceDropdown(currentQueryId)}</EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup></>
        break;
      case 'aws':
        panelElement = <>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={3}>
                <EuiFormRow label="api">
                  <EuiSelect
                    compressed
                    options={[
                      {
                        value: 'ec2', text: 'ec2'
                      }, {
                        value: 's3', text: 's3'
                      },
                      {
                        value: 'cloudwatch', text: 'Cloudwatch'
                      },
                      {
                        value: 'cloudtrail', text: 'CloudTrail'
                      },
                      {
                        value: 'iam', text: 'IAM'
                      }]}
                    onChange={(evt: any) => {/*
                      if (!queryObj.options) {
                        queryObj.options = {}
                      }
                      if (evt.currentTarget.value !== '') {
                        queryObj.options.awsApi = evt.currentTarget.value
                      }
                      else {
                        delete queryObj.options.awsApi
                      }

                      return undefined
                    */ }}
                    //defaultValue={queryObj.options && queryObj.options.awsApi ? queryObj.options.awsApi : undefined}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={8}>
                <EuiFormRow label="command">
                  <EuiFieldText
                    compressed
                    onBlur={(evt: any) => {
                      if (!queryObj.options) {
                        queryObj.options = {}
                      }
                      if (evt.currentTarget.value !== '') {
                        queryObj.options.commandText = evt.currentTarget.value
                      }
                      else {
                        delete queryObj.options.commandText
                      }

                      return undefined
                    }}
                    defaultValue={queryObj.options && queryObj.options.commandText ? queryObj.options.commandText : undefined}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="parameters"><>{paramPopoverArray}</></EuiFormRow>
          </EuiFlexItem>
        </>
        break;
      default:
        panelElement = <EuiFormRow label="url"><EuiFieldText readOnly value={baseUrl(queryType)} compressed name='url' /></EuiFormRow>
        break;
    }
    return panelElement

  }
  function saveClick(evt: any) {
    /*localStorage.setItem('allQueries', JSON.stringify(allQueries))
    localStorage.setItem('allQueriesResults', JSON.stringify(allQueriesResults))*/

    return evt
  }
  async function previewClickSingleQuery(evt: any) {
    var loadingSpinners: any[] = props.loadingSpinnersArray
    loadingSpinners.push(props.queryName)
    props.setLoadingSpinnersArray(loadingSpinners)
    props.refreshFn()

    var currentItemName: any = evt.currentTarget.id.split('-')[0]
    var currentItem: any = getQueryByName(currentItemName, allQueries)

    if (currentItem.queryClass && currentItem.queryClass === 'pageQuery') {
      currentItem = resolveAllExprArgs(currentItem, allExpressions)
    }

    if (props.queryRef.queryTextRef.current) {
      currentItem.query = props.queryRef.queryTextRef.current.value
    }

    if (!(currentItem.queryRest)) {
      currentItem.queryRest = {}
    }
    /*var queryRestOptions = {
      method: currentItem.queryRest.method,
      uri: currentItem.queryRest.uri
    }
    currentItem.queryRest = queryRestOptions*/

    if (props.queryRef.transformFnRef.current) {
      currentItem.transformFn = props.queryRef.transformFnRef.current.value
    }

    var currentItemResultIndex: number = allQueriesResults.findIndex((result: any) => result.id === currentItem.id)

    var queryResults: any[] = allQueriesResults

    await GetQueryResult(currentItem, props.tokens, allExpressions)
      .then(result => {
        var currentItemResult: any = {}
        currentItemResult.queryResult = result
        currentItemResult.timestamp = new Date().getTime()
        currentItemResult.id = currentItem.id

        var loadingSpinnerIndex: number = props.loadingSpinnersArray.indexOf(props.queryName)
        props.loadingSpinnersArray.splice(loadingSpinnerIndex, 1)
        props.setLoadingSpinnersArray(props.loadingSpinnersArray)

        if (currentItemResultIndex !== -1) {
          queryResults[currentItemResultIndex] = currentItemResult
          props.refreshFn()
          /////setAllQueriesResults(queryResults)
          ////setRefreshNeeded(true)
          //setQueriesHtml(queryHtml(pageQueries, queryResults))
        }
        else {
          queryResults.push(currentItemResult)
          props.refreshFn()
          ////setAllQueriesResults(queryResults)
          ////setRefreshNeeded(true)
          //setQueriesHtml(queryHtml(pageQueries, queryResults))
        }
      })

    return undefined
  }

  function updateQueryClick(evt: any) {
    var currentItemIndex: number = evt.currentTarget.id.split('-')[1]
    var currentItem: any = allQueries[currentItemIndex]

    if (props.queryRef.queryGroupsRef.current.state.items) {
      var groupsArray: any[] = []
      currentItem.queryGroups = groupsArray
      props.queryRef.queryGroupsRef.current.state.items.map((item: any) => {
        if (item.key) {
          currentItem.queryGroups.push(item.key)
        }
        else if (item.id) {
          currentItem.queryGroups.push(item.id)
        }

        return null
      })
    }

    return undefined

  }
  function updateQueryGroups(selectedItems: any) {
    var currentItemName: any = props.queryName
    var currentItemIndex: number = allQueries.findIndex((result: any) => result.id === currentItemName)
    var currentItemObj: any = {}
    currentItemObj = allQueries[currentItemIndex]

    currentItemObj.queryGroups = []

    selectedItems.map((item: any) => {
      currentItemObj.queryGroups.push(item.key)

      return null
    })

    props.refreshFn()

  }
  function closePopover() {
    var currentQueryIndex: any = props.currentOpenPopover.split('-')[1]
    var currentParamIndex: any = props.currentOpenPopover.split('-')[3]
    var currentParamObj: any[] = allQueries[currentQueryIndex].parameters[currentParamIndex]

    if (!currentParamObj[0]) {
      currentParamObj = []
    }
    currentParamObj[0] = props.queryRef.parametersRefArray[currentParamIndex][0].current.value
    if (props.queryRef.parametersRefArray[currentParamIndex] && props.queryRef.parametersRefArray[currentParamIndex][1] && props.queryRef.parametersRefArray[currentParamIndex][1].current !== null) {
      currentParamObj[1] = props.queryRef.parametersRefArray[currentParamIndex][1].current.value
    }
    else {
      currentParamObj[1] = ''
    }
    if (props.queryRef.parametersRefArray[currentParamIndex][2]) {
      currentParamObj[2] = props.queryRef.parametersRefArray[currentParamIndex][2].current.value
    }

    if (currentParamObj[0] !== '') {
      props.setOpenPopoverFn('')
      props.refreshFn()
    }
    else {
      currentParamObj[0] = props.currentOpenPopover
      props.setOpenPopoverFn('')
      props.refreshFn()
    }

  }
  function expressionDropdownWithParameters(exprProp: any) {
    if (!queryObj.exprArgs) {
      queryObj.exprArgs = []
    }

    if (!queryObj.exprs) {
      queryObj.exprs = {}
    }

    if (!queryObj.exprArgs.includes(exprProp)) {
      queryObj.exprArgs.push(exprProp)
    }

    var exprPropName: any = exprProp + "Expr"
    var exprParamName: any = exprPropName + "Params"

    function valueSourceDropdownOnChange(evt: any) {
      if (queryObj.exprs[exprParamName] && queryObj.exprs[exprParamName][evt.currentTarget.name]) {
        queryObj.exprs[exprParamName][evt.currentTarget.name].valueType = evt.currentTarget.value
      }

      props.refreshFn()
      return undefined
    }

    function valueValueDropdownOnChange(evt: any) {
      if (queryObj.exprs[exprParamName] && queryObj.exprs[exprParamName][evt.currentTarget.name]) {
        queryObj.exprs[exprParamName][evt.currentTarget.name].queryParam = evt.currentTarget.value
      }

      if (exprParamName.includes('param-')) {
        let uriQueryParamIndex: any = exprParamName.split('-')[2].split('ExprParams')[0]
        let uriQueryParamObj: any = queryObj.parameters[uriQueryParamIndex]

        if (!uriQueryParamObj[3]) {
          uriQueryParamObj[3] = {}
        }
        uriQueryParamObj[3] = {
          ...uriQueryParamObj[3],
          exprId: exprParamName
        }
        if (queryObj.exprs[exprParamName] && queryObj.exprs[exprParamName][evt.currentTarget.name]) {
          queryObj.exprs[exprParamName][evt.currentTarget.name].uriQueryParamIndex = uriQueryParamIndex
        }

      }

      props.refreshFn()
      return undefined
    }

    var returnObj: any

    var validExpressionsArray: any = allExpressions.filter((expr: any) => {
      if ((expr.type === 'function' || expr.type === 'complex' || expr.type === 'text') && expr.public) {
        return true
      }
      return false
    })
    var expressionsDropdownArray: any[] = validExpressionsArray.map((item: any) => {
      return { value: item.id, text: item.id + ' - ' + item.type }
    })
    expressionsDropdownArray.unshift({ value: '', text: '' })

    let expressionName: any
    if (queryObj.exprs[exprPropName]) {
      expressionName = queryObj.exprs[exprPropName]
    }

    let expressionObjMutable: any
    let expressionObj: any
    if (expressionName) {
      expressionObjMutable = allExpressions.find((result: any) => result.id === expressionName)
      if (expressionObjMutable) {
        expressionObj = JSON.parse(JSON.stringify({ ...expressionObjMutable }))
      }
    }

    let requiredParams: any[] = []
    if (expressionObj && expressionObj.type && expressionObj.type === 'complex') {
      expressionObj.complexExpression.map((subExpr: any) => {
        if (subExpr.parameters) {
          subExpr.parameters.map((subExprParam: any) => {
            requiredParams.push(subExprParam.paramValueId)
            return undefined
          })
        }
        return undefined
      })
    }
    else if (expressionObj && expressionObj.type && expressionObj.type === 'function') {
      if (expressionObj.parameters) {
        expressionObj.parameters.map((exprParm: any) => {
          requiredParams.push(exprParm.paramId)
          return undefined
        })
      }
    }

    let dropdownObj = <EuiSelect value={queryObj.exprs[exprPropName] ? queryObj.exprs[exprPropName] : undefined}
      name={exprProp}
      onChange={onChangeQueryExpressionDropdown}
      options={expressionsDropdownArray} ></EuiSelect>

    let parametersJsxArray: any = []

    requiredParams.forEach((item: any) => {
      let itemName: any
      if (expressionObj.type !== 'function') {
        itemName = item.split('-')[2]
      }
      else {
        itemName = item
      }
      let complexExpressionSubExpr: any
      let paramObj: any

      if (expressionObj.complexExpression) {
        complexExpressionSubExpr = expressionObj.complexExpression[item.split('-')[1]]
        if (complexExpressionSubExpr) {
          paramObj = complexExpressionSubExpr.parameters[item.split('-')[4]]
        }
      }
      else if (expressionObj.type === 'function') {
        paramObj = expressionObj.parameters.find((p: any) => p.paramId === item)
        if (!paramObj) {
          paramObj = {
            paramValue: item
          }
        }
      }

      if (!queryObj.exprs[exprParamName]) {
        queryObj.exprs[exprParamName] = {}
      }

      if (!queryObj.exprs[exprParamName][item]) {
        queryObj.exprs[exprParamName][item] = {
          valueType: 'default'
        }
      }

      var valueObj: JSX.Element = <></>

      if (queryObj.exprs[exprParamName][item].valueType === 'default') {
        valueObj = <EuiFlexItem>
          <EuiFormRow label="sample value">
            <EuiFieldText readOnly value={paramObj.paramValue} />
          </EuiFormRow>
        </EuiFlexItem>
      }
      else if (queryObj.exprs[exprParamName][item].valueType === 'queryparam') {
        let queryParamValueDropdownOptions: any = [{ value: '', text: '' }]
        if (queryObj && queryObj.queryParams) {
          queryObj.queryParams.map((item: any) => {
            queryParamValueDropdownOptions.push({ text: item.paramId, value: item.paramId })
          })
        }
        valueObj = <EuiFlexItem>
          <EuiFormRow label="query parameter">
            <EuiSelect name={item} onChange={valueValueDropdownOnChange} options={queryParamValueDropdownOptions}
              hasNoInitialSelection={queryObj.exprs[exprParamName][item].queryParam ? false : true}
              value={queryObj.exprs[exprParamName][item] && queryObj.exprs[exprParamName][item].queryParam ? queryObj.exprs[exprParamName][item].queryParam : ''} />
          </EuiFormRow>
        </EuiFlexItem>
      }

      let popoverObj: any = <EuiPopover
        button={<EuiExpression id={item} isInvalid={false} uppercase={false} description={itemName} onClick={existingParamButtonClick}></EuiExpression>}
        id={item + '-popover'}
        key={item + '-popover'}
        isOpen={queryObj.id + '-' + item + '-popover' === props.currentOpenPopover ? true : false}
        closePopover={closePopoverV2}
      >
        <EuiPopoverTitle>{item}</EuiPopoverTitle>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="value source">
              <EuiSelect name={item} onChange={valueSourceDropdownOnChange} options={[{ text: "", value: '' },
              { text: "Use Default Value", value: 'default' },
              { text: 'Override with query parameter', value: 'queryparam' }]}
                value={queryObj.exprs[exprParamName][item] && queryObj.exprs[exprParamName][item].valueType ? queryObj.exprs[exprParamName][item].valueType : ''} />
            </EuiFormRow>
          </EuiFlexItem>
          {valueObj}
        </EuiFlexGroup>
      </EuiPopover>

      parametersJsxArray.push(<EuiFlexItem>{popoverObj}</EuiFlexItem>)
    }, expressionObj)

    requiredParams.map((requiredParam: any) => {
      if (queryObj.exprs[exprParamName][requiredParam] && queryObj.exprs[exprParamName][requiredParam].valueType && queryObj.exprs[exprParamName][requiredParam].valueType === 'queryparam') {
        let queryParamName = queryObj.exprs[exprParamName][requiredParam].queryParam

        let queryParamObj: any

        if (queryObj && queryObj.queryParams) {
          queryParamObj = queryObj.queryParams.find((result: any) => result.paramId === queryParamName)
        }

        if (queryParamObj) {
          if (expressionObj.complexExpression && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]]) {
            // expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]].paramValue = queryParamObj.sampleValue
          }
          else if (expressionObj.type === 'function') {
            let parObjIndex: any = expressionObj.parameters.findIndex((par: any) => par.paramId === requiredParam)
            if (parObjIndex > -1) {
              expressionObj.parameters[parObjIndex].paramValue = queryParamObj.sampleValue
            }
          }
        }
      }

      return undefined
    })

    returnObj = <div style={{ borderStyle: 'solid', borderRadius: '10px', borderWidth: '1px' }}><EuiFlexGroup>
      <EuiFlexItem>
        {dropdownObj}
      </EuiFlexItem>
    </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup>
            {parametersJsxArray}
          </EuiFlexGroup>
          <div style={{ backgroundColor: 'lightblue', borderRadius: '10px' }}>
            {expressionObj ? JSON.stringify(evaluateExpression(expressionObj, { allExp: allExpressions })) : <></>}
          </div>
        </EuiFlexItem>
      </EuiFlexGroup></div>

    return returnObj
  }

  function queryPreviewPanel(currentQueryObj: any) {
    //var currentQueryObj: any = {}
    //var currentQueryObjIndex: number = allQueries.findIndex((result: any) => result.id === currentQueryId)
    //currentQueryObj = allQueries[currentQueryObjIndex]

    var currentQueryId = currentQueryObj.id
    var queryType: any = currentQueryObj.type

    var queryResultObjIndex: number = -2
    var queryResultObj: any = {}

    if (currentQueryObj.origQuery) {
      queryResultObjIndex = allQueriesResults.findIndex((result: any) => result.id === currentQueryObj.origQuery)

      if (queryResultObjIndex !== -1) {
        queryResultObj = allQueriesResults[queryResultObjIndex]
      }
    }

    var panelElement: JSX.Element = <></>

    var batchUri = ''

    if (currentQueryObj.uri) {
      batchUri = currentQueryObj.uri
    }

    switch (queryType) {
      case 'querytransform':
        panelElement = <EuiFormRow label="original query"><div id={queryResultObj.id + '-transformOriginalQuery'} style={{ minHeight: '80px', borderRadius: '4px', backgroundColor: 'lightgrey', maxHeight: '200px', overflow: 'scroll' }}>
          <ReactJson src={queryResultObj && queryResultObj.queryResult && queryResultObj.queryResult.transformed ? queryResultObj.queryResult.transformed : {}} />
        </div></EuiFormRow>
        break;
      case 'azuremgmtbatch':
        panelElement = <><EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="query result">{queryResultsetDropdown(currentQueryId)}</EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            {currentQueryObj.origQuery ? <EuiFormRow label="property to iterate">{batchPropToIterateDropdown(queryResultObj, currentQueryId)}</EuiFormRow> : <></>}
          </EuiFlexItem>
        </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="uri"><EuiFieldText defaultValue={batchUri} compressed name='uri' /></EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup></>
        break;
      case 'azuremanagement':
        panelElement = <></>
        break;
      case 'msgraph':
        panelElement = <></>
        break;
      case 'okta':
        panelElement = <></>
        break;
      case 'aws':
        panelElement = <></>
        break;
      default:
        if (currentQueryObj.queryClass && currentQueryObj.queryClass === 'pageQuery') {
          if (queryType !== '') {
            panelElement = <EuiFormRow label="query expression">{expressionDropdownWithParameters('query')}</EuiFormRow>
          }
          else {
            panelElement = <></>
          }
        }
        else {
          panelElement = <EuiFormRow label="query"><EuiTextArea compressed onChange={updateTextArea} readOnly={false} inputRef={props.queryRef.queryTextRef} value={currentQueryObj.query} id={currentQueryObj.id + '-queryText'} name={currentQueryObj.id + '-queryText'} /></EuiFormRow>
        }
        break;
    }

    return panelElement
  }
  function updateTextArea(evt: any) {
    var currentQueryObj: any = {}
    var currentQueryName: any = evt.currentTarget.name.split('-')[0]
    var currentQueryIndex: number = allQueries.findIndex((result: any) => result.id === currentQueryName)
    currentQueryObj = allQueries[currentQueryIndex]

    currentQueryObj.query = evt.currentTarget.value

    props.refreshFn()
  }
  function deleteQueryClick(evt: any) {
    var currentQueryName: any = props.queryName
    var currentQueryIndex: number = allQueries.findIndex((result: any) => result.id === currentQueryName)

    allQueries.splice(currentQueryIndex, 1)

    if (allQueriesResults.findIndex((result: any) => result.id === currentQueryName)) {
      var queryIndex: number = allQueriesResults.findIndex((result: any) => result.id === currentQueryName)
      if (queryIndex !== -1) {
        allQueriesResults.splice(queryIndex, 1)
      }
    }

    props.refreshFn()

    //setRefreshNeeded(true)

    return undefined
  }
  function queryResultsetDropdown(currentQueryName: any) {
    var queryResultsetList: any[] = [{ value: '', text: '' }]

    var currentQueryObj = allQueries.find((result: any) => result.id === currentQueryName) || null

    allQueriesResults.map((item: any, index: number) => {
      if (item.type !== 'querytransform') {
        queryResultsetList.push({ value: item.id, text: item.id })
      }

      return null
    })

    var dropdownId = currentQueryName + '-resultsetDropdown'

    var selectedItem = ''
    if (currentQueryObj.origQuery) {
      selectedItem = currentQueryObj.origQuery
    }

    return <EuiSelect
      name={dropdownId}
      onChange={onChangeOrigQueryDropdown}
      options={queryResultsetList}
      defaultValue={selectedItem}
      compressed
    />
  }
  function parameterExpressionDropdown(dropdownItems: any[], queryObj: any, dropdownRef: any, paramId: any) {
    let outputObj = <></>
    let currentParamIndex: number = paramId.split('-')[2]
    let currentParamObj: any[] = queryObj.parameters[currentParamIndex]

    var dropdownArray: any[] = []

    dropdownItems.map((item: any) => {
      var colorEntry: any = { value: item, text: item }
      dropdownArray.push(colorEntry)

      return null
    })

    outputObj = <EuiSelect
      options={dropdownArray}
      inputRef={dropdownRef}
      name={paramId}
      //hasNoInitialSelection={currentParamObj && currentParamObj[1] !== '' ? false : true}
      defaultValue={currentParamObj && currentParamObj[1] !== '' ? currentParamObj[1] : null}
      compressed />

    return outputObj
  }
  function filterSuggestedTags(filterText: string, tagList: ITag[] | undefined) {
    var outputObj: any[] = []

    if (props.queryGroups) {
      outputObj = props.queryGroups.filter((tag: ITag) => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0)
      /*props.queryGroups.map((item: any) => {
        outputObj.push({ key: item.id, name: item.name })
      })*/
    }
    return outputObj
  };

  function newQueryParamButtonClick(evt: any) {
    var popoverName = queryObj.id + '-' + evt.currentTarget.id + '-popover'
    props.setOpenPopoverFn(popoverName)

    props.refreshFn()

    return evt
  }

  function newQueryParamButton(buttonName: any) {

    return <><EuiButtonIcon
      id={buttonName}
      key={buttonName}
      color='primary'
      onClick={newQueryParamButtonClick}
      iconType="plusInCircleFilled"
    /></>
  }

  function newQueryParamButtonAddClick(evt: any) {
    var newParameterName = ''
    var newParameterSampleValue

    if (props.newQueryParamFieldsRefObj.newQueryParamNameField.current) {
      newParameterName = props.newQueryParamFieldsRefObj.newQueryParamNameField.current.value
    }

    if (props.newQueryParamFieldsRefObj.newQueryParamSampleValueField.current) {
      newParameterSampleValue = props.newQueryParamFieldsRefObj.newQueryParamSampleValueField.current.value
    }

    if (newParameterName !== '') {
      if (!queryObj.queryParams) {
        queryObj.queryParams = []
      }
      queryObj.queryParams.push({ paramId: newParameterName, sampleValue: newParameterSampleValue })
      closePopoverV2()
    }
  }

  function paramPopoverFieldOnChange(evt: any) {
    var paramId: any = props.currentOpenPopover.split('-')[1]
    var paramIndex: number = queryObj.queryParams.findIndex((result: any) => result.paramId === paramId)
    var paramObj: any = queryObj.queryParams[paramIndex]

    var fieldBeingChanged: any = evt.currentTarget.name.split('-')[1]

    if (evt.currentTarget.value !== '') {
      paramObj[fieldBeingChanged] = evt.currentTarget.value
    }
    else {
      delete paramObj[fieldBeingChanged]
    }

    props.refreshFn()

    return undefined
  }

  function newQueryParamPopover() {
    var paramPopoverName = queryObj.id + '-newQueryParam-popover'
    var paramButtonName = queryObj.id + '-newQueryParam'

    return <><EuiPopover
      button={newQueryParamButton(paramButtonName)}
      id={paramPopoverName}
      key={paramPopoverName}
      closePopover={closePopoverV2}
      isOpen={paramPopoverName === props.currentOpenPopover ? true : false}
    >
      <EuiPopoverTitle >New Parameter</EuiPopoverTitle>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="parameter name">
            <EuiFieldText inputRef={props.newQueryParamFieldsRefObj.newQueryParamNameField} placeholder="paramName" />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="sample value">
            <EuiFieldText inputRef={props.newQueryParamFieldsRefObj.newQueryParamSampleValueField} placeholder="sample value" />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow hasEmptyLabelSpace>
            <EuiButton onClick={newQueryParamButtonAddClick}>Add</EuiButton>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover></>
  }

  function closePopoverV2() {
    props.setOpenPopoverFn('')
    props.refreshFn()
  }

  function existingQueryParamButtonClick(evt: any) {
    props.setOpenPopoverFn(evt.currentTarget.id)

    props.refreshFn()

    return evt
  }

  function existingQueryParamDeleteClick(evt: any) {
    var paramId: any = props.currentOpenPopover.split('-')[1]
    var paramIndex: number = queryObj.queryParams.findIndex((result: any) => result.paramId === paramId)

    queryObj.queryParams.splice(paramIndex, 1)

    closePopoverV2()

    props.refreshFn()

    //setRefreshNeeded(true)

    return undefined
  }

  function existingQueryParamButtonPopover(paramObj: any) {
    var paramPopoverName = queryObj.id + '-' + paramObj.paramId + '-popover'

    return <><EuiFlexGroup><EuiFlexItem><EuiPopover
      button={<EuiExpression id={paramPopoverName} isInvalid={false} uppercase={false} description={paramObj.paramId} onClick={existingQueryParamButtonClick}></EuiExpression>}
      id={paramPopoverName}
      key={paramPopoverName}
      isOpen={paramPopoverName === props.currentOpenPopover ? true : false}
      closePopover={closePopoverV2}
    >
      <EuiPopoverTitle>{paramObj.paramId}</EuiPopoverTitle>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="parameter name">
            <EuiFieldText readOnly compressed name={paramObj.paramId + '-nameField'} value={paramObj.paramId} />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="sample value">
            <EuiFieldText onChange={paramPopoverFieldOnChange} name={paramObj.paramId + '-sampleValue'} value={paramObj.sampleValue ? paramObj.sampleValue : undefined} />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFormRow hasEmptyLabelSpace>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiButton color='danger' onClick={existingQueryParamDeleteClick}>Delete</EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiFlexGroup>
    </EuiPopover>
    </EuiFlexItem></EuiFlexGroup></>
  }

  function existingQueryParamPopoverArray() {
    var returnObj: any[] = []
    if (queryObj.queryParams) {
      returnObj = queryObj.queryParams.map((item: any) => {
        return existingQueryParamButtonPopover(item)
      })
    }
    return returnObj
  }


  var allQueries: any = props.allQueriesObj
  var allQueriesResults: any = props.allQueriesResultsObj
  var allExpressions: any = props.allExpressionsObj
  var expressionsArray: any[] = []
  allExpressions.map((item: any, index: number) => {
    expressionsArray.push(item.id)
    return null
  })

  var queryObj: any = getQueryObjByNameInMem(props.queryName, allQueries)
  var queryResultObj: any = getQueryResultByNameInMem(props.queryName, allQueriesResults)

  var refObj: any = {}
  var typeRef: any = createRef()
  var optionsRef: any = createRef()
  var queryTextRef: any = createRef()
  var transformFnRef: any = createRef()
  var queryGroupsRef: any = createRef()
  var parametersRefArray: any = []

  refObj.typeRef = typeRef
  refObj.optionsRef = optionsRef
  refObj.queryTextRef = queryTextRef
  refObj.transformFnRef = transformFnRef
  refObj.parametersRefArray = parametersRefArray
  refObj.queryGroupsRef = queryGroupsRef

  props.queryRef = refObj

  var selectedQueryGroups: ITag[] = []

  if (queryObj.queryGroups) {
    queryObj.queryGroups.map((item: any) => {
      var groupName = ''
      if (getQueryGroupByIdInMem(item, props.queryGroups)) {
        var findResult = getQueryGroupByIdInMem(item, props.queryGroups)
        groupName = findResult.name
      }
      var queryItem: ITag = {
        key: item,
        name: groupName
      }
      selectedQueryGroups.push(queryItem)

      return null
    })
  }

  var queryItem: JSX.Element = <></>
  var queryResultTruncated: any[] = []

  var rawJsonTitle = 'raw json'
  if (queryObj.type === 'msgraph' && queryResultObj && queryResultObj.queryResult && queryResultObj.queryResult.raw && queryResultObj.queryResult.raw.value && queryResultObj.queryResult.raw.value.length > 12) {
    queryResultTruncated = Array.from(queryResultObj.queryResult.raw.value)
    queryResultTruncated.length = 12
    rawJsonTitle = 'raw json (truncated from ' + queryResultObj.queryResult.raw.value.length + ' rows)'
  }

  var queryLevelParameters
  if (queryObj.queryClass && queryObj.queryClass === 'pageQuery') {
    queryLevelParameters = <EuiFlexGroup><EuiFlexItem><EuiFormRow label="data source parameters"><>{existingQueryParamPopoverArray()}{newQueryParamPopover()}</></EuiFormRow></EuiFlexItem></EuiFlexGroup>
  }

  switch (props.editType) {
    case "popout":
      queryItem = <><div style={{ marginBottom: '3%', borderStyle: 'solid', borderRadius: '10px', borderWidth: '1px', padding: '2%' }} >
        <EuiFlexGroup>
          <EuiFlexItem><EuiFormRow label="type">{queryTypeDropdownRef(queryObj.type, queryObj.id + '-' + props.queryIndex + '-typeDropdown', refObj.typeRef)}</EuiFormRow></EuiFlexItem>
          <EuiFlexItem>
            {queryOptionsPanelRef(queryObj, refObj.optionsRef)}
          </EuiFlexItem>
        </EuiFlexGroup>
        < EuiFlexGroup >
          <EuiFlexItem grow={1}> {queryPreviewPanel(queryObj)}
            < EuiFlexGroup style={{ marginTop: '2%' }}>
              <EuiButton
                color='success'
                size='s'
                fill
                onClick={previewClickSingleQuery}
                id={queryObj.id + '-' + props.queryIndex + '-previewButton'}
                style={{ width: '20%' }}>
                Preview
              </EuiButton>
              <EuiButton
                color='primary'
                size='s'
                fill
                onClick={saveClick}
                id={queryObj.id + '-' + props.queryIndex + '-saveButton'}
                style={{ width: '20%' }}>
                Save
              </EuiButton>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem grow={1}>
            <EuiFormRow label={rawJsonTitle}><div id={queryObj.id + '-queryResultRawPreview'} style={{ borderRadius: '4px', backgroundColor: 'lightgrey', maxHeight: '200px', overflow: 'scroll' }}> <ReactJson src={
              queryResultTruncated && queryResultTruncated.length > 0 ? queryResultTruncated :
                queryResultObj ?
                  ((queryResultObj.queryResult && queryResultObj.queryResult.code) ?
                    queryResultObj.queryResult :
                    queryResultObj.queryResult && queryResultObj.queryResult.raw ? queryResultObj.queryResult.raw : {}) :
                  {}
            } />
            </div></EuiFormRow>
            {(queryObj.type === 'azuremanagement' || queryObj.type === 'msgraph' || queryObj.type === 'okta' || queryObj.type === 'aws') ? <EuiFormRow label="query transform?">{transformExprDropdown(queryObj, transformFnRef)}</EuiFormRow> : <></>}
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem grow={1}>
            <EuiFormRow label="transformed result"><div id={queryObj.id + '-queryResultTransformedPreview'} style={{ borderRadius: '4px', backgroundColor: 'lightgrey', maxHeight: '200px', overflow: 'scroll' }}><ReactJson src={
              queryResultObj && queryResultObj.queryResult && queryResultObj.queryResult.transformed ? queryResultObj.queryResult.transformed : {}} /></div></EuiFormRow>
          </EuiFlexItem >
        </EuiFlexGroup>
      </div></>
      break;
    case "page":
      queryItem = <div style={{ marginBottom: '3%', borderStyle: 'solid', borderRadius: '10px', borderWidth: '1px', padding: '2%' }}>
        {queryLevelParameters ? queryLevelParameters : <></>}
        <div style={{ marginBottom: '3%', borderStyle: 'solid', borderRadius: '10px', borderWidth: '1px', padding: '2%' }}>
          <EuiFlexGroup>
            <EuiFlexItem><EuiFormRow label="id"><EuiFieldText readOnly compressed name='id' value={queryObj.id} /></EuiFormRow></EuiFlexItem>
            <EuiFlexItem><EuiFormRow label="type">{queryTypeDropdownRef(queryObj.type, queryObj.id + '-' + props.queryIndex + '-typeDropdown', refObj.typeRef)}</EuiFormRow></EuiFlexItem>
            {queryObj && queryObj.type && queryObj.type === 'okta' ? <EuiFlexItem>
              <EuiFormRow label="admin api">
                <EuiSwitch label="admin api" onChange={((evt: any) => {
                  let refreshFn: any = props.refreshFn
                  if (!queryObj.options) {
                    queryObj.options = {}
                  }
                  if (queryObj.options.adminApi !== undefined) {
                    if (queryObj.options.adminApi) {
                      queryObj.options.adminApi = false
                    }
                    else {
                      queryObj.options.adminApi = true
                    }
                  }
                  else {
                    queryObj.options.adminApi = evt.currentTarget.checked
                  }

                  refreshFn()
                })} checked={queryObj && queryObj.options && queryObj.options.adminApi ?
                  queryObj.options.adminApi : undefined}></EuiSwitch>
              </EuiFormRow>
            </EuiFlexItem> : <></>}
            <EuiFlexItem><EuiFormRow label="output">
              <EuiSelect
                name={queryObj.id + '-output'}
                onChange={((evt: any) => {
                  if (!queryObj.options) {
                    queryObj.options = {}
                  }
                  queryObj.options.outputType = evt.currentTarget.value
                  props.refreshFn()
                  return undefined
                })}
                options={[
                  { value: 'data', text: 'Dataset' },
                  { value: 'toast', text: 'Toast Alert' },
                ]}
                value={queryObj && queryObj.options && queryObj.options.outputType ? queryObj.options.outputType : 'data'}
                compressed
              />
            </EuiFormRow></EuiFlexItem>
            <EuiFlexItem>
              {queryOptionsPanelRef(queryObj, refObj.optionsRef)}
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem grow={1}>{queryPreviewPanel(queryObj)}
              <EuiFlexGroup style={{ marginTop: '2%', marginBottom: '2em' }}>
                <EuiButton
                  color='success'
                  size='s'
                  fill
                  onClick={previewClickSingleQuery}
                  id={queryObj.id + '-' + props.queryIndex + '-previewButton'}
                  style={{ width: '20%' }}>
                  Preview
                </EuiButton>
                <EuiButton
                  color='primary'
                  size='s'
                  fill
                  onClick={updateQueryClick}
                  id={queryObj.id + '-' + props.queryIndex + '-updateButton'}
                  style={{ width: '20%' }}>
                  Update
                </EuiButton>
                <EuiButton
                  color='danger'
                  size='s'
                  fill
                  onClick={deleteQueryClick}
                  id={queryObj.id + '-' + props.queryIndex + '-deleteButton'}
                  style={{ width: '20%' }}>
                  Delete
                </EuiButton>
                <EuiFlexItem>
                  {props.loadingSpinnersArray && props.loadingSpinnersArray.includes(queryObj.id) ? <Spinner size={SpinnerSize.large} label="Getting result..." /> : <></>}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFormRow label="query groups"><TagPicker
                  componentRef={refObj.queryGroupsRef}
                  removeButtonAriaLabel="Remove"
                  selectionAriaLabel="Selected colors"
                  onResolveSuggestions={filterSuggestedTags}
                  onChange={updateQueryGroups}
                  //getTextFromItem={getTextFromItem}
                  //pickerSuggestionsProps={pickerSuggestionsProps}
                  selectedItems={selectedQueryGroups}
                  // this option tells the picker's callout to render inline instead of in a new layer
                  pickerCalloutProps={{ doNotLayer: true }}
                //inputProps={{
                //  id: pickerId,
                //}}
                /></EuiFormRow>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              {!queryObj.queryClass || (queryObj.queryClass && queryObj.queryClass === 'dataQuery') ?
                <EuiFormRow label={rawJsonTitle}><div id={queryObj.id + '-queryResultRawPreview'} style={{ minHeight: '80px', borderRadius: '4px', backgroundColor: 'lightgrey', maxHeight: '200px', overflow: 'scroll' }}>
                  <ReactJson src={
                    queryResultTruncated && queryResultTruncated.length > 0 ? queryResultTruncated :
                      queryResultObj ?
                        ((queryResultObj.queryResult && queryResultObj.queryResult.code) ?
                          queryResultObj.queryResult :
                          queryResultObj.queryResult && queryResultObj.queryResult.raw ? queryResultObj.queryResult.raw : {}) :
                        {}
                  } /></div></EuiFormRow>
                : <></>}
              {!(queryObj && queryObj.options && queryObj.options.outputType && queryObj.options.outputType === 'toast') && (queryObj.type === 'azuremanagement' || queryObj.type === 'msgraph' || queryObj.type === 'okta' || queryObj.type === 'aws') ? <EuiFormRow label="query transform?">{transformExprDropdown(queryObj, transformFnRef)}</EuiFormRow> : <></>}
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow label="transformed result"><div id={queryObj.id + '-queryResultTransformedPreview'} style={{ minHeight: '80px', borderRadius: '4px', backgroundColor: 'lightgrey', maxHeight: '200px', overflow: 'scroll' }}><ReactJson src={
                queryResultObj && queryResultObj.queryResult && queryResultObj.queryResult.transformed ? queryResultObj.queryResult.transformed : {}} /></div></EuiFormRow>
              <EuiFormRow label="last updated"><EuiFieldText readOnly compressed value={queryResultObj.timestamp ? new Date(queryResultObj.timestamp).toISOString() : ''} /></EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
      break;
    default:
      break;
  }

  return queryItem

}

export async function refreshSingleQuery(queryObj: any, frontendDataObj: any, tokens: any) {
  var allQueryResults: any = []
  var allExpressions: any = []

  if (frontendDataObj.queryResults && frontendDataObj.queryResults.current) {
    allQueryResults = frontendDataObj.queryResults.current
  }

  if (frontendDataObj.expressions && frontendDataObj.expressions.current) {
    allExpressions = frontendDataObj.expressions.current
  }

  var queryItemResultIndex: number = allQueryResults.findIndex((result: any) => result.id === queryObj.id)

  var currentItemResult = await GetQueryResult(queryObj, tokens, allExpressions)
    .then(result => {
      var currentItemResult: any = {}
      currentItemResult.queryResult = result
      //console.log(tmpDate)
      currentItemResult.timestamp = new Date().getTime()
      currentItemResult.id = queryObj.id

      if (allQueryResults.currentItemResultIndex !== -1) {
        allQueryResults[queryItemResultIndex] = currentItemResult
        /////setAllQueriesResults(queryResults)
        ////setRefreshNeeded(true)
        //setQueriesHtml(queryHtml(pageQueries, queryResults))
      }
      else {
        allQueryResults.push(currentItemResult)
        allQueryResults.refreshFn()
        ////setAllQueriesResults(queryResults)
        ////setRefreshNeeded(true)
        //setQueriesHtml(queryHtml(pageQueries, queryResults))
      }

      //localStorage.setItem('allQueriesResults', JSON.stringify(allQueryResults))

      return currentItemResult
    })
  var test = currentItemResult
  return test
}

export function getQueryByName(queryName: any, allQueriesObj?: any[]) {
  var returnObj: any = {}
  var allQueries: any[] = []

  if (!allQueriesObj) {
    var lclStgQueries = localStorage.getItem('allQueries')
    if (lclStgQueries) {
      allQueries = JSON.parse(lclStgQueries)
    }
  }
  else {
    allQueries = allQueriesObj
  }

  var selectedQueryObj: any = allQueries.find((result: any) => result.id === queryName)

  if (selectedQueryObj) {
    returnObj = selectedQueryObj
  }

  return returnObj
}

export function getQueryObjByNameInMem(queryName: any, allQueries: any[]) {
  var returnObj: any = {}

  var selectedQueryObj: any = allQueries.find((result: any) => result.id === queryName)

  if (selectedQueryObj) {
    returnObj = selectedQueryObj
  }

  return returnObj
}

export function getAllQueries() {
  var returnObj: any[] = []
  var lclStgQueries = localStorage.getItem('allQueries')
  if (lclStgQueries) {
    returnObj = JSON.parse(lclStgQueries)
  }

  return returnObj
}

export function getAllQueryGroups() {
  var returnObj: any[] = []
  var lclStgQueryGroups = localStorage.getItem('queryGroups')
  if (lclStgQueryGroups) {
    returnObj = JSON.parse(lclStgQueryGroups)
  }

  return returnObj
}

export function getAllQueriesResults() {
  var returnObj: any[] = []
  var lclStgQueriesResults = localStorage.getItem('allQueriesResults')
  if (lclStgQueriesResults) {
    returnObj = JSON.parse(lclStgQueriesResults)
  }

  return returnObj
}

export function getQueryResultByName(queryName: any, allResultsObj?: any[]) {
  var returnObj: any = {}
  var allQueriesResults: any[] = []

  if (!allResultsObj) {
    var lclStgQueriesResults = localStorage.getItem('allQueriesResults')

    if (lclStgQueriesResults) {
      allQueriesResults = JSON.parse(lclStgQueriesResults)
    }
  }
  else {
    allQueriesResults = allResultsObj
  }

  var selectedQueryResultObj: any = allQueriesResults.find((result: any) => result.id === queryName)

  if (selectedQueryResultObj) {
    returnObj = selectedQueryResultObj
  }

  return returnObj
}

export function getQueryResultByNameInMem(queryName: any, allQueriesResults: any) {
  var returnObj: any = {}

  var selectedQueryResultObj: any = allQueriesResults.find((result: any) => result.id === queryName)

  if (selectedQueryResultObj) {
    returnObj = selectedQueryResultObj
  }

  return returnObj
}

export function getQueryGroupByIdInMem(queryGroupId: any, allQueryGroups: any) {
  var returnObj: any = {}

  var selectedQueryResultObj: any = allQueryGroups.find((result: any) => result.id === queryGroupId)

  if (selectedQueryResultObj) {
    returnObj = selectedQueryResultObj
  }

  return returnObj
}

export const baseQueries: any = [ ]

export const baseQueryGroups: any = [
  {
    id: 'id--azure',
    key: 'id--azure',
    name: 'Azure'
  },
  {
    id: 'id--misc',
    key: 'id--misc',
    name: 'Misc'
  },
  {
    id: 'id--homedashboard',
    key: 'id--homedashboard',
    name: 'Home Dashboard'
  },
  {
    id: 'id--storage',
    key: 'id--storage',
    name: 'Storage'
  },
  {
    id: 'id--cloudcosts',
    key: 'id--cloudcosts',
    name: 'Costs'
  },
  {
    id: 'id--users',
    key: 'id--users',
    name: 'Users'
  },
  {
    id: 'id--devices',
    key: 'id--devices',
    name: 'Devices'
  },
  {
    id: 'id--okta',
    key: 'id--okta',
    name: 'Okta'
  },
  {
    id: 'id--serverless',
    key: 'id--serverless',
    name: 'Serverless'
  },
  {
    id: 'id--vdi',
    key: 'id--vdi',
    name: 'VDI'
  },
  {
    id: 'id--dns',
    key: 'id--dns',
    name: 'DNS'
  },
  {
    id: 'id--messaging',
    key: 'id--messaging',
    name: 'Messaging'
  },
  {
    id: 'id--security',
    key: 'id--security',
    name: 'Security'
  },
  {
    id: 'id--blank',
    key: 'id--blank',
    name: '*Intentionally Blank*'
  },

]

export const baseQueriesResults: any = [ ]

var methodsArray: any[] = [
  'GET',
  'POST',
  'PATCH',
  'DELETE'
]

function resolveExprArg(queryObj: any, exprType: any, allExpressionsObj: any) {
  var returnObj: any = { ...queryObj }

  let exprTypeName: any = exprType + 'Expr'
  let exprTypeParams: any = exprType + 'ExprParams'

  let queryParamObjValueProvided: boolean = true

  let exprName: any = returnObj.exprs[exprTypeName]
  let exprParams: any = returnObj.exprs[exprTypeParams]
  let expressionObjMutable: any
  let expressionObj: any
  if (exprName) {
    if (allExpressionsObj) {
      expressionObjMutable = allExpressionsObj.find((result: any) => result.id === exprName)
    }
    if (expressionObjMutable) {
      expressionObj = { ...expressionObjMutable }
    }
  }

  let requiredParams: any[] = []
  if (expressionObj && expressionObj.type && expressionObj.type === 'complex') {
    expressionObj.complexExpression.map((subExpr: any) => {
      if (subExpr.parameters) {
        subExpr.parameters.map((subExprParam: any) => {
          requiredParams.push(subExprParam.paramValueId)
          return undefined
        })
      }
      return undefined
    })
  }
  else if (expressionObj && expressionObj.type && expressionObj.type === 'function') {
    if (expressionObj.parameters) {
      expressionObj.parameters.map((exprParm: any) => {
        requiredParams.push(exprParm.paramId)
        return undefined
      })
    }
  }

  requiredParams.map((requiredParam: any) => {
    if (exprParams[requiredParam] && exprParams[requiredParam].valueType && exprParams[requiredParam].valueType === 'queryparam') {
      let queryParamName = exprParams[requiredParam].queryParam
      let queryParamObj
      if (queryObj && queryObj.queryParams) {
        queryParamObj = queryObj.queryParams.find((result: any) => result.paramId === queryParamName)
      }

      if (queryParamObj && queryParamObj.provided) {
        if (expressionObj.complexExpression && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]]) {
          if (!queryParamObj.value && queryParamObj.sampleValue) {
            //expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]].paramValue = queryParamObj.sampleValue
          } else {
            expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]].paramValue = queryParamObj.value
          }
        }
        else if (expressionObj.type === 'function') {
          let exprParamObjIndex: any = expressionObj.parameters.findIndex((par: any) => par.paramId === requiredParam)
          if (exprParamObjIndex !== undefined) {
            if (!queryParamObj.value && queryParamObj.sampleValue) {
              expressionObj.parameters[exprParamObjIndex].paramValue = queryParamObj.sampleValue
            } else {
              expressionObj.parameters[exprParamObjIndex].paramValue = queryParamObj.value
            }
          }
        }
      }
      else if (queryParamObj && queryParamObj.sampleValue) {
        if (expressionObj.complexExpression && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]]) {
          if (!queryParamObj.value && queryParamObj.sampleValue) {
            //expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]].paramValue = queryParamObj.sampleValue
          } else {
            expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]].paramValue = queryParamObj.value
          }
        }
        else if (expressionObj.type === 'function') {
          let exprParamObjIndex: any = expressionObj.parameters.findIndex((par: any) => par.paramId === requiredParam)
          if (exprParamObjIndex !== undefined) {
            if (!queryParamObj.value && queryParamObj.sampleValue) {
              expressionObj.parameters[exprParamObjIndex].paramValue = queryParamObj.sampleValue
            } else {
              expressionObj.parameters[exprParamObjIndex].paramValue = queryParamObj.value
            }
          }
        }
      }
      /*else if (queryParamObj && !queryParamObj.provided) {
        if (expressionObj.complexExpression && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters && expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]]) {
          if (queryParamObj && !queryParamObj.provided) {
            delete queryParamObj.value
            queryParamObjValueProvided = false

            let exprParamObj: any = expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]]
            let asdf1
            let asdf2 = null
            let asdf3 = null
          }


          /* if (!queryParamObj.value && queryParamObj.sampleValue) {
            //expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]].paramValue = queryParamObj.sampleValue
            let asdf1 = null
            let asdf2 = null
            let asdf3 = null
          } else {
            //expressionObj.complexExpression[requiredParam.split('-')[1]].parameters[requiredParam.split('-')[4]].paramValue = queryParamObj.value
            let asdf1 = null
            let asdf2 = null
            let asdf3 = null
          }
        }
      }*/
      else {
        if (exprParams[requiredParam].uriQueryParamIndex && queryObj.parameters[exprParams[requiredParam].uriQueryParamIndex]) {
          let uriParamObj: any = queryObj.parameters[exprParams[requiredParam].uriQueryParamIndex]
          if (uriParamObj && uriParamObj[3] && uriParamObj[3].optional) {
            let queryObjUriParamIndex: any = exprParams[requiredParam].uriQueryParamIndex
            let exprArgItem: any = ''
            queryObj.parameters.splice(queryObjUriParamIndex, 1)
          }
          queryParamObjValueProvided = false
        }
      }
    }

    return undefined
  })

  if (expressionObj && queryParamObjValueProvided) {
    returnObj = evaluateExpression(expressionObj, { allExp: allExpressionsObj })

    if (exprType.includes('param-')) {
      let uriParamObj: any = queryObj.parameters[exprType.split('-')[2]]

      uriParamObj[1] = returnObj
    }

  }

  return returnObj
}

function resolveAllExprArgs(queryObj: any, allExpressionsObj: any) {
  var returnObj: any = {}
  if (queryObj.exprArgs) {
    queryObj.exprArgs.map((item: any) => {
      returnObj[item] = resolveExprArg(queryObj, item, allExpressionsObj)
      return undefined
    })
  }

  var outputObj: any = { ...queryObj, ...returnObj }

  return outputObj
}

export function resolveAllExprArgsParams(queryObjOrig: any, paramsObj: any, allExpressionsObj: any) {
  var returnObj: any = {}

  let queryObj: any = { ...queryObjOrig, queryParams: queryObjOrig['queryParams'] ? [...queryObjOrig['queryParams']] : undefined, parameters: [...queryObjOrig['parameters']], exprs: queryObjOrig['exprs'] ? { ...queryObjOrig['exprs'] } : {}, exprArgs: queryObjOrig['exprArgs'] ? [...queryObjOrig['exprArgs']] : [] }

  if (queryObj.queryParams) {
    queryObj.consumableQueryParams = []
    queryObj.queryParams.map((item: any) => {
      if (paramsObj[item.paramId]) {
        item.value = paramsObj[item.paramId]
        item.provided = true
        queryObj.consumableQueryParams.push(item)
      }
      else {
        item.value = item.sampleValue
        item.provided = false
      }
    })
  }

  queryObj.exprArgs.map((exprArgItem: any) => {
    /*if (exprArgItem.includes('param-') && exprArgItem.split('-')[2]) {
      if (queryObj.parameters && queryObj.parameters[exprArgItem.split('-')[2]]) {
        let queryObjUriParamIndex: any = exprArgItem.split('-')[2]
        let uriParamObj: any = queryObj.parameters[queryObjUriParamIndex]

        let queryParamName: any = uriParamObj[1]
        if (uriParamObj[2] && uriParamObj[2] === 'v2') {
          returnObj[exprArgItem] = resolveExprArg(queryObj, exprArgItem, allExpressionsObj)
          if (uriParamObj[3] && uriParamObj[3].optional) {
            let exprName: any = uriParamObj[1] && uriParamObj[1] !== '' ? uriParamObj[1] : undefined
            if (exprName) {

            }
            else {
              queryObj.parameters.splice(queryObjUriParamIndex, 1)

              let exprArgsArrayIndex: any = queryObj.exprArgs.find((result: any) => result === exprArgItem)

              if (exprArgsArrayIndex !== -1) {
                queryObj.exprArgs.splice(exprArgsArrayIndex, 1)
              }

              let exprObjExprName: any = exprArgItem + 'Expr'
              let exprObjExprParamName: any = exprArgItem + 'Expr'

              if (queryObj.exprs && queryObj.exprs[exprObjExprName]) {
                delete queryObj.exprs[exprObjExprName]
              }
              if (queryObj.exprs && queryObj.exprs[exprObjExprParamName]) {
                delete queryObj.exprs[exprObjExprParamName]
              }
            }
          }
        }
      }
    }
    else {*/
    returnObj[exprArgItem] = resolveExprArg(queryObj, exprArgItem, allExpressionsObj)
    //}
    return undefined
  })

  var outputObj: any = { ...queryObj, ...returnObj }

  return outputObj
}
