import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from './components/util/theme';
//import { MsalProvider } from "./components/util/msal-context";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/util/auth-config";
import { AuthenticationProvider } from "./components/util/authentication";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { PublicClientApplication } from "@azure/msal-browser";


initializeIcons();

// const container = document.getElementById('root')
// const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig());


ReactDOM.render(
  <MsalProvider instance={msalInstance}>
      <AuthenticationProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </AuthenticationProvider>
  </MsalProvider>,
  document.getElementById('root')
);