import React, { useState, useEffect, useContext } from "react";
import { useSessionStorage } from 'react-use';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';


const defaultValues = Object.freeze({
  principal: { displayName: '' },
  logout: () => { },
  login: (principal) => { }
});

const graphScopeEndpoints = {
  tokenGraph: ['https://graph.microsoft.com/.default'],
  tokenAz: ['https://management.azure.com/.default'],
  tokenLa: ['https://api.loganalytics.io/.default'],
  tokenAde: ['https://help.kusto.windows.net/.default']
}

const AUTH_STOREGE_KEY = 'authentication';

export const AuthenticationContext = React.createContext();
export const useAuthentication = () => useContext(AuthenticationContext);

export const AuthenticationProvider = ({
  children
}) => {
  const [authentication, setAuthentication] = useSessionStorage(
    AUTH_STOREGE_KEY,
    { authenticated: false, principal: {}, tokens: {} }
  );
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const login = async () => {
    if (!isAuthenticated) {
      return instance.loginPopup().then((loginResult) => {
        return getAllAccessTokens(loginResult.account).then((tokensObj) => {
          let tokens = formatAccessTokenObject(tokensObj)
          setAuthentication({ authenticated: true, principal: instance.getAllAccounts()[0], tokens: tokens });
          return Promise.resolve({ authenticated: true, principal: loginResult.account, tokens: tokens })
        }).catch((e) => {
          console.log(e)
          return Promise.reject(e)
        })
      }).catch((e) => {
        console.log(e)
        return Promise.reject(e)
      })
    }
    else {
      return getAllAccessTokens(instance.getAllAccounts()[0]).then((tokensObj) => {
        let tokens = formatAccessTokenObject(tokensObj)
        setAuthentication({ authenticated: true, principal: instance.getAllAccounts()[0], tokens: tokens });
        return Promise.resolve({ authenticated: true, principal: instance.getAllAccounts()[0], tokens: tokens })
      }).catch((e) => {
        console.log(e)
        return Promise.reject(e)
      })
    }
  }

  const accessToken = async (scope, account) => {
    let request = {
      scopes: scope,
      account: account
    }
    return instance.acquireTokenSilent(request).then((tokenResponse) => {
      return Promise.resolve(tokenResponse)
    }).catch((e) => {
      return Promise.reject(e)
    })
  }

  const formatAccessTokenObject = (tokenArray) => {
    let accessTokenObj = {}
    tokenArray.map((token) => {
      let tokenInfo = {
        accessToken: token.token.accessToken,
        expDate: token.token.expiresOn,
        scopes: token.token.scopes
      }
      accessTokenObj[token.scope] = tokenInfo
      return undefined
    })
    return accessTokenObj
  }

  const getAllAccessTokens = async (account) => {
    return Promise.all(Object.keys(graphScopeEndpoints).map((scope) => {
      return accessToken(graphScopeEndpoints[scope], account).then((token) => {
        let returnObj = {}
        returnObj.scope = scope
        returnObj.token = token
        return returnObj
      })
    })
    )
  }

  const logout = () => setAuthentication(defaultValues);

  const values = { ...authentication, login, logout };

  return (
    <AuthenticationContext.Provider value={values}>
      {children}
    </AuthenticationContext.Provider>
  );
}