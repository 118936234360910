import React from 'react';
import isArray from 'lodash/isArray';
import { useHistory } from 'react-router-dom';
import { NavToggler } from './Nav';
import { Nav } from "@fluentui/react";
import routeConfig from '../../../routeConfig';
import routeConfigAad from '../../../routeConfigAad';
import useRoutePath from '../../util/route/useRoutePath'
import { useAuthentication } from "../../util/authentication";
import { useTopMenu } from "../../util/topmenu-context";


function isVisible(route) {
  return !route.isHidden;
}

export function Sidebar() {
  const history = useHistory();
  const { authenticated } = useAuthentication();
  const { currentMainPage, setCurrentMainPage, currentMainPageParams, setCurrentMainPageParams } = useTopMenu()

  var routeLayout = {}

  if (authenticated) {
    routeLayout = routeConfigAad
  }
  else {
    routeLayout = routeConfig
  }

  const { current, paths } = useRoutePath(routeLayout);

  const homeLink = mapRouteToNavLink(routeLayout, false);
  let topPageLinks
  let groupLinks
  let navLinkGroups 

  if (routeLayout && routeLayout.children) {
    topPageLinks = routeLayout.children
      .filter(route => isVisible(route) && !isArray(route.children))
      .map(route => mapRouteToNavLink(route, false));
  }

  if (routeLayout && routeLayout.children) {
    groupLinks = routeLayout.children
      .filter(hasChildren)
      .map(route => ({
        name: route.name,
        groupType: 'MenuGroup',
        links: route.children
          .filter(isVisible)
          .map(child => mapRouteToNavLink(child, true))
      }));
  }

  if (topPageLinks && groupLinks) {
    navLinkGroups = [
      {
        links: [
          {
            key: 'Collapse',
            name: 'Collapsed',
            alternateText: 'Expanded',
            //icon: 'GlobalNavButton',
            title: 'Collapse'
          }
        ],
        groupType: 'ToggleGroup'
      },
      {
        links: [homeLink, ...topPageLinks],
        groupType: 'MenuGroup'
      },
      ...groupLinks
    ];
  }

  return (
    <NavToggler
      groups={navLinkGroups? navLinkGroups : []}
      selectedKey={current?.uniqueKey}
    />
  );


  function mapRouteToNavLink(route, deeply = true) {
    let treeDepth = route.uniqueKey.split('.').length
    let returnObj = {
      name: route.name,
      key: route.uniqueKey,
      alternateText: route.name,
      title: route.name,
      url: route.path,
      onClick: e => {
        e.preventDefault();
        setCurrentMainPage(route.key)
        history.push(route.path);
      },
      isExpanded:
        deeply &&
        hasChildren(route) &&
        paths.some(that => that.uniqueKey === route.uniqueKey),
      links:
        deeply &&
        hasChildren(route) &&
        route.children
          .filter(isVisible)
          .map(child => mapRouteToNavLink(child, deeply)),
      svg: route.svg
        ? route.svg : undefined,
      icon: route.icon
        ? route.icon
        : hasChildren(route) ? 'DocumentSet' : 'TextDocument'
    }
    if (route.children && treeDepth === 3) {
      returnObj.url = route.children[0].path
      returnObj.onClick = e => {
        e.preventDefault();
        setCurrentMainPage(route.children[0].key)
        history.push(route.children[0].path)
      }
    }

    return returnObj
  }

  function hasChildren(route) {
    return route?.children?.filter(isVisible).length;
  }
}


const siteMap = {
  key: 'homedemo',
  name: 'Home (Demo)',
  icon: 'ViewDashboard',
  path: '/',
  component: React.lazy(() => import('../../pages/TwoPanelPageLayout')),
  children: [
    {
      key: 'login',
      name: 'Login',
      isPublic: true,
      isHidden: true,
      component: React.lazy(() => import('../../pages/Login')),
    },
    {
      key: 'homedashboard',
      name: 'Home v2',
      icon: 'ViewDashboard',
      title: 'Home v2',
      component: React.lazy(() => import('../../pages/TwoPanelPageLayout')),
      isHidden: true
    },

    {
      key: 'search',
      name: 'Search',
      icon: 'Search',
      component: React.lazy(() => import('../../pages/AdvancedSearch')),
      isHidden: false
    },
    {
      key: 'security',
      name: 'Security',
      icon: '',
      children: [
        {
          key: 'securityOverview',
          name: 'Security',
          icon: 'Encryption',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: false
        },
        {
          key: 'securityIncidentDetail',
          name: 'Incident Detail',
          icon: 'Encryption',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        }
      ]
    },
    {
      key: 'usersdevices',
      name: 'Users and Devices',
      icon: 'managementApp',
      children: [
        {
          key: 'usersLanding',
          name: 'Users',
          icon: 'Contact',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: false
        },
        {
          key: 'userDetail',
          name: 'User Detail',
          icon: 'Contact',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        },
        {
          key: 'endpointsLanding',
          name: 'Endpoints',
          icon: 'Devices3',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: false
        },
        {
          key: 'endpointDetail',
          name: 'Endpoint Detail',
          icon: 'Devices3',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        }
      ]
    },
    {
      key: 'warRoom',
      name: 'War Room',
      icon: 'WhiteBoardApp16',
      component: React.lazy(() => import('../../pages/DynamicPage')),
      isHidden: false
    },
    {
      key: 'cloud',
      name: 'Cloud',
      icon: 'managementApp',
      children: [
        {
          key: 'cloud-azure',
          name: 'Azure old',
          icon: 'AzureLogo',
          title: 'Azure old',
          isHidden: true,
          component: React.lazy(() => import('../../pages/DynamicPage'))
        },
        {
          key: 'aws',
          name: 'Amazon Web Services',
          icon: 'AmazonWebServicesLogo',
          title: 'Amazon Web Services',
          isHidden: false,
          component: React.lazy(() => import('../../pages/DynamicPage'))
        },
        {
          key: 'azureLanding',
          name: 'Azure',
          icon: 'AzureLogo',
          title: 'Azure',
          isHidden: false,
          component: React.lazy(() => import('../../pages/DynamicPage'))
        },
        {
          key: 'gcp',
          name: 'Google Compute',
          icon: 'GoogleDriveLogo',
          title: 'Google Compute',
          isHidden: false,
          component: React.lazy(() => import('../../pages/DynamicPage'))
        }
      ]
    }, {
      key: 'collaboration',
      name: 'Collaboration and Productivity',
      icon: 'Mail',
      children: [{
        key: 'office365',
        name: 'Office 365',
        icon: 'OfficeLogo',
        component: React.lazy(() => import('../../pages/DynamicPage'))
      },
      {
        key: 'messagingLanding',
        name: 'Message Trace',
        icon: 'Mail',
        component: React.lazy(() => import('../../pages/DynamicPage'))
      }
      ]
    },
    {
      key: 'saas',
      name: 'SaaS',
      icon: 'managementApp',
      children: [
        {
          key: 'avdOverview',
          name: 'Azure Virtual Desktop',
          icon: 'WindowsLogo',
          component: React.lazy(() => import('../../pages/DynamicPage'))
        },
        {
          key: 'avdSessionDetail',
          name: 'AVD Session Detail',
          icon: 'WindowsLogo',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        },
        {
          key: 'avdHostPoolDetail',
          name: 'AVD Host Pool Detail',
          icon: 'WindowsLogo',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        },
        {
          key: 'avdSessionHostDetail',
          name: 'AVD Session Host Detail',
          icon: 'WindowsLogo',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        },
        {
          key: 'oktaLanding',
          name: 'Okta',
          svg: false,
          icon: 'okta-svg',
          component: React.lazy(() => import('../../pages/DynamicPage'))
        },
        {
          key: 'oktaAppDetail',
          name: 'Okta - App Detail',
          svg: false,
          icon: 'okta-svg',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        },
        {
          key: 'oktaSessionDetail',
          name: 'Okta - Session Detail',
          svg: false,
          icon: 'okta-svg',
          component: React.lazy(() => import('../../pages/DynamicPage')),
          isHidden: true
        },
        {
          key: 'umbrellaDnsLanding',
          name: 'Umbrella DNS',
          svg: false,
          icon: 'umbrelladns-svg',
          component: React.lazy(() => import('../../pages/DynamicPage'))
        },

      ]
    },
    {
      key: 'settings',
      name: 'Settings',
      icon: 'managementApp',
      children: [
        {
          key: 'queries',
          name: 'Queries',
          icon: 'ReviewRequestSolid',
          component: React.lazy(() => import('../../pages/Queries')),
          isHidden: false
        },
        {
          key: 'visualizations',
          name: 'Visualizations',
          icon: 'Chart',
          component: React.lazy(() => import('../../pages/Visualizations')),
          isHidden: false
        },
        {
          key: 'expressions',
          name: 'Expressions',
          icon: 'TextField',
          component: React.lazy(() => import('../../pages/Expressions')),
          isHidden: true
        },
        {
          key: 'expressions2',
          name: 'Expressions',
          icon: 'TextField',
          component: React.lazy(() => import('../../pages/ExpressionsV2')),
          isHidden: false
        },
        {
          key: 'pages',
          name: 'Pages',
          icon: 'Page',
          component: React.lazy(() => import('../../pages/Pages')),
          isHidden: false
        },
        {
          key: 'databasedata',
          name: 'Data',
          icon: 'Org',
          component: React.lazy(() => import('../../pages/Data')),
          isHidden: false
        },
        {
          key: 'scratchpad',
          name: 'Scratchpad',
          icon: 'Org',
          component: React.lazy(() => import('../../pages/Scratchpad')),
          isHidden: false
        },
        {
          key: 'datasources',
          name: 'Data Sources',
          icon: 'Org',
          component: React.lazy(() => import('../../pages/Data')),
          isHidden: false
        },
      ]
    }
  ]
}

function oldSitemapToNew(siteMap) {
  let newSitemap = []

  if (siteMap && siteMap.children) {
    siteMap.children.map((child) => {
      let newSiteObj = {
        name: child.name,
        url: '/' + child.key
      }
      if (child.children) {
        let newSiteObjLinks = child.children.map((item) => {
          let returnObj = {
            key: item.key,
            name: item.name,
            url: '/' + child.key + '/' + item.key,
          }
          return returnObj
        })
        newSiteObj.links = newSiteObjLinks
      }

      newSitemap.push(newSiteObj)
    })
  }

  return newSitemap
}

const tempSidebar = [
  {
    links: [
      {
        name: 'Home',
        url: 'http://example.com',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Activity',
            url: 'http://msn.com',
            key: 'key1',
            target: '_blank',
          },
          {
            name: 'MSN',
            url: 'http://msn.com',
            disabled: true,
            key: 'key2',
            target: '_blank',
          },
        ],
        isExpanded: true,
      },
      {
        name: 'Documents',
        url: 'http://example.com',
        key: 'key3',
        isExpanded: true,
        target: '_blank',
      },
      {
        name: 'Pages',
        url: 'http://msn.com',
        key: 'key4',
        target: '_blank',
      },
      {
        name: 'Notebook',
        url: 'http://msn.com',
        key: 'key5',
        disabled: true,
      },
      {
        name: 'Communication and Media',
        url: 'http://msn.com',
        key: 'key6',
        target: '_blank',
      },
      {
        name: 'News',
        url: 'http://cnn.com',
        icon: 'News',
        key: 'key7',
        target: '_blank',
      },
    ],
  },
]

export const NewSidebar = () => {
  return <Nav ariaLabel="Nav example with nested links" groups={oldSitemapToNew(siteMap)} />;
};
