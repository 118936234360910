import React, { useState, useEffect, useContext } from "react";
import useWebSocket from 'react-use-websocket';
import { AzureSASCredential, TableClient } from "@azure/data-tables";

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const account = "arbdemostorage";
const sas = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2034-02-16T02:33:52Z&st=2024-02-15T18:33:52Z&spr=https&sig=RnFQ4uA3Mqmx8xMg8eZUBHOBLRJA4B2krDAsGiU1YaI%3D";

const byteSize = (str) => new Blob([str]).size

const serviceClientWithSASTable = (tableName) => new TableClient(
  `https://${account}.table.core.windows.net`,
  tableName,
  new AzureSASCredential(sas)
);

function arrayToObj(array, keyName) {
  let returnObj = {}

  array.map((item) => {
    let keyNameValue = item[keyName]
    returnObj[keyNameValue] = item
    return undefined
  })
  return returnObj
}

function objToArray(objToConvert) {
  let returnObj = []

  Object.keys(objToConvert).map((item) => {
    let arrayObj = objToConvert[item]
    returnObj.push(arrayObj)
    return undefined
  })
  return returnObj
}



const FrontendDataContext = React.createContext();
export const useFeData = () => useContext(FrontendDataContext);
export const FrontendDataProvider = ({
  children,
  config
}) => {
  const [queries, setQueries] = useState();
  const [pageQueries, setPageQueries] = useState();
  const [queryResults, setQueryResults] = useState();
  //const [visualizations, setVisualizations] = useState();
  const [visualizations, setVisualizations] = useState();
  const [expressions, setExpressions] = useState();
  const [queryGroups, setQueryGroups] = useState();
  const [images, setImages] = useState();
  const [pages, setPages] = useState();
  const [interfaces, setInterfaces] = useState();
  const [integrations, setIntegrations] = useState();


  useEffect(() => {


    /*  V1 - with local storage
    if (!queries) {
      var localStgQueriesStr = ''
      if (localStorage.getItem('allQueries') !== null) {
        localStgQueriesStr = localStorage.getItem('allQueries')
        setQueries(JSON.parse(localStgQueriesStr))
      }
      else {
        getQueriesAzureTables('dev1').then(response => {
          setQueries(response)
          localStorage.setItem('allQueries', JSON.stringify(response))
        })
      }
    }

        if (!pageQueries) {
      var localStgPageQueriesStr = ''
      if (localStorage.getItem('pageQueries') !== null) {
        localStgPageQueriesStr = localStorage.getItem('pageQueries')
        setPageQueries(JSON.parse(localStgPageQueriesStr))
      }
      else {
        getPageQueriesAzureTables('dev1').then(response => {
          setPageQueries(response)
          localStorage.setItem('pageQueries', JSON.stringify(response))
        })
      }
    }

    if (!visualizations) {
      var localStgVisualizationsStr = ''
      if (localStorage.getItem('allVisualizations') !== null) {
        localStgVisualizationsStr = localStorage.getItem('allVisualizations')
        setVisualizations(arrayToObj(JSON.parse(localStgVisualizationsStr), 'id'))
        //setAllVisualizationsObj(arrayToObj(JSON.parse(localStgVisualizationsStr),'id'))
      }
      else {
        getVisualizationAzureTables('dev1').then(response => {
          setVisualizations(arrayToObj(response, 'id'))
          localStorage.setItem('allVisualizations', JSON.stringify(response))
          //setAllVisualizationsObj(arrayToObj(response,'id'))
        })
      }
    }

        if (!expressions) {
      var localStgExpressionsStr = ''
      if (localStorage.getItem('allExpressions') !== null) {
        localStgExpressionsStr = localStorage.getItem('allExpressions')
        setExpressions(JSON.parse(localStgExpressionsStr))
      }
      else {
        getExpressionsAzureTables('dev1').then(response => {
          setExpressions(response)
          localStorage.setItem('allExpressions', JSON.stringify(response))
        })
      }
    }

    if (!queryGroups) {
      var localStgQueryGroupsStr = ''
      if (localStorage.getItem('queryGroups') !== null) {
        localStgQueryGroupsStr = localStorage.getItem('queryGroups')
        setQueryGroups(JSON.parse(localStgQueryGroupsStr))
      }
      else {
        getQueryGroupsAzureTables('dev1').then(response => {
          setQueryGroups(response)
          localStorage.setItem('queryGroups', JSON.stringify(response))
        })
      }
    }

        if (!pages) {
      var localStgPagesStr = ''
      if (localStorage.getItem('pages') !== null) {
        localStgPagesStr = localStorage.getItem('pages')
        setPages(JSON.parse(localStgPagesStr))
      }
      else {
        getPagesAzureTables('dev1').then(response => {
          setPages(response)
          localStorage.setItem('pages', JSON.stringify(response))
        })
      }
    }
    */

    //V2 - no local storage
    if (!queries) {
      getQueriesAzureTables('dev1').then(response => {
        setQueries(response)
      })
    }

    if (!pageQueries) {
      getPageQueriesAzureTables('dev1').then(response => {
        setPageQueries(response)
      })
    }

    if (!visualizations) {
      getVisualizationAzureTables('dev1').then(response => {
        setVisualizations(arrayToObj(response, 'id'))
        //setAllVisualizationsObj(arrayToObj(response,'id'))
      })
    }

    if (!expressions) {
      getExpressionsAzureTables('dev1').then(response => {
        setExpressions(response)
      })
    }

    if (!integrations) {
      getIntegrationsAzureTables('dev1').then(response => {
        setIntegrations(response)
      })
    }

    if (!images) {
      getImagesAzureTables('dev1').then(response => {
        setImages(response)
      })
    }

    if (!interfaces) {
      getInterfacesAzureTables('dev1').then(response => {
        setInterfaces(response)
      })
    }

    if (!queryGroups) {
      getQueryGroupsAzureTables('dev1').then(response => {
        setQueryGroups(response)
      })
    }

    if (!pages) {
      getPagesAzureTables('dev1').then(response => {
        setPages(response)
      })
    }


    if (!queryResults) {
      var localStgQueryResultsStr = ''
      if (localStorage.getItem('allQueriesResults') !== null) {
        localStgQueryResultsStr = localStorage.getItem('allQueriesResults')
        setQueryResults(JSON.parse(localStgQueryResultsStr))
      }
      else {
        getQueryResultsAzureTables('dev1').then(response => {
          setQueryResults(response)
          localStorage.setItem('allQueriesResults', JSON.stringify(response))
        })
      }
    }





    // eslint-disable-next-line
  }, []);

  async function upsertEntity(entity, tableClient) {
    var outputObj = {}
    try {
      await tableClient.upsertEntity(entity, "Replace")
        .then((response) => {
          outputObj = response
        })

      return { success: outputObj }
    }
    catch (error) {
      console.log(error)
      return { failure: error }
    }
  }

  async function deleteEntity(tableName, partitionKey, rowKey) {
    var tableClient = serviceClientWithSASTable(tableName)
    var outputObj = {}
    try {
      await tableClient.deleteEntity(partitionKey, rowKey)
        .then((response) => {
          outputObj = response
        })

      return { success: outputObj }
    }
    catch (error) {
      return { failure: error }
    }
  }

  function pagePanels(pageId) {
    var pageIdLclStg = 'panelpage_' + pageId
    var pageIdPartitionKey = 'dev1_' + pageId

    var localStgPanelsStr = ''
    if (localStorage.getItem(pageIdLclStg) !== null) {
      localStgPanelsStr = localStorage.getItem(pageIdLclStg)
      return JSON.parse(localStgPanelsStr)
    }
    else {
      return getPagePanelsAzureTables(pageId, pageIdPartitionKey).then(response => {
        localStorage.setItem(pageIdLclStg, JSON.stringify(response))
        return response
      })
    }
  }

  async function pagePanelsV2(pageId) {
    let pageIdLclStg = 'panelpage_' + pageId
    let pageIdPartitionKey = 'dev1_' + pageId

    let panels = getPagePanelsAzureTables(pageId, pageIdPartitionKey).then(response => {
      localStorage.setItem(pageIdLclStg, JSON.stringify(response))
      return response
    })

    if (panels) {
      return panels
    }
  }

  async function getQueriesAzureTables(partitionId) {
    return getAzrTableData('queries', partitionId)
      .then((response) => {
        var allQueriesObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.query) {
            returnObj.query = item.query
          }
          if (item.queryGroups) {
            returnObj.queryGroups = JSON.parse(item.queryGroups)
          }
          if (item.queryRest) {
            returnObj.queryRest = JSON.parse(item.queryRest)
          }
          if (item.parameters) {
            returnObj.parameters = JSON.parse(item.parameters)
          }
          if (item.options) {
            returnObj.options = JSON.parse(item.options)
          }
          if (item.exprArgs) {
            returnObj.exprArgs = JSON.parse(item.exprArgs)
          }
          if (item.exprs) {
            returnObj.exprs = JSON.parse(item.exprs)
          }
          if (item.queryExprParams) {
            returnObj.queryExprParams = JSON.parse(item.queryExprParams)
          }
          return returnObj
        })
        return allQueriesObj
      })
  }

  async function getPageQueriesAzureTables(partitionId) {
    return getAzrTableData('pageQueries', partitionId)
      .then((response) => {
        var allQueriesObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.query) {
            returnObj.query = item.query
          }
          if (item.queryGroups) {
            returnObj.queryGroups = JSON.parse(item.queryGroups)
          }
          if (item.queryRest) {
            returnObj.queryRest = JSON.parse(item.queryRest)
          }
          if (item.parameters) {
            returnObj.parameters = JSON.parse(item.parameters)
          }
          if (item.queryParams) {
            returnObj.queryParams = JSON.parse(item.queryParams)
          }
          if (item.options) {
            returnObj.options = JSON.parse(item.options)
          }
          if (item.exprArgs) {
            returnObj.exprArgs = JSON.parse(item.exprArgs)
          }
          if (item.exprs) {
            returnObj.exprs = JSON.parse(item.exprs)
          }
          if (item.queryExprParams) {
            returnObj.queryExprParams = JSON.parse(item.queryExprParams)
          }
          return returnObj
        })
        return allQueriesObj
      })
  }

  async function getPagePanelsAzureTables(pageId, partitionId) {
    return getAzrTableData('panels', partitionId)
      .then((response) => {
        var allPagePanelsObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.visualization) {
            returnObj.visualization = JSON.parse(item.visualization)
          }
          if (item.options) {
            returnObj.options = JSON.parse(item.options)
          }
          return returnObj
        })
        return allPagePanelsObj
      })
  }

  async function getQueryGroupsAzureTables(partitionId) {
    return getAzrTableData('queryGroups', partitionId)
      .then((response) => {
        var allQueryGroupsObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, rawResult, transformedResult, timestampEpoch, ...returnObj } = unfilteredReturnObj
          return returnObj
        })
        return allQueryGroupsObj
      })
  }

  async function getVisualizationAzureTables(partitionId) {
    return getAzrTableData('visualizations', partitionId)
      .then((response) => {
        var allVizObject = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.vizGroups) {
            returnObj.vizGroups = JSON.parse(item.vizGroups)
          }
          if (item.options) {
            returnObj.options = JSON.parse(item.options)
          }
          if (item.pageQuery) {
            returnObj.pageQuery = JSON.parse(item.pageQuery)
          }
          return returnObj
        })
        return allVizObject
      })
  }

  async function getQueryResultsAzureTables(partitionId) {
    return getAzrTableData('queryResults', partitionId)
      .then((response) => {
        var allQueryResultObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, rawResult, transformedResult, timestampEpoch, ...returnObj } = unfilteredReturnObj
          if (item.rawResult) {
            if (!returnObj.queryResult) {
              returnObj.queryResult = {}
            }
            returnObj.queryResult.raw = JSON.parse(item.rawResult)
          }
          if (item.transformedResult) {
            if (!returnObj.queryResult) {
              returnObj.queryResult = {}
            }
            returnObj.queryResult.transformed = JSON.parse(item.transformedResult)
          }
          if (item.timestampEpoch) {
            returnObj.timestamp = item.timestampEpoch
          }
          return returnObj
        })
        return allQueryResultObj
      })
  }

  async function getExpressionsAzureTables(partitionId) {
    return getAzrTableData('expressions', partitionId)
      .then((response) => {
        var allExpressionsObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.expressionPreviewResult) {
            returnObj.expressionPreviewResult = JSON.parse(item.expressionPreviewResult)
          }
          if (item.complexExpression) {
            returnObj.complexExpression = JSON.parse(item.complexExpression)
          }
          if (item.parameters) {
            returnObj.parameters = JSON.parse(item.parameters)
          }
          return returnObj
        })
        return allExpressionsObj
      })
  }

  async function getIntegrationsAzureTables(partitionId) {
    return getAzrTableData('integrations', partitionId)
      .then((response) => {
        var allIntegrationsObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.options) {
            returnObj.options = JSON.parse(item.options)
          }
          return returnObj
        })
        return allIntegrationsObj
      })
  }

  async function getPagesAzureTables(partitionId) {
    return getAzrTableData('pages', partitionId)
      .then((response) => {
        var allPagesObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.expressionPreviewResult) {
            returnObj.expressionPreviewResult = JSON.parse(item.expressionPreviewResult)
          }
          if (item.complexExpression) {
            returnObj.complexExpression = JSON.parse(item.complexExpression)
          }
          if (item.flyout) {
            returnObj.flyout = JSON.parse(item.flyout)
          }
          if (item.parameters) {
            returnObj.parameters = JSON.parse(item.parameters)
          }
          if (item.dataSources) {
            returnObj.dataSources = JSON.parse(item.dataSources)
          }
          if (item.dataSourceTransforms) {
            returnObj.dataSourceTransforms = JSON.parse(item.dataSourceTransforms)
          }
          if (item.options) {
            returnObj.options = JSON.parse(item.options)
          }
          return returnObj
        })
        return allPagesObj
      })
  }

  async function getImagesAzureTables(partitionId) {
    return getAzrTableData('images', partitionId)
      .then((response) => {
        var allImagesObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj
          if (item.svgCode) {
            returnObj.svgCode = JSON.parse(item.svgCode)
          }
          return returnObj
        })
        return allImagesObj
      })
  }

  async function getInterfacesAzureTables(partitionId) {
    return getAzrTableData('interfaces', partitionId)
      .then((response) => {
        var allImagesObj = response.success.map((item) => {
          var unfilteredReturnObj = { ...item }
          const { partitionKey, rowKey, timestamp, etag, ...returnObj } = unfilteredReturnObj

          return returnObj
        })
        return allImagesObj
      })
  }

  async function listEntitiesInTable(tableName, partitionId, selectArray) {
    var tableClient = serviceClientWithSASTable(tableName)
    var outputObj = []
    if (partitionId) {
      var queryOptions = {
        queryOptions: {
          filter: "PartitionKey eq '" + partitionId + "'"
        }
      }
      if (selectArray) {
        queryOptions.queryOptions.select = selectArray
      }
    }
    try {
      let entitiesIter
      if (queryOptions) {
        entitiesIter = tableClient.listEntities(queryOptions);
      }
      else {
        entitiesIter = tableClient.listEntities();
      }

      for await (const table of entitiesIter) {
        outputObj.push(table)
      }
      return { success: outputObj }
    }
    catch (error) {
      outputObj.push(error)
      return { failure: outputObj }
    }
  }

  async function getAzrTableData(tableName, partitionId, selectArray) {
    var outputObj = {}

    outputObj = await listEntitiesInTable(tableName, partitionId, selectArray).then(
      (response) => {
        return response
      }
    )
    return outputObj
  }

  async function lclStgCopyQueryResults() {
    var outputObj = []
    var tableClient = serviceClientWithSASTable('queryResults')
    var localStorageQueryResults = JSON.parse(localStorage.getItem('allQueriesResults'))
    var allQueryResultsPostPromise = localStorageQueryResults.map((item) => {
      var azTableEntity = {}
      azTableEntity.id = item.id
      azTableEntity.timestampEpoch = item.timestamp

      if (item.queryResult && item.queryResult.raw && byteSize(JSON.stringify(item.queryResult.raw)) < 32000 && JSON.stringify(item.queryResult.raw).length < 32000) {
        azTableEntity.rawResult = JSON.stringify(item.queryResult.raw)
      }

      if (item.queryResult && item.queryResult.transformed && byteSize(JSON.stringify(item.queryResult.transformed)) < 32000 && JSON.stringify(item.queryResult.transformed).length < 32000) {
        azTableEntity.transformedResult = JSON.stringify(item.queryResult.transformed)
      }

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = item.id
      return upsertEntity(azTableEntity, tableClient)
    })
    return Promise.all(allQueryResultsPostPromise).then((allQueryResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allQueryResults
      return outputObj
    })
  }

  async function lclStgCopyVisualizations() {
    let outputObj = []
    let tableClient = serviceClientWithSASTable('visualizations')
    //var localStorageVisualizations = JSON.parse(localStorage.getItem('allVisualizations'))
    let allVisualizationsPostPromise = Object.keys(visualizations).map((itemName) => {
      let item = visualizations[itemName]
      let azTableEntity = { ...item }
      azTableEntity.vizGroups = JSON.stringify(item.vizGroups)
      if (item.options) {
        delete item.options.allViz
        delete item.options.currentPageObj
        delete item.options.dataFlyoutState
        delete item.options.pageParamsObj
      }
      azTableEntity.options = JSON.stringify(item.options)

      azTableEntity.pageQuery = JSON.stringify(item.pageQuery)

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = item.id
      return upsertEntity(azTableEntity, tableClient)
    })
    return Promise.all(allVisualizationsPostPromise).then((allViz) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allViz
      return outputObj
    })
  }

  async function lclStgCopyQueries() {
    var outputObj = []
    var tableClient = serviceClientWithSASTable('queries')
    var localStorageQueries = JSON.parse(localStorage.getItem('allQueries'))
    var allQueriesPostPromise = localStorageQueries.map((item) => {
      var azTableEntity = { ...item }
      azTableEntity.queryRest = JSON.stringify(item.queryRest)
      azTableEntity.parameters = JSON.stringify(item.parameters)
      azTableEntity.queryGroups = JSON.stringify(item.queryGroups)
      azTableEntity.options = JSON.stringify(item.options)
      azTableEntity.exprArgs = JSON.stringify(item.exprArgs)
      azTableEntity.exprs = JSON.stringify(item.exprs)
      azTableEntity.queryExprParams = JSON.stringify(item.queryExprParams)

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = item.id
      return upsertEntity(azTableEntity, tableClient)
    })
    return Promise.all(allQueriesPostPromise).then((allResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allResults
      return outputObj
    })
  }

  async function lclStgCopyPageQueries() {
    var outputObj = []
    var tableClient = serviceClientWithSASTable('pageQueries')
    //var localStorageQueries = JSON.parse(localStorage.getItem('pageQueries'))
    var allQueriesPostPromise = pageQueries.map((item) => {
      var azTableEntity = { ...item }
      azTableEntity.queryRest = JSON.stringify(item.queryRest)
      azTableEntity.parameters = JSON.stringify(item.parameters)
      azTableEntity.queryGroups = JSON.stringify(item.queryGroups)
      azTableEntity.exprs = JSON.stringify(item.exprs)
      azTableEntity.exprArgs = JSON.stringify(item.exprArgs)
      azTableEntity.options = JSON.stringify(item.options)
      azTableEntity.queryExprParams = JSON.stringify(item.queryExprParams)

      if (item.queryParams && byteSize(JSON.stringify(item.queryParams)) < 32000 && JSON.stringify(item.queryParams).length < 32000) {
        azTableEntity.queryParams = JSON.stringify(item.queryParams)
      }
      else {
        if (item.queryParams) {
          item.queryParams.map((paramItem, index) => {
            if (paramItem.value) {
              delete paramItem.value
            }
            return undefined
          })
        }
        azTableEntity.queryParams = JSON.stringify(item.queryParams)
      }

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = item.id
      return upsertEntity(azTableEntity, tableClient)
    })
    return Promise.all(allQueriesPostPromise).then((allResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allResults
      return outputObj
    })
  }

  async function lclStgCopyExpressions() {
    let outputObj = []
    let tableClient = serviceClientWithSASTable('expressions')
    //let localStorageExpressions = JSON.parse(localStorage.getItem('allExpressions'))
    let allExpressionsPostPromise = expressions.map((item) => {
      var azTableEntity = { ...item }

      if (item.expressionPreviewResult) {
        if (byteSize(JSON.stringify(item.expressionPreviewResult)) < 32000 && JSON.stringify(item.expressionPreviewResult).length < 32000) {
          azTableEntity.expressionPreviewResult = JSON.stringify(item.expressionPreviewResult)
        }
        else {
          delete azTableEntity.expressionPreviewResult
        }
      }

      if (item.parameters) {
        if (Array.isArray(item.parameters)) {
          item.parameters.map((param) => {
            if (param.paramValue) {
              delete param.paramValue
            }
            return undefined
          })
        }

        if (byteSize(JSON.stringify(item.parameters)) < 32000 && JSON.stringify(item.parameters).length < 32000) {
          azTableEntity.parameters = JSON.stringify(item.parameters)
        }
        else {
          delete azTableEntity.parameters
        }
      }
      else {
        azTableEntity.parameters = JSON.stringify(item.parameters)
      }

      azTableEntity.complexExpression = JSON.stringify(item.complexExpression)

      delete azTableEntity.expressionFunction

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = encodeURIComponent(item.id)
      return upsertEntity(azTableEntity, tableClient)

    })
    return Promise.all(allExpressionsPostPromise).then((allResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allResults
      return outputObj
    })
  }

  async function lclStgCopyIntegrations() {
    let outputObj = []
    let tableClient = serviceClientWithSASTable('integrations')
    //let localStorageExpressions = JSON.parse(localStorage.getItem('allExpressions'))
    let allIntegrationsPostPromise = integrations.map((item) => {
      var azTableEntity = { ...item }

      azTableEntity.options = JSON.stringify(item.options)

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = encodeURIComponent(item.id)
      return upsertEntity(azTableEntity, tableClient)

    })
    return Promise.all(allIntegrationsPostPromise).then((allResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allResults
      return outputObj
    })
  }

  async function lclStgCopyPagePanel(pageId) {
    var outputObj = []
    var pageIdLclStg = 'panelpage_' + pageId
    var tableClient = serviceClientWithSASTable('panels')
    var localStoragePagePanels = JSON.parse(localStorage.getItem(pageIdLclStg))
    var allPagePanelsPostPromise = localStoragePagePanels.map((item) => {
      var azTableEntity = { ...item }
      azTableEntity.options = JSON.stringify(item.options)
      azTableEntity.visualization = JSON.stringify(item.visualization)
      azTableEntity.vizOptions = JSON.stringify(item.vizOptions)

      azTableEntity.partitionKey = 'dev1_' + pageId
      azTableEntity.rowKey = item.id
      return upsertEntity(azTableEntity, tableClient)
    })
    return Promise.all(allPagePanelsPostPromise).then((allResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allResults
      return outputObj
    })
  }

  async function lclStgCopyPages(pageId) {
    let outputObj = []
    let tableClient = serviceClientWithSASTable('pages')
    //var localStoragePages = JSON.parse(localStorage.getItem('pages'))
    let allPagesPostPromise = pages.map((item) => {
      let azTableEntity = { ...item }
      azTableEntity.parameters = JSON.stringify(item.parameters)
      azTableEntity.dataSources = JSON.stringify(item.dataSources)
      azTableEntity.dataSourceTransforms = JSON.stringify(item.dataSourceTransforms)
      azTableEntity.flyout = JSON.stringify(item.flyout)
      azTableEntity.options = JSON.stringify(item.options)

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = item.id
      return upsertEntity(azTableEntity, tableClient)
    })
    return Promise.all(allPagesPostPromise).then((allResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allResults
      return outputObj
    })
  }

  async function lclStgCopyInterfaces(interfaceId) {
    var outputObj = []
    var tableClient = serviceClientWithSASTable('interfaces')
    var localStorageInterfaces = JSON.parse(localStorage.getItem('interfaces'))
    var allInterfacesPostPromise = localStorageInterfaces.map((item) => {
      var azTableEntity = { ...item }
      azTableEntity.parameters = JSON.stringify(item.parameters)
      azTableEntity.options = JSON.stringify(item.options)

      azTableEntity.partitionKey = 'dev1'
      azTableEntity.rowKey = item.id
      return upsertEntity(azTableEntity, tableClient)
    })
    return Promise.all(allInterfacesPostPromise).then((allResults) => {
      //console.log('line370')
      //console.log(outputObj)
      outputObj = allResults
      return outputObj
    })
  }


  async function saveToLocalStgThenAzure(saveType, dataToSave, pageId) {
    switch (saveType) {
      case 'queries':
        setQueries(dataToSave)
        var localQueries = []
        var azureQueries = []
        var deleteQueries = []
        dataToSave.map((item) => {
          localQueries.push(item.id)
          return null
        })
        try {
          localStorage.setItem('allQueries', JSON.stringify(dataToSave))
        }
        catch (error) {
          console.log(JSON.stringify(error))
        }
        var copyResult = await lclStgCopyQueries()
        var delResult = await listEntitiesInTable('queries', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azureQueries.push(item)
              return null
            })
            azureQueries.map(azureItem => {
              if (!localQueries.includes(azureItem.id)) {
                deleteQueries.push(azureItem)
              }
              return null
            })
            var delQueryResultPromise = deleteQueries.map(delItem => {
              return deleteEntity('queries', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delQueryResultPromise).then(allResults => {
              return allResults
            })
          }
        })

        return { copyResult: copyResult, delResult: delResult }
      case 'pageQueries':
        setPageQueries(dataToSave)
        var localPageQueries = []
        var azurePageQueries = []
        var deletePageQueries = []
        dataToSave.map((item) => {
          localPageQueries.push(item.id)
          return null
        })
        //localStorage.setItem('pageQueries', JSON.stringify(dataToSave))
        copyResult = await lclStgCopyPageQueries()
        delResult = await listEntitiesInTable('pageQueries', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azurePageQueries.push(item)
              return null
            })
            azurePageQueries.map(azureItem => {
              if (!localPageQueries.includes(azureItem.id)) {
                deletePageQueries.push(azureItem)
              }
              return null
            })
            var delQueryResultPromise = deletePageQueries.map(delItem => {
              return deleteEntity('pageQueries', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delQueryResultPromise).then(allResults => {
              return allResults
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'queryResults':
        setQueryResults(dataToSave)
        var localQueryResults = []
        var azureQueryResults = []
        var deleteQueryResults = []
        dataToSave.map((item) => {
          localQueryResults.push(item.id)
          return null
        })
        localStorage.setItem('allQueriesResults', JSON.stringify(dataToSave))
        copyResult = await lclStgCopyQueryResults()
        delResult = await listEntitiesInTable('queryResults', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azureQueryResults.push(item)
              return null
            })
            azureQueryResults.map(azureItem => {
              if (!localQueryResults.includes(azureItem.id)) {
                deleteQueryResults.push(azureItem)
              }
              return null
            })
            var delQueryResultPromise = deleteQueryResults.map(delItem => {
              return deleteEntity('queryResults', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delQueryResultPromise).then(allResults => {
              return allResults
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'visualizations':
        setVisualizations(dataToSave)
        dataToSave = objToArray(dataToSave)
        var localVisualizations = []
        var azureVisualizations = []
        var deleteVisualizations = []
        dataToSave.map((item) => {
          localVisualizations.push(item.id)
          return null
        })
        try {
          localStorage.setItem('allVisualizations', JSON.stringify(dataToSave))
        }
        catch (error) {
          console.log(error)
        }
        copyResult = await lclStgCopyVisualizations()
        delResult = await listEntitiesInTable('visualizations', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azureVisualizations.push(item)
              return null
            })
            azureVisualizations.map(azureItem => {
              if (!localVisualizations.includes(azureItem.id)) {
                deleteVisualizations.push(azureItem)
              }
              return null
            })
            var delVisualizationsPromise = deleteVisualizations.map(delItem => {
              return deleteEntity('visualizations', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delVisualizationsPromise).then(allViz => {
              return allViz
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'images':
        setImages(dataToSave)
        dataToSave = objToArray(dataToSave)
        let localImages = []
        let azureImages = []
        let deleteImages = []
        dataToSave.map((item) => {
          localImages.push(item.id)
          return null
        })
        try {
          localStorage.setItem('allImages', JSON.stringify(dataToSave))
        }
        catch (error) {
          console.log(error)
        }
        copyResult = await lclStgCopyVisualizations()
        delResult = await listEntitiesInTable('images', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azureImages.push(item)
              return null
            })
            azureImages.map(azureItem => {
              if (!localImages.includes(azureItem.id)) {
                deleteImages.push(azureItem)
              }
              return null
            })
            var delImagesPromise = deleteImages.map(delItem => {
              return deleteEntity('images', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delImagesPromise).then(allViz => {
              return allViz
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'expressions':
        setExpressions(dataToSave)
        var localExpressions = []
        var azureExpressions = []
        var deleteExpressions = []
        dataToSave.map((item) => {
          localExpressions.push(item.id)
          return null
        })
        let compressedData = dataToSave.map((item) => {
          delete item.expressionPreviewResult
          return item
        })
        //localStorage.setItem('allExpressions', JSON.stringify(compressedData))
        copyResult = await lclStgCopyExpressions()
        delResult = await listEntitiesInTable('expressions', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azureExpressions.push(item)
              return null
            })
            azureExpressions.map(azureItem => {
              if (!localExpressions.includes(azureItem.id)) {
                deleteExpressions.push(azureItem)
              }
              return null
            })
            var delExpressionsPromise = deleteExpressions.map(delItem => {
              return deleteEntity('expressions', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delExpressionsPromise).then(allExpressions => {
              return allExpressions
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'integrations':
        setIntegrations(dataToSave)
        var localIntegrations = []
        var azureIntegrations = []
        var deleteIntegrations = []
        dataToSave.map((item) => {
          localIntegrations.push(item.id)
          return null
        })
        //localStorage.setItem('allExpressions', JSON.stringify(compressedData))
        copyResult = await lclStgCopyIntegrations()
        delResult = await listEntitiesInTable('integrations', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azureIntegrations.push(item)
              return null
            })
            azureIntegrations.map(azureItem => {
              if (!localIntegrations.includes(azureItem.id)) {
                deleteIntegrations.push(azureItem)
              }
              return null
            })
            var delIntegrationsPromise = deleteIntegrations.map(delItem => {
              return deleteEntity('integrations', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delIntegrationsPromise).then(allIntegrations => {
              return allIntegrations
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'pagePanels':
        var lclStgId = 'panelpage_' + pageId
        var localPagePanels = []
        var azurePagePanels = []
        var deletePagePanels = []
        dataToSave.map((item) => {
          localPagePanels.push(item.id)
          return null
        })
        localStorage.setItem(lclStgId, JSON.stringify(dataToSave))
        copyResult = await lclStgCopyPagePanel(pageId)
        delResult = await listEntitiesInTable('panels', 'dev1_' + pageId, ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azurePagePanels.push(item)
              return null
            })
            azurePagePanels.map(azureItem => {
              if (!localPagePanels.includes(azureItem.id)) {
                deletePagePanels.push(azureItem)
              }
              return null
            })
            var delPagePanelsPromise = deletePagePanels.map(delItem => {
              return deleteEntity('panels', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delPagePanelsPromise).then(allPagePanels => {
              return allPagePanels
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'pages':
        setPages(dataToSave)
        var localPages = []
        var azurePages = []
        var deletePages = []
        dataToSave.map((item) => {
          localPages.push(item.id)
          return null
        })
        //localStorage.setItem('pages', JSON.stringify(dataToSave))
        copyResult = await lclStgCopyPages(pageId)
        delResult = await listEntitiesInTable('pages', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azurePages.push(item)
              return null
            })
            azurePages.map(azureItem => {
              if (!localPages.includes(azureItem.id)) {
                deletePages.push(azureItem)
              }
              return null
            })
            var delPagesPromise = deletePages.map(delItem => {
              return deleteEntity('pages', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delPagesPromise).then(allPages => {
              return allPages
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      case 'interfaces':
        setInterfaces(dataToSave)
        let localInterfaces = []
        let azureInterfaces = []
        let deleteInterfaces = []
        dataToSave.map((item) => {
          localInterfaces.push(item.id)
          return null
        })
        localStorage.setItem('interfaces', JSON.stringify(dataToSave))
        copyResult = await lclStgCopyInterfaces(pageId)
        delResult = await listEntitiesInTable('interfaces', 'dev1', ['id', 'partitionKey', 'rowKey']).then(result => {
          if (result.failure) {
            return [{ failure: result.failure[0] }]
          } else {
            result.success.map((item) => {
              azureInterfaces.push(item)
              return null
            })
            azureInterfaces.map(azureItem => {
              if (!localInterfaces.includes(azureItem.id)) {
                deleteInterfaces.push(azureItem)
              }
              return null
            })
            var delInterfacesPromise = deleteInterfaces.map(delItem => {
              return deleteEntity('interfaces', delItem.partitionKey, delItem.rowKey).then(item => {
                return item
              })
            })
            return Promise.all(delInterfacesPromise).then(allInterfaces => {
              return allInterfaces
            })
          }
        })
        return { copyResult: copyResult, delResult: delResult }
      default:
        break;
    }
    //console.log(azPostResult)
  }

  function getSpecificQuery(queryName) {
    var returnObj = {}

    var selectedQueryObj = queries.find((result) => result.id === queryName)

    if (selectedQueryObj) {
      returnObj = selectedQueryObj
    }

    return returnObj
  }

  function getSpecificQueryResult(queryName) {
    var returnObj = {}

    var selectedQueryResultObj = queryResults.find((result) => result.id === queryName)

    if (selectedQueryResultObj) {
      returnObj = selectedQueryResultObj
    }

    return returnObj

  }

  return (
    <FrontendDataContext.Provider
      value={{
        queries,
        pageQueries,
        queryResults,
        queryGroups,
        visualizations,
        expressions,
        pagePanels,
        pagePanelsV2,
        pages,
        images,
        interfaces,
        setInterfaces,
        integrations,
        setIntegrations,
        saveToLocalStgThenAzure,
        getSpecificQuery,
        getSpecificQueryResult
      }}
    >
      {children}
    </FrontendDataContext.Provider>
  );
};
