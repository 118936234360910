import React, { useState, useEffect, Fragment, useRef } from "react";
//import '@elastic/eui/dist/eui_theme_light.css';
import '@elastic/charts/dist/theme_light.css';
import { useAuthentication } from "../../util/authentication";
import { useTopMenu } from '../../util/topmenu-context'
import { useFeData } from '../../util/frontend-context'
import { Spinner, SpinnerSize } from '@fluentui/react';
import { EuiSelect, EuiAccordion, EuiControlBar, EuiAutoSizer, EuiResizableContainer, EuiGlobalToastList, EuiSideNav, EuiDataGrid, EuiDataGridColumnVisibility, EuiDataGridColumn, EuiButton, EuiSwitch, EuiTabs, EuiTab, EuiPopover, EuiPopoverTitle, EuiButtonIcon, EuiExpression, EuiFlexItem, EuiTextArea, EuiFieldText, EuiFormRow, EuiFlexGroup, EuiSpacer, EuiHorizontalRule } from '@elastic/eui';
import { GetQueryResult, resolveAllExprArgsParams, logAnalyticsQuery, transformLogAnalyticsResult } from '../../util/datasources'
import Editor from "@monaco-editor/react";


export default function AdvancedSearch() {
  const { queryResults, editMode, getSpecificQuery, getSpecificQueryResult } = useFeData()
  const { bottomVisible, setBottomVisible, setBottomHeight, bottomPanel, setBottomPanel, currentMainPage, setCurrentMainPage, currentMainPageParams, setCurrentMainPageParams } = useTopMenu()
  const { tokens } = useAuthentication();

  var allTokens: any = {}
  Object.keys(tokens).map((scope) => {
    allTokens[scope] = tokens[scope].accessToken
    return undefined
  })

  const textFieldContents: any = bottomPanel.textFieldContentsRef
  const datasourceTypeDropdownRef: any = bottomPanel.datasourceTypeDropdownRef
  const specificDatasourceDropdownRef: any = bottomPanel.specificDatasourceDropdownRef
  const resultSetRef: any = bottomPanel.pageDataArrayRef
  const columnsRef: any = bottomPanel.columnsRef
  const columnVizRef: any = bottomPanel.columnVizRef
  const panelHeightObjRef: any = bottomPanel.panelHeightObjRef
  const itemsPerPageRef: any = bottomPanel.itemsPerPageRef
  const itemPageIndexRef: any = bottomPanel.itemPageIndexRef

  const [querySource, setQuerySource] = useState<any>(specificDatasourceDropdownRef.current)
  const [queryType, setQueryType] = useState<any>(datasourceTypeDropdownRef.current)
  const [pageDataArray, setPageDataArray] = useState<any>(resultSetRef.current)
  const [columnVisibility, setColumnVisibility] = useState<any>(columnVizRef.current)
  const [itemsPerPage, setItemsPerPage] = useState<any>(itemsPerPageRef.current)
  const [itemPageIndex, setItemPageIndex] = useState<any>(itemPageIndexRef.current)
  const [columns, setColumns] = useState<any>(columnsRef.current)
  const [spinnerVisible, setSpinnerVisible] = useState<boolean>(false)
  const [panelHeightObj, setPanelHeightObj] = useState<any>(panelHeightObjRef.current)

  /*useEffect(() => {
    let columnsObj: any = []
    if (pageDataArray && pageDataArray.length > 0) {
      columnsObj = Object.keys(pageDataArray[0]).map((col: any) => {
        return { id: col }
      })
      setColumns(columnsObj)
    }
  }, [pageDataArray])*/

  useEffect(() => {
    let columnVisibilityObj: any = []
    if (columns && columns.length > 0 && !columnVisibility) {
      Object.keys(pageDataArray[0]).map((col: any, colIndex: number) => {
        if (col === 'TimeGenerated') {
          columnVisibilityObj.unshift(col)
        }
        else if (colIndex <= 10) {
          columnVisibilityObj.push(col)
        }
      })
      setColumnVisibility(columnVisibilityObj)
      columnVizRef.current = columnVisibilityObj
    }
  }, [columns])

  useEffect(() => {
    columnVizRef.current = columnVisibility

  }, [columnVisibility])

  function onTextUpdate(newText: any, event: any) {
    textFieldContents.current = newText

  }

  function onDropdownChange(event: any) {
    switch (event.target.name) {
      case 'type':
        let queryTypeValue = event.target.value
        if (queryTypeValue === '') {
          setQueryType(undefined)
          delete datasourceTypeDropdownRef.current
        }
        else {
          setQueryType(queryTypeValue)
          datasourceTypeDropdownRef.current = queryTypeValue
        }
        break;
      case 'querysource':
        let querySource = event.target.value
        if (querySource === '') {
          setQuerySource(undefined)
          delete specificDatasourceDropdownRef.current
        }
        else {
          setQuerySource(querySource)
          specificDatasourceDropdownRef.current = querySource
        }
        break;
    }


    return undefined
  }


  function onRunQueryButtonClick(evt: any) {
    let queryResult: any
    setSpinnerVisible(true)
    setColumns([])
    columnsRef.current = []
    setColumnVisibility(undefined)
    columnVizRef.current = []
    switch (queryType) {
      case 'loganalytics':
        logAnalyticsQuery(textFieldContents.current, allTokens['tokenAz'], querySource).then((result: any) => {
          if (!result.error && result.transformed) {
            setPageDataArray(result.transformed)
            resultSetRef.current = result.transformed

            let dataColumns: any[] = result.raw.Tables[0].Columns.map((col: any) => {
              let euiType: any
              switch (col.ColumnType) {
                case 'string':
                  break;
                case 'long':
                  euiType = 'numeric'
                  break;
                case 'dynamic':
                  euiType = 'json'
                  break;
                case 'datetime':
                  euiType = 'datetime'
                  break;
                case 'boolean':
                  euiType = 'boolean'
                  break;
                default:
                  break;
              }

              return { id: col.ColumnName, schema: euiType ? euiType : undefined, isResizable: true }

            })
            setColumns(dataColumns)
            columnsRef.current = dataColumns

            setSpinnerVisible(false)
          }
          else {
            queryResult = result
            setSpinnerVisible(false)
          }
        })
        break;
      default:
        break;

    }

    return undefined
  }

  const querySourceTypes: any = [
    { value: '', text: '' },
    { value: 'loganalytics', text: 'Log Analytics Query' },
    { value: 'kusto', text: 'Azure Data Explorer (Kusto)' },
    { value: 'azureresourcegraph', text: 'Azure Resource Graph' },
    { value: 'azuremanagement', text: 'Azure Management REST' },
    { value: 'msgraph', text: 'Microsoft Graph REST' },
    { value: 'azuremgmtbatch', text: 'Azure Management Batch' },
    { value: 'okta', text: 'Okta REST API' },
    { value: 'simplequery', text: 'Simple / Manual Resultset Entry' },
    { value: 'querytransform', text: 'Transform Existing' },
  ]

  function querySourceNames(queryType: any) {
    let returnObj: any = []

    switch (queryType) {
      case 'loganalytics':
        returnObj = [
          { value: '', text: '' },
          { value: '/subscriptions/8f2573a7-cfdf-4ff1-8709-3e8b8d238579/resourcegroups/loganalytics-rg/providers/microsoft.operationalinsights/workspaces/loganalytics-workspace01', text: 'loganalytics-workspace01' },
        ]
        break;
      case 'kusto':
        returnObj = [{ value: 'azdataexplorerclu02/eastus/pr-powerbi01', text: 'pr-powerbi01' }]
        break;
      case 'azureresourcegraph':
        break;
      default:
        break;
    }

    return returnObj
  }

  let controlBar: any = <EuiFlexGroup
    style={{ paddingLeft: '3em' }}>
    <EuiFlexItem grow={false}>
      <EuiFormRow hasEmptyLabelSpace={true} >
        <EuiButton
          color='primary'
          size='s'
          onClick={onRunQueryButtonClick}
          fill
          id={'runQueryButton'}
          disabled={queryType && querySource ? false : true}
          style={{ width: '10%' }}>
          Run Query
        </EuiButton>
      </EuiFormRow>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiFormRow label='datasource type' >
        <EuiSelect
          id='type'
          name='type'
          value={queryType ? queryType : undefined}
          onChange={onDropdownChange}
          options={querySourceTypes}>
          Run Query
        </EuiSelect>
      </EuiFormRow>
    </EuiFlexItem>
    {queryType ? <><EuiFlexItem grow={false}>
      <EuiFormRow label='datasource'>
        <EuiSelect
          id='querysource'
          name='querysource'
          value={querySource ? querySource : undefined}
          onChange={onDropdownChange}
          options={querySourceNames(queryType)}>
          Run Query
        </EuiSelect>
      </EuiFormRow>
    </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow hasEmptyLabelSpace={true}>
          {spinnerVisible ? <Spinner size={SpinnerSize.large} /> : <></>}
        </EuiFormRow>
      </EuiFlexItem></> : <></>}
  </EuiFlexGroup>

  let editor: any = <Editor
    onChange={((newValue: any) => {
      textFieldContents.current = newValue
      return undefined
    })}
    value={textFieldContents.current}
  //height='50px'
  />

  function panelWidthChange(props: any) {
    setPanelHeightObj(props)
    panelHeightObjRef.current = props

    return undefined
  }

  function onChangeItemsPerPage(itemsPerPage: number) {
    setItemsPerPage(itemsPerPage)
    itemsPerPageRef.current = itemsPerPage
    return undefined
  }

  function onChangePageItemIndex(pageIndex: number) {
    setItemPageIndex(pageIndex)
    itemPageIndexRef.current = pageIndex

    return undefined
  }

  return <>
    <EuiResizableContainer style={{ height: '820px' }} onPanelWidthChange={panelWidthChange} direction='vertical'>
      {(EuiResizablePanel, EuiResizableButton) => (
        <>
          <EuiResizablePanel mode='main' id='searchArea' size={panelHeightObjRef.current ? panelHeightObjRef.current['searchArea'] : 30} >
            {controlBar}
            <EuiSpacer size='s' />
            {editor}
          </EuiResizablePanel>
          <EuiResizableButton />
          <EuiResizablePanel mode='collapsible' id='resultsGrid' size={panelHeightObjRef.current ? panelHeightObjRef.current['resultsGrid'] : 70} >
            <EuiDataGrid
              height='100%'
              width='100%'
              aria-label="Result data grid"
              renderCellValue={((props: any) => pageDataArray[props.rowIndex][props.columnId])}
              columns={columns ? columns : []}
              //gridStyle={gridStyle}
              columnVisibility={columnVisibility ? { visibleColumns: columnVisibility, setVisibleColumns: setColumnVisibility } : { visibleColumns: [], setVisibleColumns: (() => { }) }}
              rowCount={pageDataArray ? pageDataArray.length : undefined}
              toolbarVisibility
              //renderCellValue={renderCellValue}
              pagination={{
                pageIndex: itemPageIndex ? itemPageIndex : 0,
                pageSize: itemsPerPage ? itemsPerPage : 20,
                pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
                onChangePage: onChangePageItemIndex,
                onChangeItemsPerPage: onChangeItemsPerPage
              }}
              gridStyle={{
                stripes: true,
                fontSize: 'm',
              }}
              inMemory={{ level: 'sorting' }}
            />
          </EuiResizablePanel>
        </>
      )}
    </EuiResizableContainer> </>
}