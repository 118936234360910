import React from 'react';
import { Fabric, Customizer, createTheme } from '@fluentui/react';
import { useLocalStorage } from 'react-use';

import {
  DefaultCustomizations,
  DarkCustomizations
} from '@uifabric/theme-samples';

const myTheme = createTheme({
  palette: {
    themePrimary: '#6a6af0',
    themeLighterAlt: '#f9f9fe',
    themeLighter: '#e6e6fd',
    themeLight: '#d0d0fa',
    themeTertiary: '#a3a3f6',
    themeSecondary: '#7b7bf2',
    themeDarkAlt: '#5f5fd8',
    themeDark: '#5050b6',
    themeDarker: '#3b3b86',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#8787b1',
    neutralSecondary: '#696998',
    neutralPrimaryAlt: '#4e4e7e',
    neutralPrimary: '#08081a',
    neutralDark: '#23234c',
    black: '#141433',
    white: '#ffffff',
  },
  semanticColors: {
    menuItemText: '#ffffff',
    menuItemBackgroundPressed: '#6a6af0',
    menuItemBackgroundHovered: '#393948'
  },
  defaultFontStyle: {
    fontFamily: 'NunitoSans',
    fontWeight: 'regular'
  }

});

var themeCustomizations = {
  settings: {
      theme: myTheme,
  },
  scopedSettings: {},
};

export const ThemeList = {
  light: themeCustomizations,
  dark: DarkCustomizations
};

export const ThemeContext = React.createContext({
  theme: 'light',
  changeTheme: name => {}
});

const ThemeWrapper = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Customizer {...ThemeList[theme]}>
          <Fabric>{children}</Fabric>
        </Customizer>
      )}
    </ThemeContext.Consumer>
  );
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const changeTheme = name => ThemeList[name] && setTheme(name);
  const value = { theme, changeTheme };
  return (
    <ThemeContext.Provider value={value}>
      <ThemeWrapper>{children}</ThemeWrapper>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
