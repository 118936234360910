import '@elastic/eui/dist/eui_theme_light.css';
import '@elastic/charts/dist/theme_light.css';
//import './euitheme.scss';
import { getQueryResultByName } from './datasources'

export function baseExpressions() {
  var outputObj: any[] = []

  outputObj = [ ]

  return outputObj

}

export function evaluateExpression(expressionObj: any, opts: any) {
  //var lclStgExpressions: any = localStorage.getItem('allExpressions') || null
  var lclStgExpressionsJson: any[] = []
  if (opts.allExp) {
    lclStgExpressionsJson = opts.allExp
  }

  var lclStgQueriesResults: any = localStorage.getItem('allQueriesResults') || null
  var lclStgQueriesResultsJson: any[] = []
  if (lclStgQueriesResultsJson) {
    lclStgQueriesResultsJson = JSON.parse(lclStgQueriesResults)
  }

  var allExpressions = lclStgExpressionsJson
  var allQueriesResults = lclStgQueriesResultsJson

  if (expressionObj) {
    var expressionText = expressionObj.expression
    var expressionType = expressionObj.type
    var expressionParams: string[] = []
    var expressionParamValues: string[] = []

    var expressionOutput: any

    switch (expressionType) {
      case 'text':
        expressionOutput = expressionText
        break;
      case 'function':
        if (expressionObj.parameters) {
          expressionObj.parameters.map((item: any) => {
            var paramValue: any
            if (item.paramType === 'expression') {
              var expressionObj = {}
              expressionObj = allExpressions.find((result: any) => result.id === item.paramValue)
              if (opts.paramId) {
                paramValue = evaluateExpression(expressionObj, {allExp: allExpressions, paramId: opts.paramId})
              }
              else {
                paramValue = evaluateExpression(expressionObj, {allExp: allExpressions})

              }
            }
            else if (item.paramType === 'resultset2') {
              var resultSetObj = allQueriesResults.find((result: any) => result.id === item.paramValue)
              if (resultSetObj && resultSetObj.queryResult && resultSetObj.queryResult.transformed) {
                paramValue = resultSetObj.queryResult.transformed
              }
              else {
                paramValue = []

              }
            }
            else {
              paramValue = item.paramValue
            }
            expressionParams.push(item.paramId)
            expressionParamValues.push(paramValue)

            return null
          })
        }

        expressionParams.push(expressionText)

        try {
          // eslint-disable-next-line no-new-func
          var currentFunction = new Function(...expressionParams)
          expressionObj.expressionFunction = currentFunction
          expressionOutput = currentFunction(...expressionParamValues)
        }
        catch (err: any) {
          var caughtError = err

          var errorString = caughtError.name + ': ' + caughtError.message
          expressionOutput = errorString
        }
        break;
      case 'querytransform':
        expressionParams.push(expressionObj.resultsetVariable)
        expressionParams.push(expressionText)

        var resultsetObj: any = getQueryResultByName(expressionObj.resultsetId)
        expressionParamValues.push(resultsetObj)

        try {
          // eslint-disable-next-line no-new-func
          currentFunction = new Function(...expressionParams)
          expressionObj.expressionFunction = currentFunction
          expressionOutput = currentFunction(...expressionParamValues)
        }
        catch (err: any) {
          caughtError = err

          errorString = caughtError.name + ': ' + caughtError.message
          expressionOutput = errorString
        }
        break;
      case 'complex':
        if (expressionObj.complexExpression) {
          var subExpressionResults: any[] = []

          expressionObj.complexExpression.map((item: any) => {
            var subExpressionName: any = item.name
            var expressionObj: any

            expressionObj = allExpressions.find((result: any) => result.id === subExpressionName)

            if (item.parameters && expressionObj.parameters) {
              item.parameters.map((subItem: any, subIndex: number) => {
                expressionObj.parameters[subIndex].paramValue = subItem.paramValue

                return null
              })
            }

            subExpressionResults.push(evaluateExpression(expressionObj, {allExp: allExpressions, paramId: item.key}))

            return null
          })

          let expressionOutputString: string = ''
          subExpressionResults.map((item: any) => {
            if (item) {
              if (Object.prototype.toString.call(item) === '[object Date]') {
                expressionOutputString = expressionOutputString + item.toISOString()
              }
              else {
                expressionOutputString = expressionOutputString + item.toString()
              }

              return null
            }
          })

          expressionOutput = expressionOutputString

        }
        break;
      case 'default':
        break;
    }

    return expressionOutput

  }
  return {}
}

export function evaluateTransformExpression(expressionObj: any, resultSet: any) {
  var expressionText = expressionObj.expression
  var expressionType = expressionObj.type
  var expressionParams: string[] = []
  var expressionParamValues: string[] = []

  var expressionOutput: any

  switch (expressionType) {
    case 'querytransform':
      expressionParams.push(expressionObj.resultsetVariable)
      expressionParams.push(expressionText)

      expressionParamValues.push(resultSet)

      try {
        // eslint-disable-next-line no-new-func
        var currentFunction = new Function(...expressionParams)
        expressionObj.expressionFunction = currentFunction
        expressionOutput = currentFunction(...expressionParamValues)
      }
      catch (err: any) {
        var caughtError = err

        var errorString = caughtError.name + ': ' + caughtError.message
        expressionOutput = errorString
      }
      break;
    case 'default':
      break;
  }

  return expressionOutput

}

export function evaluateExpressionByName(expressionId: any, allExpressionsObj?: any[]) {
  var expressionOutput: any = {}
  var allExpressions = []

  if (!allExpressionsObj) {
    var lclStgExpressions: any = localStorage.getItem('allExpressions') || null
    if (lclStgExpressions) {
      allExpressions = JSON.parse(lclStgExpressions)
    }
  }
  else {
    allExpressions = allExpressionsObj
  }


  var expressionIndex: number = allExpressions.findIndex((result: any) => result.id === expressionId)
  var expressionObj: any = allExpressions[expressionIndex]

  expressionOutput = evaluateExpression(expressionObj, {allExp: allExpressions})

  return expressionOutput

}

export function getExpressionObjByName(expressionName: any, allExpressionsObj?: any[]) {
  var returnObj: any = {}
  var lclStgExpressionsJson: any[] = []

  if (!allExpressionsObj) {
    var lclStgExpressions: any = localStorage.getItem('allExpressions') || null
    if (lclStgExpressions) {
      lclStgExpressionsJson = JSON.parse(lclStgExpressions)
    }
  }
  else {
    lclStgExpressionsJson = allExpressionsObj
  }

  var selectedQueryResultObj: any = lclStgExpressionsJson.find((result: any) => result.id === expressionName)

  if (selectedQueryResultObj) {
    returnObj = selectedQueryResultObj
  }

  return returnObj
}

export function getExpressionIndexByName(expressionName: any, allExpressionsObj?: any[]) {
  var returnObj: number = -2
  var lclStgExpressionsJson: any[] = []

  if (!allExpressionsObj) {

    var lclStgExpressions: any = localStorage.getItem('allExpressions') || null
    if (lclStgExpressions) {
      lclStgExpressionsJson = JSON.parse(lclStgExpressions)
    }
    else {
      lclStgExpressionsJson = baseExpressions()
    }
  }
  else {
    lclStgExpressionsJson = allExpressionsObj
  }

  var selectedQueryResultObj: number = lclStgExpressionsJson.findIndex((result: any) => result.id === expressionName)

  if (selectedQueryResultObj !== -2) {
    returnObj = selectedQueryResultObj
  }

  return returnObj
}