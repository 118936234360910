export const msalConfig = () => {
    var redirectUri = ''

    if ( origin.includes('localhost') ) {
        redirectUri = 'http://localhost:3006'
    }
    else {
        //redirectUri = 'https://wvdadmindemo.azurewebsites.net'
        redirectUri = 'https://demo.marekb.com/'
    }
    
    return {
    auth: {
        clientId: "8482ffc0-198f-48e0-a2a3-80aeb830f408",
        authority: "https://login.microsoftonline.com/0ad0f202-35a1-4d01-a67a-ce02e03e9558",
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    } }
};

export const msalPwshConfig = () => {  
    return {
    auth: {
        clientId: "a0c73c16-a7e3-4564-9a95-2bdf47383716"
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    } }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiRequest = {
    scopes: ["https://graph.microsoft.com/.default"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

export const apiRequestGraphScope = {
    scopes: ["https://graph.microsoft.com/.default"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

export const apiRequestAzureMgmtScope = {
    scopes: ["https://management.azure.com/.default"],
    forceRefresh: false
}

export const apiRequestKustoScope = {
    scopes: ["https://help.kusto.windows.net/.default"],
    forceRefresh: false
}

export const apiRequestLogAnalyticsScope = {
    scopes: ["https://api.loganalytics.io/.default"],
    forceRefresh: false
}

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};