import React from 'react';
import { get } from 'lodash-es';
import paths from 'path';
import { ReactComponent as OktaLogoSvg } from './assets/okta.svg';
import { hierarchize } from './util/hierarchical';

const keyName = 'key';
const pathName = 'path';
const uniqueKeyName = 'uniqueKey';

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + '.' + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, '');
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfigAad = hierarchize(
  {
    key: 'homedemo',
    name: 'Home',
    icon: 'ViewDashboard',
    path: '/',
    component: React.lazy(() => import('./components/pages/DynamicPage')),
    children: [
      {
        key: 'login',
        name: 'Login',
        isPublic: true,
        isHidden: true,
        component: React.lazy(() => import('./components/pages/Login')),
      },
      {
        key: 'homedashboard',
        name: 'Home v2',
        icon: 'ViewDashboard',
        title: 'Home v2',
        component: React.lazy(() => import('./components/pages/TwoPanelPageLayout')),
        isHidden: true
      },

      {
        key: 'search',
        name: 'Search',
        icon: 'Search',
        component: React.lazy(() => import('./components/pages/AdvancedSearch')),
        isHidden: false
      },
      {
        key: 'security',
        name: 'Security',
        icon: '',
        children: [
          {
            key: 'securityOverview',
            name: 'Security',
            icon: 'Encryption',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            isHidden: false
          },
          {
            key: 'securityIncidentDetail',
            name: 'Incident Detail',
            icon: 'Encryption',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            isHidden: true
          }
        ]
      },
      {
        key: 'usersdevices',
        name: 'Users and Devices',
        icon: 'managementApp',
        children: [
          {
            key: 'users',
            name: 'Users',
            icon: 'Contact',
            isHidden: false,
            children: [
              {
                key: 'usersLanding',
                name: 'Users',
                icon: 'Contact',
                component: React.lazy(() => import('./components/pages/DynamicPage')),
                isHidden: false
              },
              {
                key: 'userDetail',
                name: 'User Detail',
                icon: 'Contact',
                component: React.lazy(() => import('./components/pages/DynamicPage')),
                isHidden: false
              }
            ]
          },
          {
            key: 'userDetail',
            name: 'User Detail',
            icon: 'Contact',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            isHidden: true
          },
          {
            key: 'endpointsLanding',
            name: 'Endpoints',
            icon: 'Devices3',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            isHidden: false
          },
          {
            key: 'endpointDetail',
            name: 'Endpoint Detail',
            icon: 'Devices3',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            isHidden: true
          }
        ]
      },
      {
        key: 'warRoom',
        name: 'War Room',
        icon: 'WhiteBoardApp16',
        component: React.lazy(() => import('./components/pages/DynamicPage')),
        isHidden: false
      },
      {
        key: 'cloud',
        name: 'Cloud',
        icon: 'managementApp',
        children: [
          {
            key: 'cloud-azure',
            name: 'Azure old',
            icon: 'AzureLogo',
            title: 'Azure old',
            isHidden: true,
            component: React.lazy(() => import('./components/pages/DynamicPage'))
          },
          {
            key: 'aws',
            name: 'Amazon Web Services',
            icon: 'AmazonWebServicesLogo',
            title: 'Amazon Web Services',
            isHidden: false,
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            children: [{
              key: 'awsLanding',
              name: 'Overview',
              icon: 'AmazonWebServicesLogo',
              title: 'AWS Home',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'awsComputeDetail',
              name: 'EC2',
              icon: 'AmazonWebServicesLogo',
              title: 'EC2',
              isHidden: true,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'awsS3Detail',
              name: 'S3',
              icon: 'AmazonWebServicesLogo',
              title: 'S3',
              isHidden: true,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'awsVpcDetail',
              name: 'VPC',
              icon: 'AmazonWebServicesLogo',
              title: 'VPC',
              isHidden: true,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'awsIamGroups',
              name: 'IAM Groups',
              icon: 'AmazonWebServicesLogo',
              title: 'IAM Groups',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'awsIamUsers',
              name: 'IAM Users',
              icon: 'AmazonWebServicesLogo',
              title: 'IAM Users',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'awsIamRoles',
              name: 'IAM Roles',
              icon: 'AmazonWebServicesLogo',
              title: 'IAM Roles',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'awsIamPolicies',
              name: 'IAM Policies',
              icon: 'AmazonWebServicesLogo',
              title: 'IAM Policies',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }
            ]
          },
          {
            key: 'azure',
            name: 'Azure',
            icon: 'AzureLogo',
            title: 'Azure',
            isHidden: false,
            children: [{
              key: 'azureLanding',
              name: 'Overview',
              icon: 'AzureLogo',
              title: 'Azure',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'azPerformance',
              name: 'Performance',
              icon: 'AzureLogo',
              title: 'Performance',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'azCosts',
              name: 'Costs',
              icon: 'AzureLogo',
              title: 'Costs',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'azSupport',
              name: 'Support',
              icon: 'AzureLogo',
              title: 'Azure',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }, {
              key: 'azResourceDetail',
              name: 'Resource Detail',
              icon: 'AzureLogo',
              title: 'Resource Detail',
              isHidden: false,
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }]
          },
          {
            key: 'gcp',
            name: 'Google Compute',
            icon: 'GoogleDriveLogo',
            title: 'Google Compute',
            isHidden: false,
            component: React.lazy(() => import('./components/pages/DynamicPage'))
          }
        ]
      }, {
        key: 'collaboration',
        name: 'Collaboration and Productivity',
        icon: 'Mail',
        children: [{
          key: 'office365',
          name: 'Office 365',
          icon: 'OfficeLogo',
          component: React.lazy(() => import('./components/pages/DynamicPage')),
          children: [
            {
              key: 'messagingLanding',
              name: 'Message Trace',
              icon: 'Mail',
              component: React.lazy(() => import('./components/pages/DynamicPage'))
            }]
        }
        ]
      },
      {
        key: 'saas',
        name: 'SaaS',
        icon: 'managementApp',
        children: [
          {
            key: 'avd',
            name: 'Azure Virtual Desktop',
            icon: 'WindowsLogo',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            children: [
              {
                key: 'avdOverview',
                name: 'Overview',
                icon: 'WindowsLogo',
                component: React.lazy(() => import('./components/pages/DynamicPage')),
                isHidden: false
              },
              {
                key: 'avdSessionsOverview',
                name: 'Sessions',
                icon: 'WindowsLogo',
                component: React.lazy(() => import('./components/pages/DynamicPage')),
                isHidden: false
              },
              {
                key: 'avdSessionDetail',
                name: 'SessionDetail',
                icon: 'WindowsLogo',
                component: React.lazy(() => import('./components/pages/DynamicPage')),
                isHidden: true
              },
              {
                key: 'avdHostPoolDetail',
                name: 'Host Pools',
                icon: 'WindowsLogo',
                component: React.lazy(() => import('./components/pages/DynamicPage')),
                isHidden: false
              },
              {
                key: 'avdSessionHostDetail',
                name: 'Session Hosts',
                icon: 'WindowsLogo',
                component: React.lazy(() => import('./components/pages/DynamicPage')),
                isHidden: false
              }
            ]
          },
          {
            key: 'oktaLanding',
            name: 'Okta',
            svg: false,
            icon: 'okta-svg',
            component: React.lazy(() => import('./components/pages/DynamicPage'))
          },
          {
            key: 'oktaAppDetail',
            name: 'Okta - App Detail',
            svg: false,
            icon: 'okta-svg',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            isHidden: true
          },
          {
            key: 'oktaSessionDetail',
            name: 'Okta - Session Detail',
            svg: false,
            icon: 'okta-svg',
            component: React.lazy(() => import('./components/pages/DynamicPage')),
            isHidden: true
          },
          {
            key: 'umbrellaDnsLanding',
            name: 'Umbrella DNS',
            svg: false,
            icon: 'umbrelladns-svg',
            component: React.lazy(() => import('./components/pages/DynamicPage'))
          },

        ]
      },
      {
        key: 'settings',
        name: 'Settings',
        icon: 'managementApp',
        children: [
          {
            key: 'queries',
            name: 'Queries',
            icon: 'ReviewRequestSolid',
            component: React.lazy(() => import('./components/pages/Queries')),
            isHidden: false
          },
          {
            key: 'visualizations',
            name: 'Visualizations',
            icon: 'Chart',
            component: React.lazy(() => import('./components/pages/Visualizations')),
            isHidden: false
          },
          {
            key: 'expressions',
            name: 'Expressions',
            icon: 'TextField',
            component: React.lazy(() => import('./components/pages/Expressions')),
            isHidden: true
          },
          {
            key: 'expressions2',
            name: 'Expressions',
            icon: 'TextField',
            component: React.lazy(() => import('./components/pages/ExpressionsV2')),
            isHidden: false
          },
          {
            key: 'pages',
            name: 'Pages',
            icon: 'Page',
            component: React.lazy(() => import('./components/pages/Pages')),
            isHidden: false
          },
          {
            key: 'integrations',
            name: 'Integrations',
            icon: 'Org',
            component: React.lazy(() => import('./components/pages/Integrations')),
            isHidden: false
          },
          {
            key: 'databasedata',
            name: 'Data',
            icon: 'Org',
            component: React.lazy(() => import('./components/pages/Data')),
            isHidden: false
          },
          {
            key: 'scratchpad',
            name: 'Scratchpad',
            icon: 'Org',
            component: React.lazy(() => import('./components/pages/Scratchpad')),
            isHidden: false
          },
          {
            key: 'datasources',
            name: 'Data Sources',
            icon: 'Org',
            component: React.lazy(() => import('./components/pages/Data')),
            isHidden: false
          },
        ]
      }
    ]
  },
  null,
  pathGenerator
);

export const pageTitles = [
  { pathname: "/homedashboard", title: 'Home v2', icon: 'ViewDashboard' },
  { pathname: "/warRoom", title: 'War Room', icon: 'WhiteBoardApp16' },
  { pathname: "/cloud/azure", title: 'Azure v2', icon: 'AzureLogo' }
]

//console.log(routeConfig);

export default routeConfigAad;
