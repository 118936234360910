import React from "react";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { ReactComponent as OktaLogoSvg } from '../../../../assets/okta.svg';


/**
 * Represents a composed link in the Nav component.
 */
export function NavLink(
  {
    rightIconName,
    leftIconName,
    id,
    href,
    target,
    onClick,
    dataValue,
    ariaLabel,
    ariaExpanded,
    title,
    role,
    rootClassName,
    barClassName,
    content,
    iconClassName,
    textClassName,
    focusedStyle,
    leftIconContent,
    contentImage,
    svg,
    leftIconIsDropdown
  }
) {

  const computedTextWidth = {
    // 100px to accomodate left and right icons (48px each)
    width: "calc(100% - 96px)"
  };

  if (textClassName && !(textClassName.includes('navChildItemName'))) {
    if (!rightIconName && !leftIconName) {
      // no icons, take full with to text
      computedTextWidth.width = "100%";
    } else if (!leftIconName || !rightIconName) {
      // 48px to the left or right icon
      computedTextWidth.width = "calc(100% - 48px)";
    }
  }

  const fixedIconWidth = {
    width: "48px",
    display: rightIconName === "OpenInNewWindow" ? "none" : "inline-block"
  };

  //if (svg) {
  //leftIconContent = <div><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path d="M32 0C14.37 0 0 14.267 0 32s14.268 32 32 32 32-14.268 32-32S49.63 0 32 0zm0 48c-8.866 0-16-7.134-16-16s7.134-16 16-16 16 7.134 16 16-7.134 16-16 16z" fill="#007dc1"/></svg></div>
  //  leftIconName = 'OktaIcon'
  //}

  return (

    <div className={rootClassName} aria-hidden="true">
      <hr className={barClassName} />
      <a
        id={id + '-icon'}
        href={href}
        target={target}
        onClick={onClick}
        data-hint={false}
        data-value={dataValue}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        role={role}
        className={focusedStyle}
        title={title}
        style={{ display: 'inline' }}
      >{leftIconName ? (
        <FontIcon iconName={leftIconName} className={iconClassName} />
      ) : null}
        {leftIconContent ? (
          <img src={leftIconContent} alt="Logo" className={iconClassName} />
        ) : null}</a>
      <a
        id={id}
        href={href}
        target={target}
        onClick={onClick}
        data-hint={false}
        data-value={dataValue}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        role={role}
        className={focusedStyle}
        title={title}
        style={{ display: 'inline' }}
      >
        {content ? (
          <div className={mergeStyles(textClassName, computedTextWidth)}>
            {content}
          </div>
        ) : null}
        {contentImage ? (
          <img src={contentImage} className={textClassName} />
        ) : null}
        {rightIconName ? (
          <Icon
            iconName={rightIconName}
            className={mergeStyles(iconClassName, fixedIconWidth)}
          />
        ) : null}
      </a>
    </div>
  );
}
