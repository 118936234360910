import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthentication } from '../authentication';

export default function AuthorizedRoute({
  id,
  path,
  exact,
  strict,
  isPublic,
  children,
  ...rest
}) {

  const { authenticated } = useAuthentication()

  //const { isAuthenticated } = useAuthentication();
  let authorized = isPublic || authenticated;

  var returnObj = (
    <Route
      {...rest}
      key={id}
      path={path}
      exact={exact}
      strict={strict}
      render={({ location }) =>
        authorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );

  return returnObj
}
