import React from 'react';
import { useAuthentication } from '../util/authentication';
import {MasterLayout} from './MasterLayout';
import { BlankLayout } from './BlankLayout';
import { TopMenuProvider } from '../util/topmenu-context';

export function AutoSwitchLayout({ children }) {
  //const { isAuthenticated } = useAuthentication();
  const { authenticated } = useAuthentication();
  const Layout = authenticated ? MasterLayout : BlankLayout;

  return <Layout>{children}</Layout>;
}
