import React from 'react';
import { Stack, styled, classNamesFunction } from '@fluentui/react';
import { Sidebar, NewSidebar } from '../Sidebar';
import { TopMenu } from '../TopMenu';
import { useTopMenu } from '../../util/topmenu-context'
import { EuiGlobalToastList, EuiFlexGroup, EuiControlBar, EuiConfirmModal, EuiFlyout, EuiFlexItem, EuiTabbedContent } from '@elastic/eui';

const getStyles = ({ theme }) => {
  return {
    root: {},
    sidebar: {},
    contentWrapper: {
    }
  };
};

const getClassNames = classNamesFunction();

function MasterLayoutComponent({ children, theme, styles }) {
  const classNames = getClassNames(styles, { theme });
  const { bottomVisible, setBottomVisible, setBottomHeight, bottomPanel, setBottomPanel, currentMainPage, setCurrentMainPage, currentMainPageParams, setCurrentMainPageParams } = useTopMenu()

  return (
    <Stack>
      <Stack horizontal className={classNames.root}>
        <Stack.Item style={{ zIndex: 200 }} grow={false} className={classNames.sidebar}>
          <Sidebar />
          {/*<NewSidebar />*/}
        </Stack.Item>
        <Stack.Item grow={true}>
          <TopMenu />
          <Stack style={{width: '99%'}}>{children}
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
}

export const MasterLayout = styled(MasterLayoutComponent, getStyles);
