import React, { useState } from 'react';
import { IconButton, ContextualMenuItemType } from '@fluentui/react';
import { useTopMenu } from '../../util/topmenu-context'


export function SettingsMenu() {

  const { editMode, setEditMode, layoutLock, setLayoutLock } = useTopMenu()


  var lclStgDebugOptions = localStorage.getItem('debugOptions')
  var lclStgDebugOptionsJson = {}
  if (lclStgDebugOptions) {
    lclStgDebugOptionsJson = JSON.parse(lclStgDebugOptions)
  }

  //const { user, token, logout } = useMsal();
  const [debugOptions, setDebugOptions] = useState(lclStgDebugOptionsJson)
  //const [editMode, setEditMode] = useState(false)
  //const [sidebarCollapsed, setSidebarCollapsed] = useState(JSON.parse(localStorage.getItem('debugOptions'))['sidebarCollapsed'])


  function toggleEditMode() {
    if (editMode === true) {
      setEditMode(false)
      return false
    }
    else {
      setEditMode(true)
      return true
    }
  }

  function toggleLayoutLock() {
    if (layoutLock === true) {
      setLayoutLock(false)
      return false
    }
    else {
      setLayoutLock(true)
      return true
    }
  }

  /*function toggleCollapsed() {
    //var currentState = localStorage.getItem('NavToggler.isNavCollapsed')
    var currentState = sidebarCollapsed
    var newState
    if (currentState === true) {
      newState = 'false'
      setSidebarCollapsed(false)
    }
    else if (currentState === false)
    {
      newState = 'true'
      setSidebarCollapsed(true)
    }

    localStorage.setItem('NavToggler.isNavCollapsed',newState)

    return undefined
  }*/

  function debugToggle(evt) {
    var debugOption = evt.currentTarget.id
    var currentValue
    var newValue = false

    if (debugOptions[debugOption] !== undefined) {
      currentValue = debugOptions[debugOption]

      if (currentValue === false) {
        newValue = true
      }
    }

    debugOptions[debugOption] = newValue

    setDebugOptions(debugOptions)
    localStorage.setItem('debugOptions', JSON.stringify(debugOptions))

    /*if (debugOption === 'sidebarCollapsed') {
      toggleCollapsed()
    }*/
  }

  const settingsMenuProps = () => ({
    items: [
      {
        key: 'debug',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          title: 'Debug',
          items: [
            { id: 'vizTimeStamp', key: 'vizTimeStamp', text: 'Visualization Timestamps', canCheck: true, isChecked: debugOptions.vizTimeStamp, onClick: debugToggle },
            { id: 'queriesUseState', key: 'queriesUseState', text: 'Show Queries (useState)', canCheck: true, isChecked: debugOptions.queriesUseState, onClick: debugToggle },
            { id: 'queryResultsUseState', key: 'queryResultsUseState', text: 'Show Query Results (useState)', canCheck: true, isChecked: debugOptions.queryResultsUseState, onClick: debugToggle },
            { id: 'pageQueriesUseState', key: 'pageQueriesUseState', text: 'Show Page Queries (useState)', canCheck: true, isChecked: debugOptions.pageQueriesUseState, onClick: debugToggle },
            { id: 'visualizationUseState', key: 'visualizationUseState', text: 'Show Visualizations (useState)', canCheck: true, isChecked: debugOptions.visualizationUseState, onClick: debugToggle },
            { id: 'expressionUseState', key: 'expressionUseState', text: 'Show Expressions (useState)', canCheck: true, isChecked: debugOptions.expressionUseState, onClick: debugToggle },
            { id: 'showPageData', key: 'showPageData', text: 'Show Page Data', canCheck: true, isChecked: debugOptions.showPageData, onClick: debugToggle },
            //{ id: 'sidebarCollapsed', key: 'sidebarCollapsed', text: 'Sidebar Collapsed', canCheck: true, isChecked: debugOptions.sidebarCollapsed, onClick: debugToggle },
          ],
        },
      },
    ],
  })

  return (<>
    <IconButton
      styles={{ icon: { fontSize: 'medium', color: 'black' } }}
      iconProps={layoutLock ? { iconName: 'Lock' } : { iconName: 'UnlockSolid' } }
      onClick={toggleLayoutLock} />
    <IconButton
      styles={{ icon: { fontSize: 'medium', color: 'black' } }}
      iconProps={editMode ? { iconName: 'EditSolid12' } : { iconName: 'Edit' }}
      onClick={toggleEditMode} />
    <IconButton
      styles={{ icon: { fontSize: 'medium', color: 'black' } }}
      iconProps={{ iconName: 'Settings' }}
      menuProps={settingsMenuProps()} />
  </>
  );
}
