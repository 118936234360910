import { AnimationClassNames, getFocusStyle } from '@fluentui/react';

export function getStyles(
  {
    isSelected,
    hasChildren,
    nestingLevel,
    isCollapsed,
    scrollTop,
    isChildLinkSelected,
    hasGroupName,
    theme,
  }
) {
  const { semanticColors, fonts } = theme;

  const navFontSize = fonts.medium.fontSize;
  const navTextColor = semanticColors.menuItemText;
  const navWidth = 220;
  const navCollapsedWidth = 48;
  const shortenedIconWidth = 32;
  const navFloatingWidth = 230;
  const navItemHeight = 56;
  const navChildItemHeight = 32;
  //const navBackgroundColor = semanticColors.menuBackground;
  const navBackgroundColor = '#08081a';
  const floatingNavBackgroundColor = semanticColors.menuBackground;
  const navItemHoverColor = semanticColors.menuItemBackgroundHovered;
  const navGroupSeparatorItemHeight = 40;
  const navGroupSeparatorWithGroupNameHeight = 70;
  //const navItemWithChildBgColor = semanticColors.menuItemBackgroundPressed;
  const navItemWithChildBgColor = '#403e3e';
  const navItemSelectedColor = semanticColors.menuItemBackgroundPressed;
  const navItemIndentSize = 50;
  const navFloatingItemIndentSize = 20;
  const BackDropSelector =
    '@supports (backdrop-filter: blur(20px)) or (-webkit-backdrop-filter: blur(20px))';
  const MouseEnabledDeviceSelector = '@media (hover: hover) and (pointer: fine)';

  return {
    root: {
      width: isCollapsed ? navCollapsedWidth : navWidth,
      height: '100%',
      backgroundColor: navBackgroundColor,
      color: navTextColor,
      borderRightStyle: 'solid',
      borderRightWidth: '0px',
      borderRightColor: semanticColors.bodyFrameDivider,
      selectors: {
        ul: {
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          fontSize: navFontSize,
          selectors: {
            [MouseEnabledDeviceSelector]: {
              selectors: {
                'li:hover >div': {
                  display: 'block'
                }
              }
            }
          },
        },
        a: {
          color: `${navTextColor} !important`,
          outline: 'none'
        }
      }
    },
    navItemRoot: {
      height:
        !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      cursor: 'pointer',
      paddingLeft:
        !!nestingLevel && nestingLevel > 0
          ? nestingLevel * navItemIndentSize
          : 'inherit',
      selectors: {
        ':hover': {
          backgroundColor: hasChildren
            ? navItemWithChildBgColor
            : navItemHoverColor
        },
        ':active': {
          backgroundColor: navItemSelectedColor
        }
      }
    },
    navItemBarMarker: {
      /*marginLeft:
        !!nestingLevel && nestingLevel > 0 && !hasChildren ? '-7px' : '3px',*/
      marginRight:
        !!nestingLevel && nestingLevel > 0 && !hasChildren ? '8px' : '0px',
      marginTop: !!nestingLevel && nestingLevel > 0 ? '7px' : '12px',
      width: '2px',
      height: !!nestingLevel && nestingLevel > 0 ? '18px' : '24px',
      backgroundColor: semanticColors.menuItemBackgroundPressed,
      display: isSelected || isChildLinkSelected ? 'inline-block' : 'none',
      borderWidth: 0
    },
    navItemIconColumn: {
      width:
        isSelected || isChildLinkSelected
          ? shortenedIconWidth
          : navCollapsedWidth,
      fontSize: '16px',
      lineHeight:
        !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      textAlign: 'center',
      color: isSelected ? semanticColors.menuItemBackgroundPressed : navTextColor,
      fill: isSelected ? semanticColors.menuItemBackgroundPressed : navTextColor,
      verticalAlign: 'top',
      marginLeft:
        isChildLinkSelected ||
          (!hasChildren && isSelected && !(nestingLevel && nestingLevel > 0))
          ? '4px'
          : '0px',
    },
    navItemIconColumnGroupExpanded: {
      width:
        isSelected || isChildLinkSelected
          ? shortenedIconWidth
          : navCollapsedWidth,
      fontSize: '16px',
      lineHeight: '56px',
      height: '56px',
      textAlign: 'center',
      color: isSelected ? semanticColors.menuItemBackgroundPressed : navTextColor,
      fill: isSelected ? semanticColors.menuItemBackgroundPressed : navTextColor,
      verticalAlign: 'top',
      marginLeft:
        isChildLinkSelected ||
          (!hasChildren && isSelected && !(nestingLevel && nestingLevel > 0))
          ? '4px'
          : '0px',
    },
    navItemIconColumnToggler: {
      width: 36,
      fontSize: '16px',
      lineHeight:
        !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      textAlign: 'center',
      color: isSelected ? semanticColors.menuItemBackgroundPressed : navTextColor,
      verticalAlign: 'middle',
      marginLeft: '4px'
    },
    navItemNameColumn: {
      width: '100%',
      marginLeft:
        isChildLinkSelected ||
          (!hasChildren && isSelected && !(nestingLevel && nestingLevel > 0))
          ? '8px'
          : '0px',
      lineHeight:
        !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      verticalAlign: 'top',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: navTextColor,
      fontWeight: isSelected ? 'bold' : 'regular'
    },
    navItemNameColumnGroupExpanded: {
      width: '100%',
      marginLeft:
        isChildLinkSelected ||
          (!hasChildren && isSelected && !(nestingLevel && nestingLevel > 0))
          ? '8px'
          : '0px',
      lineHeight: '56px',
      verticalAlign: 'top',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: navTextColor,
      fontWeight: isSelected ? 'bold' : 'regular'
    },
    navChildItemNameColumn: {
      //width: '30%',
      marginLeft: isSelected ? '36px' : '48px',
      lineHeight:
        !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      verticalAlign: 'top',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: navTextColor,
      fontWeight: isSelected ? 'bold' : 'regular'
    },
    navSlimItemRoot: {
      selectors: {
        ':hover': {
          backgroundColor: hasChildren
            ? navItemWithChildBgColor
            : navItemHoverColor
        }
      }
    },
    navItemNameColumnToggler: {
      width: '65%',
      marginLeft: '8px',
      lineHeight:
        !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      verticalAlign: 'middle',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    navFloatingRoot: [
      {
        display: 'none',
        zIndex: 1901,
        position: 'absolute',
        marginLeft: navCollapsedWidth,
        marginTop:
          -navItemHeight - (!!scrollTop && scrollTop > 0 ? scrollTop : 0),
        width: navFloatingWidth,
        color: navTextColor,
        boxShadow:
          '0px 1.2px 3.6px rgba(0, 0, 0, 0.18), 0px 6.4px 14.4px rgba(0, 0, 0, 0.22)',
        backgroundColor: floatingNavBackgroundColor,
        opacity: '0.6',
        selectors: {
          [BackDropSelector]: {
            webkitBackdropFilter: 'blur(20px) saturate(125%)',
            backdropFilter: 'blur(20px) saturate(125%)',
            backgroundColor: 'rgba(255,255,255,.6)'
          },
          a: {
            width: '100%',
            backgroundColor: 'inherit'
          }
        }
      },
      AnimationClassNames.slideRightIn20
    ],
    navFloatingItemRoot: {
      height:
        !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      cursor: 'pointer',
      backgroundColor: !(nestingLevel && nestingLevel > 0)
        ? navItemHoverColor
        : floatingNavBackgroundColor,
      paddingLeft: navFloatingItemIndentSize,
      selectors: {
        ':hover': {
          backgroundColor: navItemHoverColor
        },
        ':active': {
          backgroundColor: navItemSelectedColor
        }
      }
    },
    navGroupSeparatorRoot: {
      width: '100%',
      /*height: hasGroupName
        ? navGroupSeparatorWithGroupNameHeight
        : navGroupSeparatorItemHeight,*/
      textAlign: 'left',
      marginTop: '1.5em'
    },
    navGroupSeparatorHrLine: {
      position: 'relative',
      height: '20px',
      borderBottom: `1px solid ${navItemWithChildBgColor}`
    },
    navGroupSeparatorHeaderGroupName: {
      //position: 'absolute',
      marginTop: '40px',
      marginLeft: '6px',
      color: navTextColor,
      fontWeight: 'bold',
      fontSize: 'smaller'
    },
    navToggler: {
      height: navItemHeight,
      cursor: 'pointer',
      selectors: {
        ':hover': {
          backgroundColor: navItemHoverColor
        }
      },
      textAlign: 'left'
    },
    focusedStyle: [
      getFocusStyle(
        theme,
        undefined,
        undefined,
        undefined,
        'transparent',
        undefined
      ),
      {
        display: 'block',
        position: 'relative',
        height:
          !!nestingLevel && nestingLevel > 0
            ? navChildItemHeight
            : navItemHeight,
        width: '100%',
        lineHeight:
          !!nestingLevel && nestingLevel > 0
            ? navChildItemHeight
            : navItemHeight
      }
    ]
  };
}
