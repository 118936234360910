import React, { useState, useEffect, useContext, createContext, useRef } from "react";

const ws = new WebSocket("wss://marekapp-backend.azurewebsites.net/")
//const ws = new WebSocket("ws://localhost:3001")
//const ws = new WebSocket("wss://supvkcrptn.us-east-2.awsapprunner.com/")

export const WebsocketContext = createContext(ws);

export const WebSocketProvider = ({
  children,
  config
}) => (
  <WebsocketContext.Provider value={ws}>{children}</WebsocketContext.Provider>
);

export const useWebSocket = () => {
  const socket = useContext(WebsocketContext);

  return socket;
};
